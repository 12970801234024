import axios from "axios";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function SaleAndProfitChart({ from_date, to_date }) {
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (from_date && to_date) {
      axios
        .get("/api/v1/dashboard/calculate-total-amount-profit", {
          params: {
            from_date: from_date,
            to_date: to_date,
          },
        })
        .then((response) => {
          setSeries(response.data?.graph_data);
          setCategories(response.data?.categories);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [from_date, to_date]);

  const options = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: categories,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default SaleAndProfitChart;
