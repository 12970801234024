import { useCookies } from "react-cookie";
import ToolboxSection from "../../../../toolbox-button-group";
import { Link, useNavigate } from "react-router-dom";

const RegisterProductButtonGroup = () => {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];
  const router = useNavigate();

  return (
    storedData?.permission.group_mgmt.includes(1) && (
      <ToolboxSection className="grid items-center grid-cols-1 gap-3 md:flex">
        <button
          onClick={() =>
            document
              .getElementById("product-list-create-excel-upload-modal")
              .showModal()
          }
          className="btn btn-primary max-sm:w-full"
        >
          <span className="flex items-center gap-2">
            <i className="fa-solid fa-file-spreadsheet"></i>
            <p>Excel Upload</p>
          </span>
        </button>
        <button
          onClick={() => router(-1)}
          className="btn btn-primary max-sm:w-full"
        >
          <span className="flex items-center gap-2">
            <i className="fa-solid fa-list"></i>
            <p>Product List</p>
          </span>
        </button>
      </ToolboxSection>
    )
  );
};

export default RegisterProductButtonGroup;
