import { Label, TextInput } from "flowbite-react";
import Select from "react-select";
import clearForm from "../../../../utils/clearForm";
import { useEffect, useState } from "react";

const CreateModal = ({ handleSubmit, UserGroup, BranchList, ClearForm }) => {
  const [UserCurrentGroup, setUserCurrentGroup] = useState(null);
  const [CurrentBranchList, setCurrentBranchList] = useState([]);

  useEffect(() => {
    if (ClearForm) {
      clearAll();
    }
  }, [ClearForm]);

  const clearAll = () => {
    setUserCurrentGroup(null);
    setCurrentBranchList([]);
    clearForm();
  };
  return (
    <dialog
      id="user-list-create-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Add User</h3>
          <form method="dialog">
            <button
              className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
              onClick={() => clearAll()}
            >
              ✕
            </button>
          </form>
        </div>
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form"
        >
          <div>
            <div className="block mb-2">
              <Label htmlFor="Username" value="Username" />
            </div>
            <TextInput
              id="name"
              type="text"
              name="name"
              placeholder="Oliviar Naing"
              required
            />
          </div>
          <div>
            <div className="block mb-2">
              <Label htmlFor="user-group" value="User Group" />
            </div>
            <Select
              id="user-group"
              name="user-group"
              className="croni-select-container"
              classNamePrefix="croni-select"
              options={UserGroup}
              isClearable={false}
              required={true}
              value={UserCurrentGroup}
              onChange={(data) => setUserCurrentGroup(data)}
            />
          </div>
          <div>
            <div className="block mb-2">
              <Label htmlFor="branch_id" value="Branch" />
            </div>
            <Select
              id="branch_id"
              name="branch_id"
              className="croni-select-container"
              classNamePrefix="croni-select"
              options={BranchList}
              isMulti
              isClearable={false}
              value={CurrentBranchList}
              onChange={(data) => setCurrentBranchList(data)}
              required={true}
            />
          </div>
          <div>
            <div className="block mb-2">
              <Label htmlFor="email" value="Your email" />
            </div>
            <TextInput
              key={1}
              id="email"
              type="email"
              name="email"
              placeholder="name@flowbite.com"
              required
            />
          </div>
          <div>
            <div className="block mb-2">
              <Label htmlFor="phone" value="Phone" />
            </div>
            <TextInput
              id="phone"
              type="tel"
              name="phone"
              placeholder="09xxxxxxxx"
              required
            />
          </div>
          <div>
            <div className="block mb-2">
              <Label htmlFor="password" value="Set password" />
            </div>
            <TextInput
              name="password"
              id="password"
              type="password"
              autoComplete="off"
              autoCorrect="off"
              placeholder="Enter password"
              required
            />
          </div>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <form method="dialog">
              <button
                type="submit"
                className="w-full btn btn-secondary"
                onClick={() => clearAll()}
              >
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default CreateModal;
