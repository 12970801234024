import { TextInput } from "flowbite-react";

const ResetPasswordModal = ({ handlePasswordResetSubmit, userName }) => {
  return (
    <dialog
      id="user-list-reset-password-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Reset Password</h3>
          <form method="dialog">
            <button className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2">
              ✕
            </button>
          </form>
        </div>
        <form
          onSubmit={handlePasswordResetSubmit}
          autoComplete="off"
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible"
        >
          <p className="text-slate-500">
            You are about to reset the password for the user{" "}
            <span className="font-semibold text-primary">{userName}</span>.
          </p>

          <TextInput
            id="password"
            type="text"
            name="password"
            placeholder="Enter new password"
            required
          />
          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Reset
            </button>
            <form method="dialog">
              <button type="submit" className="w-full btn btn-secondary">
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default ResetPasswordModal;
