import {  useNavigate } from "react-router-dom";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex,  Tooltip } from "@mantine/core";
import { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useCookies } from "react-cookie";
import BulkChangeModal from "../../components/modals/product/product-list/bulk-change";
import fireToast from "../../components/fireToast";
import clearForm from "../../utils/clearForm";
import { toast } from "react-toastify";
import DeleteModal from "../../components/modals/product/product-list/delete";
import EditModal from "../../components/modals/product/product-list/edit";
import defaultImage from "../../../image/default_product-768x768.jpg";

function ViewProduct() {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];
  const toastId = useRef(null);
  const [data, setData] = useState([]);
  const [switch1, setSwitch1] = useState(true);
  const [switch2, setSwitch2] = useState(false);
  // State to store the selected image file
  const [DeleteDataId, setDeleteDataId] = useState(null);
  const [EditID, setEditID] = useState(null);
  const router = useNavigate();
  const formRef = useRef();

  const notify = () =>
    (toastId.current = toast.info("Please wait...", {
      autoClose: false,
      position: "top-center",
    }));

  // const dismiss = () => toast.dismiss(toastId.current);
  const dismissAll = () => toast.dismiss();

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/v1/product-management`);
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      fireToast(error.response.data.message, "error");
      console.error("Error fetching data:", error);
    } finally {
      dismissAll();
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(formRef.current);
    formData.append("increase", switch1);
    formData.append("percentage", switch2);

    axios
      .put("/api/v1/update-all-sale-price", formData)
      .then((response) => {
        fireToast(response.data.message, "success");
        fetchData();
        setSwitch1(!switch1);
        setSwitch2(!switch2);
      })
      .catch((error) => fireToast(error.response.data.message, "error"))
      .finally(() => {
        document
          .getElementById("product-list-bulk-change-amount-modal")
          .close();
        clearForm();
      });
  };

  const handleDeleteFunction = async (pID) => {
    const form = new FormData();

    form.append("productID", pID);

    try {
      const response = await axios.post(
        "/api/v1/product-management/delete",
        form
      );

      if (response.status == 200) {
        setData(data.filter((variation) => variation.id !== pID));
        fireToast(response.data.message, "success");
      }
    } catch (error) {
      console.error("Error deleting product variation:", error);
      fireToast(error.response.data.message, "error");
    } finally {
      document.getElementById("product-list-delete-modal").close();
    }
  };

  useEffect(() => {
    notify();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        enableEditing: false,
        header: "Image",
        Cell: ({ row }) => (
          <div className="w-20 overflow-hidden border rounded-md aspect-square bg-slate-50">
            {row.original.product_image ? (
              <img
                alt={row.original.name}
                className="object-cover w-full border rounded-md aspect-square bg-slate-50"
                src={"/" + row.original.product_image}
              />
            ) : (
              <img
                alt={row.original.name}
                className="object-cover w-full border rounded-md aspect-square bg-slate-50"
                src={defaultImage}
              />
            )}
          </div>
        ),
      },
      {
        accessorKey: "name", //access nested data with dot notation
        header: "Product Name",
      },
      {
        accessorKey: "brand_name", //normal accessorKey
        header: "Brand",
      },
      {
        accessorKey: "category_name",
        header: "Category",
      },
      // {
      //   accessorKey: "subcategory_name",
      //   header: "Sub Category",
      // },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        {storedData?.permission.product_mgmt.includes(3) && (
          <Tooltip label="Edit">
            {/* <ActionIcon onClick={() => router(`/product-edit/${row.original.id}`)}> */}
            <ActionIcon
              onClick={() => [
                setEditID(row.original.id),
                document.getElementById("product-list-edit-modal").showModal(),
              ]}
            >
              <IconEdit className="text-yellow-500" />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.product_mgmt.includes(4) && (
          <Tooltip label="Delete">
            <ActionIcon
              onClick={() => [
                document
                  .getElementById("product-list-delete-modal")
                  .showModal(),
                setDeleteDataId(row.original),
              ]}
            >
              <IconTrash className="text-red-400 " />
            </ActionIcon>
          </Tooltip>
        )}
        <Tooltip label="Variant">
          <ActionIcon
            onClick={() =>
              router(
                `/product-management/product-list/detail/${row.original.id}`
              )
            }
          >
            <span className="flex items-center gap-2 text-cyan-800 ">
              <i className="fa-regular fa-sitemap"></i>
            </span>
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    // renderRowActionMenuItems: ({ row }) => (
    //   <div className="min-w-[10rem]">
    //     <Menu.Item onClick={() => router(`/product-edit/${row.original.id}`)}>
    //       <span className="flex items-center gap-2 text-yellow-400">
    //         <i className="fa-regular fa-pen-to-square"></i>
    //         Edit
    //       </span>
    //     </Menu.Item>
    //     <Menu.Item
    //       onClick={() => {
    //         props.setOpenDeleteModal("dismissible");
    //         console.log(row.original);
    //         setDeleteDataId(row.original);
    //       }}
    //     >
    //       <span className="flex items-center gap-2 text-red-600">
    //         <i className="fa-regular fa-trash-xmark"></i>
    //         Delete
    //       </span>
    //     </Menu.Item>
    //     <hr />
    //     <Menu.Item onClick={() => router(`/product-list/${row.original.id}`)}>
    //       <span className="flex items-center gap-2 text-cyan-800 ">
    //         <i className="fa-regular fa-sitemap"></i>
    //         Variant Options
    //       </span>
    //     </Menu.Item>
    //   </div>
    // ),
    enableExpanding: false,
    // renderDetailPanel: ({ row }) => (
    //   <div className="grid grid-cols-3 ">
    //     {row.original.varType.map((e, index) => (
    //       <Text key={index}>
    //         <span className="flex">
    //           {e}:
    //           <span className="flex gap-2 ml-2">
    //             {row.original[`${e}`].map((v, index2) => (
    //               <div
    //                 className="px-2 font-semibold text-white bg-blue-500 rounded-sm"
    //                 key={index2}
    //               >
    //                 {v.von}
    //               </div>
    //             ))}
    //           </span>
    //         </span>
    //       </Text>
    //     ))}
    //   </div>
    // ),
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <div>
      <MantineReactTable table={table} />
      {/* add model */}
      <BulkChangeModal
        handleFormSubmit={handleFormSubmit}
        formRef={formRef}
        switch1={switch1}
        switch2={switch2}
        setSwitch1={setSwitch1}
        setSwitch2={setSwitch2}
      />
      {/* delete modal */}
      <DeleteModal
        DeleteDataId={DeleteDataId}
        handleDeleteFunction={handleDeleteFunction}
      />

      {/* edit modal */}
      <EditModal EditID={EditID} fetchData={fetchData} />
    </div>
  );
}

export default ViewProduct;
