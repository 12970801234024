import axios from "axios";
import Formatter from "../../../../utils/formater";
import fireToast from "../../../fireToast";
import { useEffect, useState } from "react";
import Select from "react-select";

const AddonProfit = () => {
  const [Data, setData] = useState(0.0);
  const [SelectData, setSelectData] = useState({});
  const [DefaultSelectData, setDefaultSelectData] = useState(null);
  const [WalletData, setWalletData] = useState({});
  const [WalletAmount, setWalletAmount] = useState(0);
  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/money-exchange/total-profit");
      if (response.status === 200) {
        setData(response.data?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }

    try {
      const response = await axios.get(`/api/v1/expenses/props`);
      if (response.status === 200) {
        if (!DefaultSelectData) {
          console.log("aa");
          setSelectData(response.data);
          setDefaultSelectData(response.data?.wallet[0]);
        }
        setWalletAmount(
          WalletData?.total_amount_each_wallet?.find(
            (wallet) => wallet.id === DefaultSelectData.value
          )?.total_amount
        );
      }
    } catch (error) {
      console.error(error);
    }

    axios
      .get(`/api/v1/dashboard/wallet-total-amount`)
      .then(({ data }) => setWalletData(data))
      .catch(console.error);
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setWalletAmount(
      WalletData?.total_amount_each_wallet?.find(
        (wallet) => wallet.id === DefaultSelectData?.value
      )?.total_amount
    );
  }, [WalletData, DefaultSelectData]);

  return (
    <div className="flex flex-col gap-3 lg:flex-row lg:items-center">
      <div className="flex items-center gap-4 p-4 border rounded-lg bg-slate-50 min-w-[24rem]">
        <span className="bg-blue-200 rounded-full w-[3rem] h-[3rem] flex justify-center items-center">
          <i className="text-xl fa-solid fa-money-bill-transfer text-primary-content"></i>
        </span>
        <div className="flex flex-col">
          <span className="text-sm text-slate-500">Total Profit</span>
          <span className="text-lg font-semibold">
            Ks {Formatter(parseFloat(Data), "price")}
          </span>
        </div>
      </div>
      <div className="flex items-center gap-4 p-4 relative border rounded-lg bg-slate-50 min-w-[24rem]">
        <span className="bg-green-200 rounded-full w-[3rem] h-[3rem] flex justify-center items-center">
          <i className="text-xl fa-solid fa-wallet text-primary-content"></i>
        </span>
        <div className="flex flex-col flex-grow">
          <div className="flex items-center justify-between w-full ">
            <span className="text-sm text-slate-500">Balance</span>
            <div className="absolute right-2 top-2">
              {SelectData?.wallet && (
                <Select
                  id="wallet_id"
                  name="wallet_id"
                  className="croni-select-container w-[10rem] text-black "
                  classNamePrefix="croni-select"
                  options={SelectData?.wallet}
                  isClearable={false}
                  required={true}
                  onChange={(data) => setDefaultSelectData(data)}
                  defaultValue={SelectData?.wallet[0]}
                />
              )}
            </div>
          </div>
          <span className="text-lg font-semibold">
            Ks {Formatter(parseFloat(WalletAmount), "price")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AddonProfit;
