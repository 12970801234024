import moment from "moment";

const Formatter = (value, type) => {
  if (type === "datetime") {
    const formattedDate = moment.utc(value).format("MMM DD, YYYY hh:mm:ss A");
    return formattedDate;
  } else if (type === "date") {
    const formattedDate = moment.utc(value).format("MMM DD, YYYY");
    return formattedDate;
  } else if (type === "price") {
    const formattedPrice = parseFloat(value).toLocaleString();
    return formattedPrice;
  }
};

export default Formatter;
