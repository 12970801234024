import { Link, useLocation, useNavigate } from "react-router-dom";
import PhoenixLogo from "../../image/phoenix_logo.png";
import { navigationLinks } from "../utils/settings";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import { useState } from "react";
import { useCurrentPath } from "../hooks/useCurrentPath";

export default function CSideBar() {
  // function openSideBar() {
  //   const sidebar = document.getElementById("drawer-navigation");
  //   if (sidebar.classList.contains("max-sm:left-0")) {
  //     sidebar.classList.remove("max-sm:left-0");
  //     sidebar.classList.add("max-sm:-left-[15rem]");
  //   }
  // }

  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { currentPath } = useCurrentPath();

  return (
    <div className="max-md:hidden bg-[#FBFBFB] h-screen border-r">
      <Sidebar collapsed={collapsed}>
        <Menu
          image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
          rootStyles={{
            [`.${menuClasses.icon}`]: {
              backgroundColor: "#f1f1f1",
            },
          }}
        >
          <MenuItem className="py-4 ">
            <div
              className={`h-[60px] flex items-center gap-1 w-full
          `}
            >
              <label className="!rounded-[0.2rem] btn swap swap-rotate btn-sm btn-ghost bg-slate-300 btn-square">
                {/* this hidden checkbox controls the state */}
                <input
                  type="checkbox"
                  className="opacity-0"
                  onChange={() => setCollapsed(!collapsed)}
                />

                {/* hamburger icon */}
                <svg
                  className="fill-current swap-on"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 512 512"
                >
                  <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
                </svg>

                {/* close icon */}
                <svg
                  className="fill-current swap-off"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 512 512"
                >
                  <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
                </svg>
              </label>
              <Link
                to="/"
                className={`justify-start w-[80%] h-fit  ${
                  collapsed ? "hidden" : ""
                }`}
              >
                <img src={PhoenixLogo} alt="Phoenix POS" className="w-[100%]" />
              </Link>
            </div>
          </MenuItem>
          {navigationLinks.map(
            (link, index) =>
              link.active &&
              link.show_in_menu !== false && (
                <>
                  {link.children?.length > 0 ? (
                    <SubMenu
                      defaultOpen={
                        (link.href !== "/" &&
                          location.pathname.includes(link.href)) ||
                        location.pathname === link.href
                      }
                      icon={link.icon}
                      key={index}
                      label={link.name}
                    >
                      {link.children.map((link2, index2) => (
                        <MenuItem
                          active={
                            (link.href !== "/" &&
                              location.pathname.includes(link2.href)) ||
                            location.pathname === link2.href
                          }
                          key={index2}
                          onClick={() => navigate(link.href + link2.href)}
                        >
                          {link2.name}
                        </MenuItem>
                      ))}
                    </SubMenu>
                  ) : (
                    <MenuItem
                      active={
                        (link.href !== "/" &&
                          location.pathname.includes(link.href)) ||
                        location.pathname === link.href
                      }
                      icon={link.icon}
                      key={index}
                      onClick={() => navigate(link.href)}
                    >
                      {link.name}
                    </MenuItem>
                  )}
                </>
              )
          )}
        </Menu>
      </Sidebar>
    </div>
  );
}
