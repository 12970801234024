import { Link, useLocation } from "react-router-dom";
import PhoenixLogo from "../../image/phoenix_logo.png";
import { navigationLinks } from "../utils/settings";

const MobileNavBar = () => {
  const location = useLocation();
  return (
    <>
      <div className="fixed bottom-0 flex items-center justify-between w-full h-16 px-3 border-t shadow-sm bg-base-100 lg:hidden">
        <Link to="/" className="justify-start w-[8rem] btn btn-ghost h-fit p-0">
          <img src={PhoenixLogo} alt="Phoenix POS" className="w-[100%]" />
        </Link>
        <button
          className="btn btn-ghost btn-square btn-sm"
          onClick={() =>
            document.getElementById("mobile-nav-modal").showModal()
          }
        >
          <i className="text-xl fa-regular fa-bars-staggered"></i>
        </button>
      </div>
      <dialog id="mobile-nav-modal" className="modal modal-bottom">
        <div className="modal-box">
          <div className="grid grid-cols-3 gap-2">
            {navigationLinks.map(
              (link, index) =>
                link.active &&
                link.show_in_menu !== false && (
                  <Link
                    onClick={() =>
                      document.getElementById("mobile-nav-modal").close()
                    }
                    key={index}
                    to={link.href}
                    className={`justify-start flex-col items-center p-3 gap-1 w-full btn h-fit  ${
                      (link.href !== "/" &&
                        location.pathname.includes(link.href)) ||
                      location.pathname === link.href
                        ? "btn-primary text-white"
                        : "btn-ghost"
                    }`}
                  >
                    <div className="size-[20px] aspect-square">
                      <img
                        src={link.icon}
                        className="object-cover w-full aspect-square"
                        alt={link.title}
                      />
                    </div>
                    <span className="text-[0.7rem]">{link.name}</span>
                  </Link>
                )
            )}
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};

export default MobileNavBar;
