import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCurrentDateTime } from "../utils/dateFormat";
import BrandLogo from "../../image/brindha_logo.jpg";
import { useCookies } from "react-cookie";

export default function PrintPage() {
  let { data } = useParams();
  const [PrintData, setPrintData] = useState(JSON.parse(atob(data)));
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  useEffect(() => {
    if (PrintData) {
      window.print();
    }
  }, []);
  return (
    <div className="bg-white">
      <div
        className={`cursor-pointer flex justify-center  z-[1000] m-0 p-0 px-1 `}
      >
        <div className="flex flex-col w-full py-10 text-[0.7rem]">
          <div className="flex justify-center w-full">
            <img
              src={BrandLogo}
              alt="Brindha"
              className="aspect-[1/1] w-[12rem]"
            />
          </div>
          <h5 className="text-center text-bold">Brindha</h5>
          <div className="flex flex-col items-center">
            <span>Peelkan, Kyauktan, Yangon</span>
            <span>Brindha The Indian Collections</span>
          </div>

          <>
            <div className="flex flex-col items-start justify-start py-4 border-b-2 border-black border-dashed">
              {PrintData?.customer && (
                <span>Customer: {PrintData.customer}</span>
              )}
              <span>Employee: {storedData?.name ?? "N/A"}</span>
              <span>Branch: {storedData?.current_branch ?? "N/A"}</span>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 py-2 border-b-2 border-black border-dashed">
              {PrintData?.product_data.map((value) => (
                <div className="flex justify-between w-full">
                  <div className="flex flex-col">
                    <span className="">{value?.name}</span>
                    <span className="">
                      {value?.qty} x Ks {value?.sale_price.toLocaleString()}
                    </span>
                  </div>
                  <div className="">
                    Ks {(value?.qty * value?.sale_price).toLocaleString()}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-col items-start justify-start py-2 border-b-2 border-black border-dashed">
              <div className="flex justify-between w-full text-[0.65rem]">
                <span>Total amount</span>
                <span>
                  Ks {Number(PrintData?.total_amount).toLocaleString()}
                </span>
              </div>
              {PrintData?.promotion > 0 && (
                <div className="flex justify-between w-full text-[0.65rem]">
                  <span className="">Promotion</span>
                  <span>
                    Ks -{Number(PrintData?.promotion).toLocaleString()}
                  </span>
                </div>
              )}
              <div className="flex justify-between w-full text-[0.65rem]">
                <span>Payment method</span>
                <span>{PrintData?.wallet}</span>
              </div>
              <div className="flex justify-between w-full text-[0.65rem]">
                <span>Status</span>
                <span>{PrintData?.status}</span>
              </div>
              <div className="flex justify-between w-full font-bold">
                <span>Payable amount</span>
                <span>Ks {Number(PrintData?.net_amount).toLocaleString()}</span>
              </div>
            </div>
            <div className="py-3 text-center">
              Thank you for shoping in Brindha!
            </div>
            <div className="flex justify-between w-full">
              <div className="">{getCurrentDateTime(PrintData?.datetime)}</div>
              <div className="">#{PrintData?.voucher_code}</div>
            </div>
            <div className="py-5 text-center">***</div>
          </>
        </div>
      </div>
    </div>
  );
}
