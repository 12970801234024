import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import fireToast from "../../../components/fireToast";
import Formatter from "../../../utils/formater";
import { useNavigate, useParams } from "react-router-dom";

export default function WalletDetailLog() {
  const [data, setData] = useState([]);
  const [PrintID, setPrintID] = useState(null);
  const { walletId } = useParams();
  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/v1/wallet/log/${walletId}`);

      if (response.status === 200) {
        const newData = response.data.map((item) => {
          return {
            ...item,
            status: wallet_status[item.status],
          };
        });

        setData(newData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (PrintID !== null) {
      window.print();
      const myFunction = () => {
        setPrintID(null);
      };

      const timeoutId = setTimeout(myFunction, 4000);

      return () => {
        clearTimeout(timeoutId);
      };

      //   location.reload();
    }
  }, [PrintID]);

  const wallet_status = {
    0: "OUT",
    1: "IN",
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at",
        header: "Datetime",
        Cell: ({ row }) => (
          <div className="flex text-xs flex-nowrap text-nowrap">
            {Formatter(row.original.created_at, "datetime")}
          </div>
        ),
      },
      {
        accessorKey: "wallet", //access nested data with dot notation
        header: "Wallet",
        Cell: ({ row }) => (
          <span className={`font-semibold`}>{row.original.wallet}</span>
        ),
      },
      {
        accessorKey: "feature", //access nested data with dot notation
        header: "Feature",
        Cell: ({ row }) => (
          <span className={`font-semibold`}>{row.original.feature}</span>
        ),
      },
      {
        accessorKey: "effective_id", //access nested data with dot notation
        header: "Effective ID",
        Cell: ({ row }) => (
          <span className={`font-semibold`}>{row.original.effective_id}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ row }) => (
          <span
            className={`${
              row.original.status === "IN" ? "text-success" : "text-error"
            } font-semibold`}
          >
            {row.original.status}
          </span>
        ),
      },
      {
        accessorKey: "amount",
        header: "Amount",
        Cell: ({ row }) => (
          <span
            className={`${
              row.original.status === "IN" ? "text-success" : "text-error"
            } font-semibold`}
          >
            Ks {row.original.status === "IN" ? "+" : "-"}
            {Formatter(parseFloat(row.original.amount), "price")}
          </span>
        ),
      },
      {
        accessorKey: "user", //access nested data with dot notation
        header: "User Name",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    positionActionsColumn: "last",
    enableRowActions: false,
    enableExpanding: false,
    columns,
    initialState: {
      columnVisibility: {
        sale_40: false,
        ygn_sale: false,
        net_whole_sale: false,
        net_stock: false,
      },
    },
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
}
