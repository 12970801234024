import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import fireToast from "../../components/fireToast";
import Formatter from "../../utils/formater";

export default function DailySaleList() {
  const [data, setData] = useState([]);
  const [PrintID, setPrintID] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/v1/sale/daily-sale-list`);
        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        fireToast(error.response.data.message, "error");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (PrintID !== null) {
      const print_data = data[PrintID];
      const print_format = {
        customer: print_data?.voucher_data?.customer?.name,
        wallet: print_data?.voucher_data?.wallet?.name,
        product_data: print_data?.voucher_data?.product_data,
        total_amount: print_data?.total_amount,
        datetime: print_data?.datetime,
        voucher_code: print_data?.voucher_code,
      };

      const encode_data = btoa(JSON.stringify(print_format));

      window.open(`/print/${encode_data}`, "_blank");
      setPrintID(null);
    }
  }, [PrintID]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "sale_date",
        header: "Sale Date",
        Cell: ({ row }) => (
          <div className="flex text-xs flex-nowrap text-nowrap">
            {Formatter(row.original.sale_date, "datetime")}
          </div>
        ),
      },
      {
        accessorKey: "sku", //access nested data with dot notation
        header: "Product Code",
      },
      {
        accessorKey: "product_name", //access nested data with dot notation
        header: "Product Name",
      },
      {
        accessorKey: "current_stock", //access nested data with dot notation
        header: "Current Stock",
      },
      {
        accessorKey: "total_sold_qty", //access nested data with dot notation
        header: "Sold Qty",
      },
      {
        accessorKey: "sale_price",
        header: "Sale Price",
        Cell: ({ row }) => (
          <span>Ks {Formatter(row.original.sale_price, "price")}</span>
        ),
      },
      {
        accessorKey: "cost",
        header: "Cost",
        Cell: ({ row }) => (
          <span>Ks {Formatter(row.original.cost, "price")}</span>
        ),
      },
      {
        accessorKey: "total_discount", //access nested data with dot notation
        header: "Total Discount",
        Cell: ({ row }) => (
          <span>Ks {Formatter(row.original.total_discount, "price")}</span>
        ),
      },
      {
        accessorKey: "total_price",
        header: "Total Price",
        Cell: ({ row }) => (
          <span>Ks {Formatter(row.original.total_price, "price")}</span>
        ),
      },
      {
        accessorKey: "total_profit",
        header: "Total Profit",
        Cell: ({ row }) => (
          <span>Ks {Formatter(row.original.total_profit, "price")}</span>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    enableRowActions: false,
    enableExpanding: false,
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <div>
      {/* <div
      onClick={()=>setPrintID(null)}
        className={`cursor-pointer flex justify-center fixed left-0 w-[100vw] h-[100vh] top-0 right-0 bg-white z-[1000] m-0 p-0 ${
          PrintID !== null ? "" : "hidden"
        }`}
      >
        <div className="flex flex-col w-full py-10 text-[0.7rem]">
          <div className="flex justify-center w-full">
            <img
              src="/image/brindha_logo.jpg"
              alt=""
              className="w-[12rem] h-[12rem]"
            />
          </div>
          <h5 className="text-center text-bold">Brindha</h5>
          <div className="flex flex-col items-center">
            <span>Peelkan, Kyauktan, Yangon</span>
            <span>Brindha The Indian Collections</span>
          </div>
          {PrintID != null && (
            <>
              <div className="flex flex-col items-start justify-start py-2 border-b-2 border-black border-dashed">
                <div className="">
                  {data[PrintID]?.voucher_data?.customer?.name
                    ? "Customer: " + data[PrintID]?.voucher_data?.customer?.name
                    : ""}
                </div>
                <div className="">
                  Payment method: {data[PrintID]?.voucher_data?.wallet?.name}
                </div>
                <div className="">Employee: Brindha</div>
                <div className="">POS: Brindha</div>
              </div>
              <div className="flex flex-col items-start justify-start gap-2 py-2 border-b-2 border-black border-dashed">
                {data[PrintID]?.voucher_data?.product_data.map((value) => (
                  <div className="flex justify-between w-full">
                    <div className="flex flex-col">
                      <span className="">{value?.name}</span>
                      <span className="">
                        {value?.qty} x K{value?.sale_price.toLocaleString()}
                      </span>
                    </div>
                    <div className="">
                      K{(value?.qty * value?.sale_price).toLocaleString()}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col items-start justify-start gap-3 py-2 border-b-2 border-black border-dashed">
                <div className="flex justify-between w-full">
                  <span className="font-bold">Total amount</span>
                  <span>K{data[PrintID]?.total_amount}</span>
                </div>
                <div className="w-full flex text-[0.65rem] justify-between">
                  <span className="">Payable amount</span>
                  <span>K{data[PrintID]?.total_amount}</span>
                </div>
              </div>
              <div className="py-2 text-center">
                Thank you for shoping in Brindha!
              </div>
              <div className="flex justify-between w-full">
                <div className="">
                  {getCurrentDateTime(data[PrintID]?.datetime)}
                </div>
                <div className="">#{data[PrintID]?.voucher_code}</div>
              </div>
              <div className="py-5 text-center">***</div>
            </>
          )}
        </div>
      </div> */}
      <MantineReactTable table={table} />
    </div>
  );
}
