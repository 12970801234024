import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import fireToast from "../../components/fireToast";
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";

const SelectBranch = () => {
  const cookies = new Cookies();
  const currentData = cookies.get("userSession");
  const [storedData, setStoredData] = useState(currentData);
  const [Data, setData] = useState();
  const route = useNavigate();
  const token = cookies.get("userSession")["token"]["access_token"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/v1/branch/list/select", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        fireToast(error.response.data.message, "error");
      }
    };
    fetchData();
  }, []);

  const handleBranchSelectSubmit = (value) => {
    const form = new FormData();
    form.append("branch_id", value.id);
    form.append("branch_name", value.name);

    axios
      .post("/api/v1/change-branch", form)
      .then((res) => {
        if (res.status === 200) {
          fireToast(res.data.message, "success");
          const updatedCookieData = {
            ...storedData,
            current_branch_id: value.id,
            current_branch: value.name,
          };
          setStoredData(updatedCookieData);
          cookies.set("userSession", JSON.stringify(updatedCookieData), {
            path: "*",
            expires: new Date(
              moment.utc(storedData?.last_login_date) +
                24 * 60 * 60 * 1000 +
                2 * 60 * 1000
            ),
          });
          route("/");
        }
      })
      .catch((err) => {
        console.log(err);
        fireToast(err.response.data.message, "error");
      });
  };

  return (
    <div className="grid w-full grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-6 gap-y-8">
      {Data?.branches.map((value, index) => (
        <button
          onClick={() => handleBranchSelectSubmit(value)}
          key={index}
          className="flex flex-col items-center w-full gap-1 "
        >
          <div
            className={`flex flex-col items-center justify-center w-full gap-4 p-6 ${
              value?.id === Data?.current_branch_id
                ? "bg-primary text-white"
                : "bg-white hover:shadow-orange-100 hover:text-primary hover:border-primary hover:bg-[unset]"
            } border rounded-md hover:shadow-md btn h-fit`}
          >
            {/* <i className={`${sub_link.icon} text-[2.5rem]`}></i> */}
            <span className="font-semibold">{value?.name}</span>
          </div>
        </button>
      ))}
    </div>
  );
};

export default SelectBranch;
