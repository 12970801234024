import { useCookies } from "react-cookie";
import ToolboxSection from "../../../toolbox-button-group";
import { Link } from "react-router-dom";

const AddProductButtonGroup = () => {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  return (
    storedData?.permission.group_mgmt.includes(1) && (
      <ToolboxSection className="grid items-center w-full grid-cols-1 gap-3 md:flex">
        <button
          onClick={() =>
            document
              .getElementById("product-list-bulk-change-amount-modal")
              .showModal()
          }
          className="btn btn-primary max-sm:w-full"
        >
          <span className="flex items-center gap-2">
            <i className="fa-solid fa-rotate"></i>
            <p>Change All Amount</p>
          </span>
        </button>
        <Link to="product-list/add" className="btn btn-primary max-sm:w-full">
          <span className="flex items-center gap-2">
            <i className="fa-solid fa-plus"></i>
            <p>New Product</p>
          </span>
        </Link>
      </ToolboxSection>
    )
  );
};

export default AddProductButtonGroup;
