import { Label, TextInput, Textarea } from "flowbite-react";
import clearForm from "../../../../utils/clearForm";
import moment from "moment";

const CreateModal = ({ handleFormSubmit }) => {
  function clearAll() {
    clearForm();
  }

  return (
    <dialog
      id="payable-create-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Create Record</h3>
          <form method="dialog">
            <button className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2">
              ✕
            </button>
          </form>
        </div>
        <form
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form"
          onSubmit={handleFormSubmit}
        >
          <div>
            <div className="block mb-2 ">
              <Label htmlFor="datetime" value="Date" />
            </div>
            <div className="grid w-full grid-cols-1 gap-2">
              <TextInput
                type="date"
                id="datetime"
                name="datetime"
                required
                defaultValue={moment().format("YYYY-MM-DD")}
                max={moment().format("YYYY-MM-DD")}
              />
            </div>
          </div>
          <div className="grid w-full grid-cols-1 gap-2">
            <div>
              <div className="block mb-2 ">
                <Label htmlFor="customer_name" value="Customer Name" />
              </div>
              <div>
                <TextInput id="customer_name" name="customer_name" required />
              </div>
            </div>
            <div>
              <div className="block mb-2 ">
                <Label htmlFor="total_amount" value="Total amount" />
              </div>
              <div>
                <TextInput id="total_amount" name="total_amount" required />
              </div>
            </div>
          </div>
          <div>
            <div className="block mb-2 ">
              <Label htmlFor="description" value="Note" />
            </div>
            <div className="grid w-full grid-cols-1 gap-2">
              <Textarea id="description" name="description" rows={4} />
            </div>
          </div>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <form method="dialog">
              <button
                type="submit"
                className="w-full btn btn-secondary"
                onClick={clearAll}
              >
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default CreateModal;
