import { useEffect, useRef, useState } from "react";
import fireToast from "../../components/fireToast";
import axios from "axios";
import { Cookies } from "react-cookie";
import defaultProfile from "../../../image/default_profile.jpg";
import Formatter from "../../utils/formater";
import EditModal from "../../components/modals/profile/edit";
import EditProfileImageModal from "../../components/modals/profile/profile-image";
import clearForm from "../../utils/clearForm";
import moment from "moment";

const Profile = () => {
  const [userData, setUserData] = useState({});
  const cookies = new Cookies();
  const token = cookies.get("userSession")["token"]["access_token"];

  const profileImgRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedUploadImage, setSelectedUploadImage] = useState(null);

  const fetchData = () => {
    axios
      .get("/api/v1/user/profile", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUserData(response.data?.data);
        const currentData = cookies.get("userSession");
        const updatedCookieData = {
          ...currentData,
          name: response.data?.data?.name,
          mobile: response.data?.data?.mobile,
          profile_image: response.data?.data?.image,
        };
        cookies.set("userSession", JSON.stringify(updatedCookieData), {
          path: "*",
          expires: new Date(
            moment.utc(response.data?.data?.last_login_date) +
              17.5 * 60 * 60 * 1000 +
              2 * 60 * 1000
          ),
        });
      })
      .catch((error) => {
        console.error(error);
        fireToast(error.response.data.message, "error");
      });
  };

  const handleProfileEdit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    axios
      .put("/api/v1/user/profile", formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status === 200) {
          fireToast(response.data.message, "success");
          document.getElementById("profile-edit-modal").close();
          fetchData();
          clearForm();
        }
      })
      .catch((error) => {
        console.error(error);
        fireToast(error.response.data.message, "error");
      });
  };

  const handleCameraButtonClick = () => {
    profileImgRef.current.click();
  };

  const handleProfileImgUpload = (event) => {
    const file = event.target.files[0];
    file && setSelectedUploadImage(file);
    file && setSelectedImage(URL.createObjectURL(file));
  };

  const handleCancel = () => {
    setSelectedImage(null); // Reset selected image
  };

  useEffect(() => {
    if (selectedImage) {
      document.getElementById("profile-image-edit-modal").showModal();
    }
  }, [selectedImage]);

  const handleProfileImageEdit = () => {
    if (selectedUploadImage) {
      const formData = new FormData();
      formData.append("profile_picture", selectedUploadImage);

      axios
        .post("/api/v1/user/profile/picture", formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setSelectedImage(null);

          fireToast(res.data.message, "success");
          fetchData();
        })
        .catch((err) => {
          setSelectedImage(null);

          fireToast(err.response.data.message, "error");
        })
        .finally(() => {
          document.getElementById("profile-image-edit-modal").close();
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="flex items-center justify-center w-full lg:h-[70vh]">
      <div className="p-3 border rounded-lg shadow-sm w-full lg:w-[50%]">
        <div className="relative flex flex-col items-center w-full gap-3 ">
          <div className="flex justify-center w-full py-5">
            <div className="size-[150px] rounded-full p-[3px] bg-primary relative select-none aspect-[1/1] flex justify-center items-center">
              <img
                src={userData?.image ? userData?.image : defaultProfile}
                alt={userData?.name}
                className=" object-cover rounded-full aspect-[1/1] w-full bg-gradient-to-b from-white to-slate-300 justify-center items-center flex"
              />
              <button
                type="button"
                className="absolute bottom-0 right-0 p-2 rounded-full touch-ripple-current bg-primary"
                onClick={handleCameraButtonClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                  />
                </svg>
              </button>
              <input
                type="file"
                ref={profileImgRef}
                accept="image/jpg, image/jpeg, image/png"
                className="hidden "
                onChange={handleProfileImgUpload}
              />
            </div>
          </div>
          <div className="flex flex-col justify-center w-full border divide-y rounded-md">
            <div className="flex justify-between w-full p-3 max-sm:py-2 md:px-4 md:items-center max-sm:flex-col">
              <span className=" text-slate-600 max-sm:text-sm">Name:</span>
              <span className="font-semibold">{userData?.name}</span>
            </div>
            <div className="flex justify-between w-full p-3 max-sm:py-2 md:px-4 md:items-center max-sm:flex-col">
              <span className=" text-slate-600 max-sm:text-sm">
                Login email:
              </span>
              <span className="font-semibold">{userData?.email}</span>
            </div>
            <div className="flex justify-between w-full p-3 max-sm:py-2 md:px-4 md:items-center max-sm:flex-col">
              <span className=" text-slate-600 max-sm:text-sm">Phone:</span>
              <span className="font-semibold">{userData?.phone_number}</span>
            </div>
          </div>
          <div className="flex flex-col justify-center w-full border divide-y rounded-md">
            <div className="flex items-center justify-between w-full p-3 px-4">
              <span className=" text-slate-600">Account status:</span>
              <span className="font-semibold">
                {userData?.status == 1 ? (
                  <span className="badge badge-success">Active</span>
                ) : (
                  <span className="badge badge-error">Inactive</span>
                )}
              </span>
            </div>
            <div className="flex items-center justify-between w-full p-3 px-4">
              <span className=" text-slate-600">Last login:</span>
              <span className="font-semibold">
                {Formatter(userData?.last_login_date, "datetime")}
              </span>
            </div>
          </div>
          <button
            className="absolute top-0 right-0 btn btn-primary btn-sm btn-square"
            onClick={() =>
              document.getElementById("profile-edit-modal").showModal()
            }
          >
            <i className="fa-regular fa-pen-to-square"></i>
          </button>
        </div>
      </div>
      <EditModal handleEditFormSubmit={handleProfileEdit} EditData={userData} />
      <EditProfileImageModal
        handleCancel={handleCancel}
        selectedImage={selectedImage}
        handleProfileImageEdit={handleProfileImageEdit}
      />
    </div>
  );
};

export default Profile;
