import EditProduct from "../../../../pages/product/edit-product";

const EditModal = ({ EditID, fetchData }) => {
  return (
    <dialog
      id="product-list-edit-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Edit Product</h3>
          <form method="dialog">
            <button className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2">
              ✕
            </button>
          </form>
        </div>
        <EditProduct pId={EditID} fetchData={fetchData} />
      </div>
    </dialog>
  );
};

export default EditModal;
