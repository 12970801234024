import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { DropdownItem } from "flowbite-react/lib/esm/components/Dropdown/DropdownItem";
import { useEffect, useState } from "react";
import DefaultProfile from "../../image/default_profile.jpg";
import BreadCrumb from "./breadcrumb";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import $ from "jquery";

export default function TopNavBar() {
  const cookies = new Cookies();
  const [UserData, setUserData] = useState(cookies.get("userSession"));
  const route = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      setUserData(cookies.get("userSession"));
    }, 3 * 1000);

    return () => clearInterval(interval);
  }, []);

  // console.log(UserData);

  const toggleClass = (className) => {
    $("." + className).toggleClass("show");
    $(".phoenix-content-container").toggleClass("shrink");
    $("#top-nav-bar").toggleClass("shrink");
  };

  return (
    <Navbar fluid id="top-nav-bar">
      <div className="flex items-center gap-3">
        <BreadCrumb />
      </div>

      <Dropdown
        arrowIcon={false}
        inline
        label={
          <div className="overflow-hidden border-2 rounded-full size-10 border-primary aspect-square">
            <img
              src={
                UserData?.profile_image
                  ? UserData?.profile_image
                  : DefaultProfile
              }
              alt={UserData?.name}
              className="object-cover w-full aspect-square"
            />
          </div>
        }
      >
        <Dropdown.Header>
          <span className="block text-sm font-bold text-primary">
            {UserData?.name ?? "Loading..."}
          </span>
          <span className="block text-sm font-medium truncate text-slate-500">
            {UserData?.email ?? "Please wait..."}
          </span>
        </Dropdown.Header>
        <DropdownItem
          onClick={() => route("/settings/select-branch")}
          className="border-b"
          title="Current Branch"
        >
          <div className="flex items-center justify-between w-full">
            <div className="font-bold">
              <i className="w-5 mr-2 fa-regular fa-shop text-success"></i>
              {UserData?.current_branch ?? "Please wait..."}
            </div>
            <i className="fa-regular fa-chevron-right"></i>
          </div>
        </DropdownItem>
        <DropdownItem onClick={() => route("/profile")}>
          <i className="w-5 mr-2 fa-regular fa-user"></i> Profile Setting
        </DropdownItem>
        <DropdownItem
          onClick={() => [
            cookies.remove("userSession"),
            cookies.remove("branchSession"),
            window.location.replace("/login"),
          ]}
          className="text-red-700 "
        >
          <i className="w-5 mr-2 fa-regular fa-right-from-bracket"></i> Logout
        </DropdownItem>
      </Dropdown>
      {/* <Navbar.Toggle onClick={() => openSideBar()} /> */}
      {/* <Navbar.Collapse></Navbar.Collapse> */}
    </Navbar>
  );
}
