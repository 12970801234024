import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import EditModal from "../../components/modals/userManagement/customer-list/edit";
import DeleteModal from "../../components/modals/userManagement/customer-list/delete";
import { useCookies } from "react-cookie";
import Formatter from "../../utils/formater";

export default function ViewCustomerList() {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  const [data, setData] = useState([]);
  const [PrintID, setPrintID] = useState(null);
  const [EditUserData, setEditUserData] = useState(null);
  const [DeleteID, setDeleteID] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/v1/customer/list`);

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (customer_id) => {
    try {
      const response = await axios.delete(
        `/api/v1/customer/delete/${customer_id}`
      );

      if (response.status === 200) {
        document.getElementById("customer-list-delete-modal").close();
        fetchData();
        setDeleteID(null);
        alert("Deleted Successfully.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Something went wrong.");
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      const response = await axios.put(
        `/api/v1/customer/edit/${EditUserData?.id}`,
        formData
      );

      if (response.status === 200) {
        document.getElementById("customer-list-edit-modal").close();
        fetchData();
        alert("Edit Successfully.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Something went wrong.");
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (PrintID !== null) {
      const print_data = data[PrintID];
      const print_format = {
        customer: print_data?.voucher_data?.customer?.name,
        wallet: print_data?.voucher_data?.wallet?.name,
        product_data: print_data?.voucher_data?.product_data,
        total_amount: print_data?.total_amount,
        datetime: print_data?.datetime,
        voucher_code: print_data?.voucher_code,
      };

      const encode_data = btoa(JSON.stringify(print_format));

      window.open(`/print/${encode_data}`, "_blank");
      setPrintID(null);
    }
  }, [PrintID]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        header: "Name",
      },
      {
        accessorKey: "email", //access nested data with dot notation
        header: "email",
      },
      {
        accessorKey: "phone", //access nested data with dot notation
        header: "Phone",
      },
      {
        accessorKey: "address", //access nested data with dot notation
        header: "Address",
      },
      {
        accessorKey: "created_at",
        header: "Created",
        Cell: ({ row }) => (
          <div className="flex text-xs flex-nowrap text-nowrap">
            {Formatter(row.original.created_at, "datetime")}
          </div>
        ),
      },
      {
        accessorKey: "updated_at",
        header: "Last Updated",
        Cell: ({ row }) => (
          <div className="flex text-xs flex-nowrap text-nowrap">
            {Formatter(row.original.updated_at, "datetime")}
          </div>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        {storedData?.permission.customer_mgmt.includes(3) && (
          <Tooltip label="Edit">
            <ActionIcon
              onClick={() => [
                document.getElementById("customer-list-edit-modal").showModal(),
                setEditUserData(row["original"]),
              ]}
            >
              <IconEdit className="text-yellow-500" />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.customer_mgmt.includes(4) && (
          <Tooltip label="Delete">
            <ActionIcon
              onClick={() => [
                document
                  .getElementById("customer-list-delete-modal")
                  .showModal(),
                setDeleteID(row?.original),
              ]}
            >
              <IconTrash className="text-red-400 " />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),
    enableExpanding: false,
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <div>
      <MantineReactTable table={table} />
      {/* edit */}
      <EditModal
        handleEditSubmit={handleEditSubmit}
        EditUserData={EditUserData}
      />
      {/* delete modal */}
      <DeleteModal
        handleDeleteFunction={handleDelete}
        DeleteDataId={DeleteID}
      />
    </div>
  );
}
