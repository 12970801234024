import axios from "axios";
import { Label, TextInput, Textarea, ToggleSwitch } from "flowbite-react";
import { memo, useEffect, useState } from "react";
import Select from "react-select";
import $ from "jquery";
import defaultImage from "../../../../image/default_product-768x768.jpg";
import Formatter from "../../../utils/formater";
import fireToast from "../../../components/fireToast";
import CreateModal from "../../../components/modals/sale/cashier/add-customer";

let cancelToken;
function OutgoingStock() {
  const [AddedProduct, setAddedProduct] = useState([]);
  const [promotion, setPromotion] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [SearchProductKeys, setSearchProductKeys] = useState("");
  const [SearchedProduct, setSearchedProduct] = useState(null);
  const [ProductSearchErrorMessage, setProductSearchErrorMessage] =
    useState("");
  const [WalletType, setWalletType] = useState([]);
  const [SelectedWalletType, setSelectedWalletType] = useState(null);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [SelectedCustomerData, setSelectedCustomerData] = useState({});
  const [Print, setPrint] = useState(false);
  const [VoucherCode, setVoucherCode] = useState("");
  const [Paid, setPaid] = useState(true);
  const [CustomerList, setCustomerList] = useState([]); //for options
  const [CustomerData, setCustomerData] = useState([]);

  useEffect(() => {
    setProductSearchErrorMessage("");
    if (SearchProductKeys) {
      SearchProduct(SearchProductKeys);
    } else {
      setSearchedProduct(null);
    }
  }, [SearchProductKeys]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setErrorMessage("");
    }, 3000);

    return () => clearTimeout(timerId);
  }, [ErrorMessage]);

  const fetchCustomerList = async () => {
    try {
      const response = await axios.get(`/api/v1/customer/list`);

      if (response.status === 200) {
        setCustomerData(response.data);
        var list = [];
        response.data?.map((res) => {
          const option_format = {
            label: res?.name,
            value: res?.id,
          };
          list.push(option_format);
        });
        setCustomerList(list);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddProduct = () => {
    const alreadyAdded = AddedProduct.some(
      (value) => value?.sku == SearchedProduct?.sku
    );

    if (!alreadyAdded) {
      const data = {
        product_id: SearchedProduct?.id,
        variation_data_id: SearchedProduct?.variation_data_id,
        sale_price: SearchedProduct?.sale_price,
        qty: 1,
        name: SearchedProduct?.name,
        product_image: SearchedProduct?.product_image,
        sku: SearchedProduct?.sku,
      };
      setAddedProduct((prev) => [...prev, data]);
      setSearchProductKeys("");
      document.getElementById("product_code").value = "";
    }
  };

  function handleDeleteAddedProduct(sku) {
    const product_index = AddedProduct.findIndex((item) => item.sku === sku);

    if (product_index !== -1) {
      const updatedProducts = [...AddedProduct];
      updatedProducts.splice(product_index, 1);
      setAddedProduct(updatedProducts);
    }
  }

  function handleProductQty(sku, qty) {
    // console.log(sku, qty);
    const product_index = AddedProduct.findIndex((item) => item.sku === sku);

    if (product_index !== -1) {
      const updatedProducts = [...AddedProduct];
      updatedProducts[product_index].qty =
        updatedProducts[product_index].qty + qty;
      setAddedProduct(updatedProducts);
    }
  }

  function SearchProduct(sku) {
    try {
      if (cancelToken) {
        cancelToken.cancel("New Request Made");
      }
      cancelToken = axios.CancelToken.source();
      axios
        .get(`/api/v1/sale/get-product/${sku}`, {
          cancelToken: cancelToken.token,
        })
        .then((response) => {
          if (response.status === 200) {
            setSearchedProduct(response.data);
          }
        })
        .catch((er) => {
          console.log(er);
          setSearchedProduct(null);
          setProductSearchErrorMessage("No product found.");
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error(error);
      }
    }
  }

  function GetWalletType() {
    axios.get(`/api/v1/sale/props`).then((response) => {
      if (response.status === 200) {
        setWalletType(response.data.wallet);
      }
    });
  }

  const handleSaveRecord = (btntype) => {
    if (!SelectedWalletType) {
      setErrorMessage("You have to select wallet type.");
      return;
    }
    const data = {
      customer_id: SelectedCustomerData?.id ?? null,
      wallet_id: SelectedWalletType.value,
      status: Paid ? 1 : 0,
      promotion: $("input[name='promotion']").val(),
      product_data: AddedProduct,
    };
    axios
      .post("/api/v1/sale/add-record", data, {
        headers: {
          "Content-Type": "application/json",
          // Add any other headers if needed
        },
      })
      .then((response) => {
        if (btntype === "save_record") {
          setAddedProduct([]);
          setVoucherCode("");
          setSelectedCustomerData(null);
          setSelectedWalletType(null);
          setPaid(true);
          setTotalAmount(0.0);
          setPromotion(null);
        } else {
          setVoucherCode(response.data.voucher_code);
          setPrint(true);
        }
        fireToast(response.data.message, "success");
      })
      .catch((error) => {
        // Handle errors
        console.log("Error adding brand:", error);
        fireToast(error.response.data.message, "error");
      });
  };

  function calTotalAmount() {
    var total_amount = 0;
    AddedProduct.map((data) => {
      const qty = data.qty;
      const sale_price = data.sale_price;
      const amount = qty * sale_price;
      total_amount += amount;
    });
    return total_amount;
  }

  useEffect(() => {
    const total_amount = calTotalAmount();
    var promo = promotion ? promotion : 0;
    setTotalAmount(total_amount - promo);
  }, [AddedProduct, promotion]);

  useEffect(() => {
    if (Print) {
      // window.print();
      const print_format = {
        customer: SelectedCustomerData?.name ?? "",
        wallet: SelectedWalletType?.label,
        product_data: AddedProduct,
        total_amount: AddedProduct.length > 0 ? calTotalAmount() : 0.0,
        promotion: promotion ?? 0.0,
        net_amount: totalAmount ?? 0.0,
        status: Paid ? "Paid" : "Unpaid",
        datetime: "",
        voucher_code: VoucherCode,
      };

      const encode_data = btoa(JSON.stringify(print_format));

      setAddedProduct([]);
      setVoucherCode("");
      setSelectedCustomerData(null);
      setSelectedWalletType(null);
      setPaid(true);
      setTotalAmount(0.0);
      setPromotion(null);

      window.open(`/print/${encode_data}`, "_blank");
      setPrint(false);
      // window.location.reload();
    }
  }, [Print]);

  const handleSaveCustomer = () => {
    if (!SelectedCustomerData?.name) {
      fireToast("Customer name is required", "error");
      return;
    }

    if (!SelectedCustomerData?.address) {
      fireToast("Customer address is required", "error");
      return;
    }

    if (!SelectedCustomerData?.phone) {
      fireToast("Customer phone is required", "error");
      return;
    }

    axios
      .post("/api/v1/customer/add", SelectedCustomerData, {
        headers: {
          "Content-Type": "application/json",
          // Add any other headers if needed
        },
      })
      .then((response) => {
        var selected_data = SelectedCustomerData;
        selected_data.id = response?.data?.customer_id;
        setSelectedCustomerData(selected_data);
        fireToast(response.data.message, "success");
        document.getElementById("customer-create-modal").close();
      })
      .catch((error) => {
        // Handle errors
        console.log("Error adding brand:", error);
        fireToast(error.response.data.message, "error");
      })
      .finally(() => {
        fetchCustomerList();
      });
  };

  useEffect(() => {
    GetWalletType();
    fetchCustomerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log(SelectedCustomerData);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [SelectedCustomerData]);

  return (
    <>
      <div className="grid-cols-1 gap-3 max-sm:flex max-sm:flex-col lg:grid lg:mr-[30rem]">
        <div className="flex flex-col col-span-2 gap-3">
          <div className="flex items-center justify-between gap-3 p-3 bg-white border rounded-lg max-sm:flex-col">
            <Select
              id="customer"
              name="customer"
              className="croni-select-container w-[20rem] max-sm:w-full"
              classNamePrefix="croni-select"
              placeholder="Choose Customer"
              options={CustomerList}
              isClearable={true}
              required={true}
              onChange={(e) => {
                if (e?.value) {
                  const selected_customer_id = e.value;
                  const selected_customer = CustomerData?.filter(
                    (customer) => customer?.id == selected_customer_id
                  )[0];
                  setSelectedCustomerData(selected_customer);
                } else {
                  setSelectedCustomerData({});
                }
              }}
            />

            <button
              onClick={() =>
                document.getElementById("customer-create-modal").showModal()
              }
              className="max-sm:w-full btn btn-primary"
            >
              <i className="fa-regular fa-plus"></i>
              New Customer
            </button>
          </div>

          <div
            className={`w-full col-span-2 p-4 bg-white border rounded-lg card h-fit `}
          >
            <div className="flex flex-col w-full gap-4 ">
              <h5>Customer Information</h5>
              <div className="flex flex-col grid-cols-3 gap-3 ">
                <div>
                  <div className="block mb-2">
                    <Label htmlFor="name" value="Customer Name*" />
                  </div>
                  <TextInput
                    type="text"
                    // ref={titleInputRef}
                    readOnly
                    defaultValue={SelectedCustomerData?.name ?? ""}
                    required
                  />
                </div>
                <div>
                  <div className="block mb-2">
                    <Label htmlFor="email" value="Email (Optional)" />
                  </div>
                  <TextInput
                    type="email"
                    defaultValue={SelectedCustomerData?.email ?? ""}
                    readOnly
                  />
                </div>
                <div>
                  <div className="block mb-2">
                    <Label htmlFor="phone" value="Phone *" />
                  </div>
                  <TextInput
                    type="tel"
                    readOnly
                    defaultValue={SelectedCustomerData?.phone ?? ""}
                    required
                  />
                </div>
                <div className="col-span-3 ">
                  <div className="block mb-2">
                    <Label htmlFor="address" value="Address *" />
                  </div>
                  <Textarea
                    readOnly
                    defaultValue={SelectedCustomerData?.address ?? ""}
                    required
                    rows={4}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="md:my-3 lg:hidden" />
        <div className="lg:fixed right-0 w-full lg:w-[30rem] h-full space-y-3 top-0 lg:pt-[4rem]">
          <div className="h-full bg-white border rounded-lg card">
            <div className="flex flex-col w-full gap-3 p-3 border-b">
              {/* <div className="flex flex-col w-full gap-4">
                <h5>Search Product</h5>
              </div> */}
              <div>
                <TextInput
                  onChange={(e) => setSearchProductKeys(e.currentTarget.value)}
                  type="text"
                  id="product_code"
                  name="product_code"
                  placeholder="Product Code"
                  className="col-span-2"
                />
                {ProductSearchErrorMessage && (
                  <i className="text-xs text-error">
                    {ProductSearchErrorMessage}
                  </i>
                )}
              </div>
              {SearchedProduct && (
                <div className="relative flex gap-3 p-2 overflow-hidden rounded-md border-slate-200 bg-slate-100">
                  <div className="avatar">
                    <div className="size-[80px] rounded">
                      {SearchedProduct?.product_image ? (
                        <img
                          src={"/" + SearchedProduct?.product_image}
                          alt={SearchProduct?.name}
                        />
                      ) : (
                        <img src={defaultImage} alt={SearchProduct?.name} />
                      )}
                    </div>
                  </div>
                  <div className="flex-grow">
                    <div className="flex flex-col items-start justify-center ">
                      <span className="font-semibold text-primary">
                        {SearchedProduct?.name}
                      </span>
                      <span className="text-xs italic text-slate-500">
                        Code: #{SearchedProduct?.sku}
                      </span>
                      <span className="text-sm font-semibold">
                        Ks {Formatter(SearchedProduct?.sale_price, "price")}
                      </span>
                    </div>
                  </div>
                  <button
                    disabled={!SearchedProduct}
                    onClick={() => handleAddProduct()}
                    className="h-full w-14  absolute right-0 top-0 btn btn-primary !rounded-l-none "
                  >
                    <i className="fa-regular fa-add"></i>
                  </button>
                </div>
              )}
              {ErrorMessage && (
                <div className="text-xs italic text-error">{ErrorMessage}</div>
              )}
            </div>
            <div className="h-[50vh] lg:h-full overflow-scroll overflow-x-hidden">
              <div className="flex flex-col w-full">
                <div className="flex flex-col w-full gap-2 p-2">
                  {AddedProduct.map((value, index) => (
                    <div
                      key={index}
                      className="relative flex gap-3 p-2 overflow-hidden rounded-md border-slate-200 bg-slate-100"
                    >
                      <div className="avatar">
                        <div className="w-[60px] rounded">
                          {value?.product_image ? (
                            <img
                              src={"/" + value?.product_image}
                              alt={value?.name}
                            />
                          ) : (
                            <img src={defaultImage} alt={value?.name} />
                          )}
                        </div>
                      </div>

                      <div className="flex justify-between flex-grow h-full">
                        <div className="flex flex-col items-start justify-center ">
                          <span className="font-semibold text-primary">
                            {value?.name}
                          </span>
                          <span className="text-xs italic text-slate-500">
                            Code: #{value?.sku}
                          </span>

                          <span className="text-sm font-semibold">
                            Ks{" "}
                            {Formatter(value?.sale_price * value?.qty, "price")}
                          </span>
                        </div>
                        <div className="flex items-center mr-2">
                          <div class="flex items-center">
                            <button
                              className="inline-flex items-center justify-center w-6 h-6 p-1 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full me-3 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                              type="button"
                              disabled={value?.qty < 2}
                              onClick={() => handleProductQty(value?.sku, -1)}
                            >
                              <span class="sr-only">Quantity button</span>
                              <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 18 2"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M1 1h16"
                                />
                              </svg>
                            </button>
                            <div>
                              <input
                                id="first_product"
                                className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg block px-2.5 py-1 "
                                value={value?.qty}
                                readOnly
                                required
                              />
                            </div>
                            <button
                              className="inline-flex items-center justify-center w-6 h-6 p-1 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full ms-3 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                              type="button"
                              onClick={() => handleProductQty(value?.sku, 1)}
                            >
                              <span class="sr-only">Quantity button</span>
                              <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 18 18"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 1v16M1 9h16"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => handleDeleteAddedProduct(value?.sku)}
                        className="flex items-center justify-center -m-2 text-white bg-red-600 cursor-pointer w-14"
                      >
                        <i className="cursor-pointer fa-solid fa-trash "></i>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 p-3 border-t">
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                <div>
                  <TextInput
                    id="promotion"
                    name="promotion"
                    placeholder="Enter Promotion Amount"
                    required
                    value={promotion}
                    onChange={(e) => setPromotion(e.target.value)}
                  />
                </div>
                <div>
                  <Select
                    id="wallet_type_id"
                    name="wallet_type_id"
                    className="croni-select-container"
                    classNamePrefix="croni-select"
                    placeholder="Payment Method"
                    options={WalletType}
                    isClearable={false}
                    required={true}
                    value={SelectedWalletType}
                    onChange={(e) => setSelectedWalletType(e)}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                {AddedProduct.length >= 0 ? (
                  (() => {
                    var total_amount = 0;
                    AddedProduct.map((data, index) => {
                      const qty = data.qty;
                      const sale_price = data.sale_price;
                      const amount = qty * sale_price;
                      total_amount += amount;
                    });
                    return (
                      <div className="flex justify-between gap-2 text-sm">
                        <span className="text-gray-500">Total Amount</span>
                        <span className="font-semibold text-gray-700">
                          Ks {Formatter(total_amount, "price")}
                        </span>
                      </div>
                    );
                  })()
                ) : (
                  <div className="flex justify-between gap-2 text-sm">
                    <span className="text-gray-500">Total Amount</span>
                    <span className="font-semibold text-gray-700">
                      Ks {Formatter(0, "price")}
                    </span>
                  </div>
                )}
                <div className="flex justify-between gap-2 text-sm">
                  <span className="text-gray-500">Promotion</span>
                  <span className="font-semibold text-green-500">
                    Ks -{Formatter(promotion ? promotion : 0, "price")}
                  </span>
                </div>
                <div className="flex justify-between gap-2 ">
                  <span className="">Net amount</span>
                  <span className="font-bold">
                    Ks {Formatter(totalAmount ? totalAmount : 0, "price")}
                  </span>
                </div>
                <div className="mt-2">
                  <ToggleSwitch
                    id="status"
                    name="status"
                    checked={Paid}
                    label={Paid ? "Paid" : "Unpaid"}
                    onClick={() => setPaid((prev) => !prev)}
                  />
                </div>
              </div>

              <div className="grid justify-end grid-cols-2 gap-2 mt-2">
                <button
                  disabled={AddedProduct.length <= 0}
                  onClick={() => handleSaveRecord("save_record")}
                  className="w-full btn btn-secondary"
                >
                  <i className="fa-regular fa-save"></i>
                  <p>Save Record</p>
                </button>
                <button
                  disabled={AddedProduct.length <= 0}
                  onClick={() => handleSaveRecord("save_print")}
                  className="btn btn-primary"
                >
                  <i className="fa-solid fa-print"></i>
                  <p>Save & Print</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateModal
        handleSaveCustomer={handleSaveCustomer}
        setSelectedCustomerData={setSelectedCustomerData}
      />
    </>
  );
}

export default memo(OutgoingStock);
