import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Tooltip } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { IconTrash } from "@tabler/icons-react";
import { useCookies } from "react-cookie";
import DeleteModal from "../../components/modals/sale/voucher-list/delete";
import fireToast from "../../components/fireToast";
import Formatter from "../../utils/formater";

export default function SaleList() {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  const [data, setData] = useState([]);
  const [PrintID, setPrintID] = useState(null);
  const [DeleteDataId, setDeleteDataId] = useState(null);
  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/v1/sale/voucher-list`);
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
  };

  const handleDeleteFunction = async (dataId) => {
    try {
      const response = await axios.delete(
        `/api/v1/sale/delete-record/${dataId}`
      );

      if (response.status === 200) {
        setDeleteDataId(null);
        fireToast(response.data.message, "success");
        document.getElementById("voucher-list-delete-modal").close();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
    fetchData();
  };

  const handleChangeVoucherStatus = async (pID) => {
    try {
      const response = await axios.put(`/api/v1/sale/edit-record/${pID}`, {});
      if (response.status == 200) {
        fireToast("Voucher status changed successfully", "success");
      }
    } catch (error) {
      console.error("Error deleting product variation:", error);
      fireToast(error.response.data.message, "error");
    }
    fetchData();
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (PrintID !== null) {
      const print_data = data[PrintID];
      const print_format = {
        customer: print_data?.voucher_data?.customer?.name,
        wallet: print_data?.voucher_data?.wallet?.name,
        product_data: print_data?.voucher_data?.product_data,
        total_amount:
          parseFloat(print_data?.total_amount) +
          parseFloat(print_data?.promotion),
        promotion: print_data?.promotion > 0 ? print_data?.promotion : 0.0,
        net_amount: print_data?.total_amount ?? 0.0,
        status: print_data?.voucher_status == "paid" ? "Paid" : "Unpaid",
        datetime: print_data?.datetime,
        voucher_code: print_data?.voucher_code,
      };

      const encode_data = btoa(JSON.stringify(print_format));

      window.open(`/print/${encode_data}`, "_blank");
      setPrintID(null);
      // const myFunction = () => {
      //   setPrintID(null);
      // };

      // const timeoutId = setTimeout(myFunction, 4000);

      // return () => {
      //   clearTimeout(timeoutId);
      // };

      //   location.reload();
    }
  }, [PrintID]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "datetime",
        header: "Date",
        Cell: ({ row }) => (
          <div className="flex text-xs flex-nowrap text-nowrap">
            {Formatter(row.original.datetime, "datetime")}
          </div>
        ),
      },
      {
        accessorKey: "voucher_code", //access nested data with dot notation
        header: "Voucher Code",
      },

      {
        enableEditing: false,
        header: "Wallet",
        Cell: ({ row }) => row.original.voucher_data.wallet.name,
      },

      {
        accessorKey: "total_qty", //access nested data with dot notation
        header: "Qty",
      },
      {
        accessorKey: "voucher_status", //access nested data with dot notation
        header: "Paid/Unpaid",
        Cell: ({ row }) =>
          row.original.voucher_status == "unpaid" ? (
            <span className="text-xs font-semibold text-red-600 bg-red-100 border-red-600 badge">
              {row.original.voucher_status}
            </span>
          ) : (
            <span className="text-xs font-semibold text-green-600 bg-green-100 border-green-600 badge">
              {row.original.voucher_status}
            </span>
          ),
      },
      {
        accessorKey: "total_amount", //access nested data with dot notation
        header: "Grand Total",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.total_amount), "price")}
          </span>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => {
      return (
        <div className="flex items-center justify-end gap-4">
          {row.original.voucher_status == "unpaid" && (
            <Tooltip label="Change to paid">
              <ActionIcon
                onClick={() =>
                  handleChangeVoucherStatus(row.original.voucher_code)
                }
              >
                <i className="text-green-500 cursor-pointer fa-solid fa-circle-check fa-lg "></i>
              </ActionIcon>
            </Tooltip>
          )}
          <div className="tooltiip-white">
            <Tooltip label="print">
              <i
                onClick={() => setPrintID(row.index)}
                className="text-blue-600 cursor-pointer fa-solid fa-print fa-lg "
              ></i>
            </Tooltip>
          </div>
          {storedData?.permission.sale_mgmt.includes(4) && (
            <Tooltip label="Delete">
              <ActionIcon
                onClick={() => [
                  setDeleteDataId(row.original),
                  document
                    .getElementById("voucher-list-delete-modal")
                    .showModal(),
                ]}
              >
                <IconTrash className="text-red-400 " />
              </ActionIcon>
            </Tooltip>
          )}
        </div>
      );
    },
    enableExpanding: false,
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <>
      <MantineReactTable table={table} />
      {/* delete modal */}
      <DeleteModal
        handleDeleteFunction={handleDeleteFunction}
        DeleteDataId={DeleteDataId}
      />
    </>
  );
}
