export const getCurrentDateTime = (date) => {
    const now = date ? new Date(date): new Date();
  
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(now);
  
    return formattedDateTime;
  };