import { Label, TextInput } from "flowbite-react";
import clearForm from "../../../../../utils/clearForm";

const EditModal = ({ EditData, editFormRef, handleSaveRow }) => {
  return (
    <dialog
      id="product-detail-list-edit-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Edit Sub Product</h3>
          <form method="dialog">
            <button
              className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
              onClick={() => clearForm()}
            >
              ✕
            </button>
          </form>
        </div>
        <form
          ref={editFormRef}
          onSubmit={handleSaveRow}
          autoComplete="off"
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form"
        >
          <div>
            <TextInput
              required
              defaultValue={EditData?.id}
              type="hidden"
              name="pvID"
            />
          </div>
          <div>
            <div className="block mb-2">
              <Label htmlFor="SKU" value="SKU" />
            </div>
            <TextInput
              required
              defaultValue={EditData?.sku}
              type="text"
              name="sku"
            />
          </div>

          <div>
            <div className="block mb-2">
              <Label htmlFor="sale_price" value="Sale Price" />
            </div>
            <TextInput
              required
              defaultValue={EditData?.sale_price}
              name="sale_price"
            />
          </div>
          <div>
            <div className="block mb-2">
              <Label htmlFor="ygn_sale" value="YGN Sale" />
            </div>
            <TextInput
              required
              defaultValue={EditData?.ygn_sale}
              name="ygn_sale"
            />
          </div>
          <div>
            <div className="block mb-2">
              <Label htmlFor="whole_sale" value="Whole Sale" />
            </div>
            <TextInput
              required
              defaultValue={EditData?.whole_sale}
              name="whole_sale"
            />
          </div>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <form method="dialog">
              <button
                type="submit"
                className="w-full btn btn-secondary"
                onClick={() => clearForm()}
              >
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default EditModal;
