import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Label, Modal, TextInput } from "flowbite-react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import axios from "axios";
import { IconEdit } from "@tabler/icons-react";
import { IconTrash } from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";

const SubCategoryList = () => {
  const { catId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const titleInputRef = useRef(null);
  const titleEditInputRef = useRef(null);
  const imageRef = useRef(null);
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image file
  const props = { openDeleteModal, setOpenDeleteModal };
  const [EditData, setEditData] = useState({});
  const [DeleteDataId, setDeleteDataId] = useState(null);
  const [MainCatData, setMainCatData] = useState({});
  const history = useNavigate();

  const handleCameraButtonClick = () => {
    imageRef.current.click();
  };

  const handleImageChange = (event) => {
    // Update the selectedImage state with the selected file
    setSelectedImage(event.target.files[0]);
  };

  const handleImageRemove = () => {
    // Clear the selected image when the remove button is clicked
    setSelectedImage(null);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Create a FormData object to send the form data
    const formData = new FormData();
    formData.append("categoryTitle", titleInputRef.current.value);
    formData.append("categoryIcon", selectedImage);

    // Send the formData to the backend API using Axios
    axios
      .post(`/api/v1/category/sub-category/add/${catId}`, formData)
      .then((response) => {
        if (response.status === 201) {
          // Handle success
          console.log("Brand added successfully");
        }
      })
      .catch((error) => {
        // Handle errors
        console.log("Error adding brand:", error);
      })
      .finally((a) => {
        setOpenModal(false); // Close the modal after successful submission
        fetchData();
        setSelectedImage(null);
      });
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    // Create a FormData object to send the form data
    const formData = new FormData();
    formData.append("categoryTitle", titleEditInputRef.current.value);
    formData.append("categoryIcon", selectedImage);

    // Send the formData to the backend API using Axios
    axios
      .post(
        `/api/v1/category/sub-category/edit-category/${EditData.id}`,
        formData
      )
      .then((response) => {
        if (response.status === 200) {
          // Handle success
          console.log("Brand added successfully");
        }
      })
      .catch((error) => {
        // Handle errors
        console.log("Error adding brand:", error);
      })
      .finally((a) => {
        setOpenEditModal(false);
        setEditData({}); // Close the modal after successful submission
        fetchData();
        setSelectedImage(null);
      });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        header: "Title",
      },
      {
        enableEditing: false,
        header: "Image",
        Cell: ({ row }) => (
          <div className="w-20 aspect-square bg-slate-400">
            <img
              alt={row.original.name}
              className="object-cover w-full shadow-lg aspect-square bg-slate-400"
              src={"/" + row.original.icon}
            />
          </div>
        ),
      },
    ],
    []
  );

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/api/v1/category/sub-category/${catId}`
      );

      if (response.status === 200) {
        setData(response.data.data);
        setMainCatData(response.data.cat_data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //DELETE action

  const handleDeleteFunction = async (dataId) => {
    const formData = new FormData();
    formData.append("categoryID", dataId);
    try {
      const response = await axios.post(
        "/api/v1/category/sub-category/delete-category",
        formData
      );
      if (response.status === 200) {
        setDeleteDataId(null);
        props.setOpenDeleteModal(undefined);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    fetchData();
  };

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              setOpenEditModal(true);
              setEditData(row.original);
            }}
          >
            <IconEdit className="text-yellow-500" />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Delete">
          <ActionIcon
            onClick={() => {
              props.setOpenDeleteModal("dismissible");
              setDeleteDataId(row.original);
            }}
          >
            <IconTrash className="text-red-400 " />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    enableEditing: true,
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableExpanding: false,
    columns,
    data,
  });

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          <span
            onClick={() => history(-1)}
            className="text-gray-700 cursor-pointer hover:underline"
          >
            Category List
          </span>
          <span>
            {" "}
            {">"} {MainCatData.name || "..."}
          </span>
        </h5>
        <Button color="success" onClick={() => setOpenModal(true)}>
          <span className="flex items-center gap-2">
            <i className="fa-light fa-plus"></i>
            <p>Add Category</p>
          </span>
        </Button>
      </div>
      <MantineReactTable table={table} />
      {/* add model */}
      <Modal
        show={openModal}
        size="md"
        popup
        onClose={() => setOpenModal(false)}
        initialFocus={titleInputRef}
      >
        <Modal.Header />
        <Modal.Body>
          <form className="space-y-6" onSubmit={handleFormSubmit}>
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Add new brand
            </h3>
            <center>
              <div className="w-[10rem] h-[10rem] rounded-md overflow-hidden relative">
                <img
                  className="object-cover w-full "
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : "/placeholder.jpg"
                  }
                  alt=""
                />
                <div className="absolute bottom-0 flex justify-center w-full bg-opacity-50 bg-slate-400">
                  {!selectedImage ? (
                    <div className="flex gap-8">
                      <button
                        type="button"
                        className="p-2 rounded-full text-slate-800 "
                        onClick={handleCameraButtonClick}
                      >
                        <i className="text-lg fa-light fa-camera"></i>
                      </button>
                    </div>
                  ) : (
                    <div className="flex gap-8">
                      <button
                        type="button"
                        className="p-2 text-white rounded-full "
                        onClick={handleCameraButtonClick}
                      >
                        <i className="text-lg text-yellow-400 fa-light fa-pen-to-square"></i>
                      </button>
                      <button
                        type="button"
                        className="p-2 text-white rounded-full "
                        onClick={handleImageRemove}
                      >
                        <i className="text-lg text-red-600 fa-regular fa-trash-xmark"></i>
                      </button>
                    </div>
                  )}

                  <input
                    ref={imageRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="absolute opacity-0 -z-30"
                  />
                </div>
              </div>
            </center>
            <div>
              <div className="block mb-2">
                <Label htmlFor="title" value="Category Title" />
              </div>
              <TextInput
                id="title"
                name="title"
                ref={titleInputRef}
                placeholder="Enter category title"
                required
              />
            </div>
            <Button type="submit" className="w-full">
              Submit
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      {/* edit model */}
      <Modal
        show={openEditModal}
        size="md"
        popup
        onClose={() => setOpenEditModal(false)}
        initialFocus={titleInputRef}
      >
        <Modal.Header />
        <Modal.Body>
          <form className="space-y-6" onSubmit={handleEditFormSubmit}>
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Edit brand
            </h3>
            <center>
              <div className="w-[10rem] h-[10rem] rounded-md overflow-hidden relative">
                <img
                  className="object-cover w-full "
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : "/" + EditData.icon
                  }
                  alt=""
                />
                <div className="absolute bottom-0 flex justify-center w-full bg-opacity-50 bg-slate-400">
                  {!selectedImage ? (
                    <div className="flex gap-8">
                      <button
                        type="button"
                        className="p-2 rounded-full text-slate-800 "
                        onClick={handleCameraButtonClick}
                      >
                        <i className="text-lg fa-light fa-camera"></i>
                      </button>
                    </div>
                  ) : (
                    <div className="flex gap-8">
                      <button
                        type="button"
                        className="p-2 text-white rounded-full "
                        onClick={handleCameraButtonClick}
                      >
                        <i className="text-lg text-yellow-400 fa-light fa-pen-to-square"></i>
                      </button>
                      <button
                        type="button"
                        className="p-2 text-white rounded-full "
                        onClick={handleImageRemove}
                      >
                        <i className="text-lg text-red-600 fa-regular fa-trash-xmark"></i>
                      </button>
                    </div>
                  )}

                  <input
                    ref={imageRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="absolute opacity-0 -z-30"
                  />
                </div>
              </div>
            </center>
            <div>
              <div className="block mb-2">
                <Label htmlFor="title" value="Brand Title" />
              </div>
              <TextInput
                id="title"
                name="title"
                ref={titleEditInputRef}
                placeholder="Enter brand title"
                defaultValue={EditData.name}
                required
              />
            </div>
            <Button type="submit" className="w-full">
              Submit
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      {/* delete modal */}
      <Modal
        dismissible
        show={props.openDeleteModal === "dismissible"}
        onClose={() => props.setOpenDeleteModal(undefined)}
      >
        <Modal.Header>Are you sure to want to delete?</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <b>{DeleteDataId && DeleteDataId.name}</b> will be permanently
              delete.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="red"
            onClick={() => handleDeleteFunction(DeleteDataId.id)}
          >
            Continue
          </Button>
          <Button
            color="gray"
            onClick={() => props.setOpenDeleteModal(undefined)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SubCategoryList;
