import { useEffect, useMemo, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import axios from "axios";
import { IconEdit, IconSettings } from "@tabler/icons-react";
import { IconTrash } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import fireToast from "../../components/fireToast";
import clearForm from "../../utils/clearForm";
import CreateModal from "../../components/modals/setting/variantOptions/create";
import EditModal from "../../components/modals/setting/variantOptions/edit";
import DeleteModal from "../../components/modals/setting/variantOptions/delete";

const VarientOptions = () => {
  const [data, setData] = useState([]);
  const [EditData, setEditData] = useState({});
  const [DeleteDataId, setDeleteDataId] = useState(null);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    axios
      .post("/api/v1/variation/add-variation", formData)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          fireToast("Variant added successfully", "success");
        }
      })
      .catch((error) => {
        console.log("Error:", error);
        fireToast(error.response.data.message, "error");
      })
      .finally((a) => {
        fetchData();
        document.getElementById("variant-option-create-modal").close();
        clearForm();
      });
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    axios
      .post(`/api/v1/variation/edit-variation/${EditData.id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          fireToast("Variant updated successfully", "success");
        }
      })
      .catch((error) => {
        // Handle errors
        console.log("Error:", error);
        fireToast(error.response.data.message, "error");
      })
      .finally((a) => {
        setEditData({});
        document.getElementById("variant-option-edit-modal").close();
        clearForm();
        fetchData();
      });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        header: "Title",
      },
    ],
    []
  );

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/variation");

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //DELETE action

  const handleDeleteFunction = async (dataId) => {
    const formData = new FormData();
    formData.append("variationID", dataId);
    try {
      const response = await axios.post(
        "/api/v1/variation/delete-variation",
        formData
      );
      if (response.status === 200) {
        setDeleteDataId(null);
        fireToast("Variant deleted successfully", "success");
      }
    } catch (error) {
      console.error("Error:", error);
      fireToast(error.response.data.message, "error");
    }
    fetchData();
    document.getElementById("variant-option-delete-modal").close();
  };

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => [
              setEditData(row.original),
              document.getElementById("variant-option-edit-modal").showModal(),
            ]}
          >
            <IconEdit className="text-yellow-500" />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Delete">
          <ActionIcon
            onClick={() => [
              setDeleteDataId(row.original),
              document
                .getElementById("variant-option-delete-modal")
                .showModal(),
            ]}
          >
            <IconTrash className="text-red-400 " />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Options">
          <Link to={`list/${row.original.id}`}>
            <ActionIcon>
              <IconSettings className="text-blue-600 " />
            </ActionIcon>
          </Link>
        </Tooltip>
      </Flex>
    ),
    enableEditing: true,
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableExpanding: false,
    columns,
    data,
  });

  return (
    <div>
      <MantineReactTable table={table} />
      {/* add model */}
      <CreateModal handleFormSubmit={handleFormSubmit} />
      {/* edit model */}
      <EditModal
        handleEditFormSubmit={handleEditFormSubmit}
        EditData={EditData}
      />
      {/* delete modal */}
      <DeleteModal
        handleDeleteFunction={handleDeleteFunction}
        DeleteDataId={DeleteDataId}
      />
    </div>
  );
};

export default VarientOptions;
