import { Label, TextInput, Textarea } from "flowbite-react";
import clearForm from "../../../../utils/clearForm";

const CreateModal = ({ handleSaveCustomer, setSelectedCustomerData }) => {
  const clearAll = () => {
    clearForm();
  };
  return (
    <dialog
      id="customer-create-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Add Customer</h3>
          <form method="dialog">
            <button
              className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
              onClick={() => clearAll()}
            >
              ✕
            </button>
          </form>
        </div>
        <div className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form">
          <div className="flex flex-col grid-cols-3 gap-3 ">
            <div>
              <div className="block mb-2">
                <Label htmlFor="name" value="Customer Name*" />
              </div>
              <TextInput
                type="text"
                id="name"
                name="name"
                // ref={titleInputRef}
                onChange={(e) =>
                  setSelectedCustomerData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
                required
              />
            </div>
            <div>
              <div className="block mb-2">
                <Label htmlFor="email" value="Email (Optional)" />
              </div>
              <TextInput
                type="email"
                id="email"
                name="email"
                onChange={(e) =>
                  setSelectedCustomerData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <div className="block mb-2">
                <Label htmlFor="phone" value="Phone *" />
              </div>
              <TextInput
                type="phone"
                id="phone"
                name="phone"
                onChange={(e) =>
                  setSelectedCustomerData((prev) => ({
                    ...prev,
                    phone: e.target.value,
                  }))
                }
                required
              />
            </div>
            <div className="col-span-3 ">
              <div className="block mb-2">
                <Label htmlFor="address" value="Address *" />
              </div>
              <Textarea
                id="address"
                name="address"
                onChange={(e) =>
                  setSelectedCustomerData((prev) => ({
                    ...prev,
                    address: e.target.value,
                  }))
                }
                required
                rows={4}
              />
            </div>
          </div>

          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleSaveCustomer()}
            >
              Submit
            </button>
            <form method="dialog">
              <button
                type="submit"
                className="w-full btn btn-secondary"
                onClick={() => clearAll()}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default CreateModal;
