import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import fireToast from "../../../components/fireToast";
import CreateModal from "../../../components/modals/otherFeatures/wallet/create";
import DeleteModal from "../../../components/modals/otherFeatures/wallet/delete";
import Formatter from "../../../utils/formater";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useCookies } from "react-cookie";
import EditModal from "../../../components/modals/otherFeatures/wallet/edit";
import clearForm from "../../../utils/clearForm";
import { useNavigate } from "react-router-dom";

const WalletManagement = () => {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  const router = useNavigate();

  const [data, setData] = useState([]);
  // State to store the selected image file
  const [EditData, setEditData] = useState({});
  const [DeleteData, setDeleteData] = useState({});

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios
      .post("/api/v1/wallet/create", formData)
      .then(() => {
        fetchData();
        fireToast("Wallet created successfully", "success");
      })
      .catch((error) => {
        console.error(error);
        fireToast(error.response.data.message, "error");
      })
      .finally(() => {
        document.getElementById("wallet-create-modal").close();
        clearForm();
      });
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios
      .put(`/api/v1/wallet/update/${EditData.id}`, formData)
      .then(() => {
        fireToast("Wallet updated successfully", "success");
      })
      .catch((error) => fireToast(error.response.data.message, "error"))
      .finally(() => {
        setEditData({});
        fetchData();
        clearForm();
        document.getElementById("wallet-edit-modal").close();
      });
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/wallet");

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
  };

  //DELETE action
  const handleDeleteFunction = async (dataId) => {
    try {
      const response = await axios.delete(`/api/v1/wallet/delete/${dataId}`);
      if (response.status === 200) {
        fireToast(response.data.message, "success");
        document.getElementById("wallet-delete-modal").close();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "datetime",
        header: "Created at",
        Cell: ({ row }) => (
          <div className="flex text-xs flex-nowrap text-nowrap">
            {Formatter(row.original.datetime, "date")}
          </div>
        ),
      },
      {
        accessorKey: "id",
        header: "Wallet ID",
      },
      {
        accessorKey: "name",
        header: "Wallet Name",
      },
      {
        accessorKey: "total_amount",
        header: "Total Amount",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.total_amount), "price")}
          </span>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        {storedData?.permission.wallet_mgmt.includes(3) && (
          <Tooltip label="Edit">
            <ActionIcon
              onClick={() => [
                setEditData(row.original),
                document.getElementById("wallet-edit-modal").showModal(),
              ]}
            >
              <IconEdit className="text-yellow-500" />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.wallet_mgmt.includes(4) && (
          <Tooltip label="Delete">
            <ActionIcon
              onClick={() => [
                setDeleteData(row.original),
                document.getElementById("wallet-delete-modal").showModal(),
              ]}
            >
              <IconTrash className="text-red-400 " />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.wallet_mgmt.includes(1) && (
          <Tooltip label="Log">
            <ActionIcon
              onClick={() =>
                router(`/other-features/wallet/log/${row.original.id}`)
              }
            >
              <span className="flex items-center gap-2 text-cyan-800 ">
                <i className="fa-regular fa-sitemap"></i>
              </span>
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),
    enableEditing: true,
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableExpanding: false,
    columns,
    data,
  });

  return (
    <div>
      <MantineReactTable table={table} />

      {/* add model */}
      <CreateModal handleFormSubmit={handleFormSubmit} />
      {/* edit model */}
      <EditModal
        handleEditFormSubmit={handleEditFormSubmit}
        EditData={EditData}
      />
      {/* delete modal */}
      <DeleteModal
        DeleteDataId={DeleteData}
        handleDeleteFunction={handleDeleteFunction}
      />
    </div>
  );
};

export default WalletManagement;
