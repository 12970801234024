import ToolboxSection from "../../../toolbox-button-group";

//!required to edit
const AddSupplierButton = () => {
  return (
    // storedData?.permission.user_mgmt.includes(1) && (
    <ToolboxSection>
      <button
        onClick={() =>
          document.getElementById("supplier-list-create-modal").showModal()
        }
        className="btn btn-primary max-sm:w-full"
      >
        <span className="flex items-center gap-2">
          <i className="fa-solid fa-plus"></i>
          <p>Add Supplier</p>
        </span>
      </button>
    </ToolboxSection>
  );
  // );
};

export default AddSupplierButton;
