const DeleteModal = ({ DeleteDataId, handleDeleteFunction }) => {
  return (
    <dialog
      id="variant-detail-delete-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Delete Variant Option</h3>
          <form method="dialog">
            <button className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2">
              ✕
            </button>
          </form>
        </div>
        <div className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible">
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Variant Option <b>{DeleteDataId && DeleteDataId.name}</b> will be
              permanently delete.
            </p>
          </div>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button
              onClick={() => handleDeleteFunction(DeleteDataId?.id)}
              className="btn btn-error"
            >
              Delete
            </button>
            <form method="dialog">
              <button type="submit" className="w-full btn btn-secondary">
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default DeleteModal;
