import { MantineReactTable } from "mantine-react-table";

const MainTable = ({ table, ...props }) => {
  return (
    <div className={`phoenix-main-table ${props.className}`}>
      <MantineReactTable table={table} />
    </div>
  );
};

export default MainTable;
