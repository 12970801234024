import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import MainLayout from "./lib/layout";
import "./lib/css/all.min.css";
import { useEffect, useState } from "react";
import PrintPage from "./lib/pages/print";
import { navigationLinks } from "./lib/utils/settings";
import Login from "./lib/pages/login/login";
import { useCookies } from "react-cookie";
import "./lib/css/style.css";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [cookies] = useCookies();

  useEffect(() => {
    // const storedData = JSON.parse(sessionStorage.getItem("userSession"));
    const storedData = cookies["userSession"];
    // console.log(storedData);
    if (storedData) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          {/* <Route
            index
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
          />
          <Route
            path="product-register"
            element={
              isAuthenticated ? <RegisterProduct /> : <Navigate to="/login" />
            }
          />
          <Route
            path="product-list"
            element={
              isAuthenticated ? <ViewProduct /> : <Navigate to="/login" />
            }
          />
          <Route
            path="product-list/:pId"
            element={
              isAuthenticated ? (
                <ViewProductVariationList />
              ) : (
                <Navigate to="/login" />
              )
            }
          /> */}
          {/* <Route
            path="product-edit/:pId"
            element={
              isAuthenticated ? <EditProduct /> : <Navigate to="/login" />
            }
          />
          <Route
            path="purchase-list"
            element={
              isAuthenticated ? <ViewPurchaseList /> : <Navigate to="/login" />
            }
          />
          <Route
            path="purchase-add"
            element={isAuthenticated ? <AddStock /> : <Navigate to="/login" />}
          />
          <Route
            path="purchase-edit/:purchaseId"
            element={isAuthenticated ? <EditStock /> : <Navigate to="/login" />}
          />
          <Route
            path="brand-list"
            element={isAuthenticated ? <BrandList /> : <Navigate to="/login" />}
          />
          <Route
            path="category-list"
            element={
              isAuthenticated ? <CategoryList /> : <Navigate to="/login" />
            }
          />
          <Route
            path="category-list/:catId"
            element={
              isAuthenticated ? <SubCategoryList /> : <Navigate to="/login" />
            }
          />
          <Route
            path="variant-option"
            element={
              isAuthenticated ? <VarientOptions /> : <Navigate to="/login" />
            }
          />
          <Route
            path="variant-option/:variantId"
            element={
              isAuthenticated ? <VariantDetails /> : <Navigate to="/login" />
            }
          />
          <Route
            path="supplier-list"
            element={isAuthenticated ? <Supplier /> : <Navigate to="/login" />}
          />
          <Route
            path="sale"
            element={
              isAuthenticated ? <OutgoingStock /> : <Navigate to="/login" />
            }
          />
          <Route
            path="voucher-list"
            element={isAuthenticated ? <SaleList /> : <Navigate to="/login" />}
          />
          <Route
            path="wallet"
            element={
              isAuthenticated ? <WalletManagement /> : <Navigate to="/login" />
            }
          />
          <Route
            path="expense-category"
            element={
              isAuthenticated ? <ExpenseCategories /> : <Navigate to="/login" />
            }
          />
          <Route
            path="expense-list"
            element={
              isAuthenticated ? <ExpenseManagement /> : <Navigate to="/login" />
            }
          />
          <Route
            path="money-exchange"
            element={
              isAuthenticated ? <MoneyExchange /> : <Navigate to="/login" />
            }
          />
          <Route
            path="payable"
            element={isAuthenticated ? <Payable /> : <Navigate to="/login" />}
          />
          <Route
            path="payable/:noteId"
            element={
              isAuthenticated ? <PayableLog /> : <Navigate to="/login" />
            }
          />
          <Route
            path="profit"
            element={
              isAuthenticated ? <ViewProfit /> : <Navigate to="/login" />
            }
          /> */}
          {/* <Route
            path="user-list"
            element={
              isAuthenticated ? <ViewUserList /> : <Navigate to="/login" />
            }
          />
          <Route
            path="user-group-list"
            element={
              isAuthenticated ? <ViewUserGroupList /> : <Navigate to="/login" />
            }
          />
          <Route
            path="daily-sale-list"
            element={
              isAuthenticated ? <DailySaleList /> : <Navigate to="/login" />
            }
          />
          <Route
            path="customer-list"
            element={
              isAuthenticated ? <ViewCustomerList /> : <Navigate to="/login" />
            }
          /> */}
          {navigationLinks.map((link, index) => {
            return (
              <Route
                key={index}
                path={link.href}
                element={
                  isAuthenticated ? link.component : <Navigate to="/login" />
                }
              />
            );
          })}
        </Route>
        {navigationLinks.map((link, index) => (
          <Route
            key={index}
            path={link.href}
            element={
              isAuthenticated ? <MainLayout /> : <Navigate to="/login" />
            }
          >
            {link.children?.map((sub_link, sub_index) => (
              <Route
                key={sub_index + "_" + index}
                path={`${link.href}${sub_link.href}`}
                element={
                  isAuthenticated ? (
                    sub_link.component
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
            ))}
          </Route>
        ))}
        {navigationLinks.map((link, index) => (
          <Route
            key={index}
            path={link.href}
            element={
              isAuthenticated ? <MainLayout /> : <Navigate to="/login" />
            }
          >
            {link.children?.map((sub_link, sub_index) =>
              sub_link.children?.map((micro_link, micro_index) => {
                return (
                  <Route
                    key={micro_index + "_" + sub_index + "_" + index}
                    path={`${link.href}${sub_link.href}${micro_link.href}${micro_link.parm}`}
                    element={
                      isAuthenticated ? (
                        micro_link.component
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                  />
                );
              })
            )}
          </Route>
        ))}

        <Route path="print/:data" element={<PrintPage />} />
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/" /> : <Login />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
