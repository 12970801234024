import { toast } from "react-toastify";

const fireToast = (message, type) => {
  const options = {
    position: "top-center",
  };
  if (type === "success") {
    toast.success(message, options);
  } else if (type === "warning") {
    toast.warn(message, options);
  } else if (type === "error") {
    toast.error(message, options);
  } else {
    toast.info(message, options);
  }
};

export default fireToast;
