import { useEffect, useMemo, useRef, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import axios from "axios";
import { IconEdit } from "@tabler/icons-react";
import { IconTrash } from "@tabler/icons-react";
import CreateModal from "../../components/modals/setting/categoryList/create";
import fireToast from "../../components/fireToast";
import clearForm from "../../utils/clearForm";
import defaultImage from "../../../image/default_product-768x768.jpg";
import EditModal from "../../components/modals/setting/categoryList/edit";
import DeleteModal from "../../components/modals/setting/categoryList/delete";

const CategoryList = () => {
  const imageRef = useRef(null);
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image file
  const [EditData, setEditData] = useState({});
  const [DeleteDataId, setDeleteDataId] = useState(null);

  const handleCameraButtonClick = () => {
    imageRef.current.click();
  };

  const handleImageChange = (event) => {
    // Update the selectedImage state with the selected file
    setSelectedImage(event.target.files[0]);
  };

  const handleImageRemove = () => {
    // Clear the selected image when the remove button is clicked
    setSelectedImage(null);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    formData.append("categoryIcon", selectedImage);

    axios
      .post("/api/v1/category/add-category", formData)
      .then(() => {
        fireToast("Category added successfully", "success");
      })
      .catch((error) => {
        fireToast(error.response.data.message, "error");
      })
      .finally(() => {
        fetchData();
        setSelectedImage(null);
        document.getElementById("category-create-modal").close();
        clearForm();
      });
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    formData.append("categoryIcon", selectedImage);

    axios
      .post(`/api/v1/category/edit-category/${EditData.id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          fireToast("Category edited successfully", "success");
        }
      })
      .catch((error) => {
        fireToast(error.response.data.error, "error");
        console.error("Error editing data:", error);
      })
      .finally((a) => {
        setEditData({});
        fetchData();
        document.getElementById("category-edit-modal").close();
        clearForm();
        setSelectedImage(null);
      });
  };

  const columns = useMemo(
    () => [
      {
        enableEditing: false,
        header: "Image",
        Cell: ({ row }) => (
          <div className="w-20 overflow-hidden border rounded-md aspect-square bg-slate-50">
            {row.original.icon ? (
              <img
                alt={row.original.name}
                className="object-cover w-full border rounded-md aspect-square bg-slate-50"
                src={"/" + row.original.icon}
              />
            ) : (
              <img
                alt={row.original.name}
                className="object-cover w-full border rounded-md aspect-square bg-slate-50"
                src={defaultImage}
              />
            )}
          </div>
        ),
      },
      {
        accessorKey: "name",
        header: "Title",
      },
    ],
    []
  );

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/category");

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //DELETE action

  const handleDeleteFunction = async (dataId) => {
    const formData = new FormData();
    formData.append("categoryID", dataId);
    try {
      const response = await axios.post(
        "/api/v1/category/delete-category",
        formData
      );
      if (response.status === 200) {
        setDeleteDataId(null);
        fireToast(response.data.message, "success");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
    fetchData();
    document.getElementById("category-delete-modal").close();
  };

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => [
              setEditData(row.original),
              document.getElementById("category-edit-modal").showModal(),
            ]}
          >
            <IconEdit className="text-yellow-500" />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Delete">
          <ActionIcon
            onClick={() => [
              setDeleteDataId(row.original),
              document.getElementById("category-delete-modal").showModal(),
            ]}
          >
            <IconTrash className="text-red-400 " />
          </ActionIcon>
        </Tooltip>
        {/* <Tooltip label="Sub Categories">
          <Link to={`${row.original.id}`}>
            <ActionIcon>
              <IconCube className="text-blue-600 " />
            </ActionIcon>
          </Link>
        </Tooltip> */}
      </Flex>
    ),
    enableEditing: true,
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableExpanding: false,
    columns,
    data,
  });

  return (
    <>
      <MantineReactTable table={table} />
      {/* add model */}
      <CreateModal
        handleFormSubmit={handleFormSubmit}
        selectedImage={selectedImage}
        imageRef={imageRef}
        handleImageChange={handleImageChange}
        handleCameraButtonClick={handleCameraButtonClick}
        handleImageRemove={handleImageRemove}
      />
      {/* edit model */}
      <EditModal
        handleEditFormSubmit={handleEditFormSubmit}
        selectedImage={selectedImage}
        imageRef={imageRef}
        handleImageChange={handleImageChange}
        handleCameraButtonClick={handleCameraButtonClick}
        handleImageRemove={handleImageRemove}
        EditData={EditData}
      />

      {/* delete modal */}
      <DeleteModal
        DeleteDataId={DeleteDataId}
        handleDeleteFunction={handleDeleteFunction}
      />
    </>
  );
};

export default CategoryList;
