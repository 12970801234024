import { useEffect, useState } from "react";
import { useCurrentPath } from "../hooks/useCurrentPath";

const Wrapper = ({ children }) => {
  const { currentPath, currentSubPath, currentMicroPath } = useCurrentPath();
  const [Title, setTitle] = useState("");
  const [ToolBar, setToolBar] = useState(undefined);

  useEffect(() => {
    getTitle();
  }, [currentPath, currentSubPath, currentMicroPath]);

  const getTitle = () => {
    if (currentMicroPath) {
      setTitle(currentMicroPath.title);
      setToolBar(currentMicroPath.toolbox);
    } else if (currentSubPath) {
      setTitle(currentSubPath.title);
      setToolBar(currentSubPath.toolbox);
    } else {
      setTitle(currentPath.title);
      setToolBar(currentPath.toolbox);
    }
  };

  return (
    <div className="container mt-20">
      <div className="flex flex-col flex-wrap justify-between w-full gap-3 mb-4 md:items-center md:flex-row ">
        <h1
          id="page-title"
          className="text-xl font-semibold lg:text-2xl drop-shadow-sm"
        >
          {Title}
        </h1>
        <div id="toolbox" className="max-sm:w-full">
          {ToolBar?.toolboxItem}
        </div>
      </div>
      {children}
    </div>
  );
};

export default Wrapper;
