import { Link } from "react-router-dom";
import { useCurrentPath } from "../hooks/useCurrentPath";

const MenuPage = () => {
  const { currentPath } = useCurrentPath();

  return (
    <div className="grid w-full grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-6 gap-y-8">
      {currentPath.children?.map(
        (sub_link, index) =>
          sub_link.active &&
          sub_link.show_in_menu !== false && (
            <Link
              to={currentPath.href + sub_link.href}
              key={index}
              className="flex flex-col items-center w-full gap-1 "
            >
              <div className="flex flex-col text-primary border-primary rounded-xl hover:text-white hover:bg-gradient-to-tr from-orange-500 to-orange-300 items-center justify-center w-full gap-4 p-6 bg-orange-50 border hover:shadow-md hover:border-primary  hover:shadow-orange-100 hover:bg-[unset] btn h-fit">
                <i className={`fa-light   ${sub_link.icon} text-[2.5rem]`}></i>
                {/* <div className="size-[80px] aspect-square">
                  <img
                    src={sub_link.icon}
                    className="object-cover w-full aspect-square"
                    alt={sub_link.title}
                  />
                </div> */}
              </div>
              <span className="font-semibold">{sub_link.title}</span>
            </Link>
          )
      )}
    </div>
  );
};

export default MenuPage;
