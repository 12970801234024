import { useEffect, useMemo, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import axios from "axios";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import Formatter from "../../../utils/formater";
import CreateModal from "../../../components/modals/otherFeatures/receivable/detail/create";
import fireToast from "../../../components/fireToast";
import clearForm from "../../../utils/clearForm";
import DeleteModal from "../../../components/modals/otherFeatures/receivable/detail/delete";
import EditModal from "../../../components/modals/otherFeatures/receivable/detail/edit";

const ReceivableLog = () => {
  const { noteId } = useParams();
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  const [data, setData] = useState([]);
  // State to store the selected image file
  const [EditData, setEditData] = useState({});
  const [DeleteDataId, setDeleteDataId] = useState(null);
  const [WalletType, setWalletType] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(true);
  const [ClearForm, setClearForm] = useState(false);

  function GetWalletType() {
    axios.get(`/api/v1/sale/props`).then((response) => {
      if (response.status === 200) {
        setWalletType(response.data.wallet);
      }
    });
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Create a FormData object to send the form data
    const formData = new FormData(event.target);
    formData.append("type", 1);
    formData.append("note_id", noteId);
    // Send the formData to the backend API using Axios
    axios
      .post("/api/v1/receivable-log/add-record", formData)
      .then((response) => {
        if (response.status === 201) {
          // Handle success
          fireToast(response.data.message, "success");
        }
      })
      .catch((error) => {
        // Handle errors
        console.log("Error :", error);
        fireToast(error.response.data.message, "error");
      })
      .finally((a) => {
        fetchData();
        document.getElementById("receivable-detail-create-modal").close();
        clearForm();
      });
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("note_id", noteId);
    axios
      .put(`/api/v1/receivable-log/${EditData.log_id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          fireToast(response.data.message, "success");
        }
      })
      .catch((error) => {
        // Handle errors
        fireToast(error.response.data.message, "error");
        console.log("Error :", error);
      })
      .finally((a) => {
        setEditData({});
        fetchData();
        document.getElementById("receivable-detail-edit-modal").close();
        setClearForm(!ClearForm);
      });
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/v1/receivable-log/${noteId}`);
      if (response.status === 200) {
        if (response.data[0]?.remain_amount <= 0) {
          setShowCreateModal(false);
          document.getElementById("make-receivable-payment").disabled = true;
        } else {
          setShowCreateModal(true);
          document.getElementById("make-receivable-payment").disabled = false;
        }
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
  };

  //DELETE action

  const handleDeleteFunction = async (dataId) => {
    try {
      const response = await axios.delete(`/api/v1/receivable-log/${dataId}`);
      if (response.status === 200) {
        setDeleteDataId(null);
        fireToast(response.data.message, "success");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
    document.getElementById("receivable-detail-delete-modal").close();
    fetchData();
  };

  useEffect(() => {
    GetWalletType();
    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "log_id",
        header: "Record ID",
      },
      {
        accessorKey: "note_record_time",
        header: "Record Datetime",
        Cell: ({ row }) => (
          <div className="flex text-xs flex-nowrap text-nowrap">
            {Formatter(row.original.note_record_time, "date")}
          </div>
        ),
      },
      {
        accessorKey: "customer_name",
        header: "Customer Name",
      },
      {
        accessorKey: "wallet_name",
        header: "Wallet Name",
      },
      {
        accessorKey: "amount",
        header: "Added Amount",
        Cell: ({ row }) => (
          <span>Ks {Formatter(parseFloat(row.original.amount), "price")}</span>
        ),
      },
      {
        accessorKey: "remain_amount",
        header: "Remain Amount",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.remain_amount), "price")}
          </span>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        {storedData?.permission.exchange_mgmt.includes(3) && (
          <Tooltip label="Edit">
            <ActionIcon
              onClick={() => [
                setEditData(row.original),
                document
                  .getElementById("receivable-detail-edit-modal")
                  .showModal(),
              ]}
            >
              <IconEdit className="text-yellow-500" />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.exchange_mgmt.includes(4) && (
          <Tooltip label="Delete">
            <ActionIcon
              onClick={() => [
                setDeleteDataId(row.original),
                document
                  .getElementById("receivable-detail-delete-modal")
                  .showModal(),
              ]}
            >
              <IconTrash className="text-red-400 " />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),
    enableEditing: true,
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableExpanding: false,
    columns,
    data,
  });

  return (
    <div>
      <MantineReactTable table={table} />
      {/* add model */}
      {showCreateModal && (
        <CreateModal
          handleFormSubmit={handleFormSubmit}
          WalletType={WalletType}
        />
      )}
      {/* edit model */}
      <EditModal
        handleEditFormSubmit={handleEditFormSubmit}
        EditData={EditData}
        WalletType={WalletType}
        ClearForm={ClearForm}
      />
      {/* delete modal */}
      <DeleteModal
        handleDeleteFunction={handleDeleteFunction}
        DeleteDataId={DeleteDataId}
      />
    </div>
  );
};

export default ReceivableLog;
