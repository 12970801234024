import { useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import EditModal from "../../components/modals/userManagement/branch-list/edit";
import CreateModal from "../../components/modals/userManagement/branch-list/create";
import DeleteModal from "../../components/modals/userManagement/branch-list/delete";
import MainTable from "../../components/mantine-table";
import { useCookies } from "react-cookie";
import fireToast from "../../components/fireToast";
import Formatter from "../../utils/formater";

export default function BranchList() {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  const [data, setData] = useState([]);
  const [PrintID, setPrintID] = useState(null);
  const [EditBranchData, setEditBranchData] = useState(null);
  const [DeleteDataId, setDeleteDataId] = useState(null);

  const fetchData = async () => {
    try {
      const { data: branches } = await axios.get("/api/v1/branch/list");
      setData(branches);
    } catch (error) {
      console.error("Error fetching branches:", error);
      fireToast(error.response.data.message, "error");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    axios
      .post("/api/v1/branch/add", formData)
      .then((response) => {
        if (response.status === 201) {
          fireToast(response.data.message, "success");
        }
      })
      .catch((error) => {
        fireToast(error.response.data.message, "error");
      })
      .finally(() => {
        document.getElementById("branch-list-create-modal").close();
        fetchData();
      });
  };
  const handleEditSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    axios
      .put(`/api/v1/branch/update/${EditBranchData.id}`, data)
      .then((response) => {
        if (response.status === 200) {
          fireToast(response.data.message, "success");
        }
      })
      .catch((error) => {
        fireToast(error.response.data.message, "error");
      })
      .finally(() => {
        document.getElementById("branch-list-edit-modal").close();
        setEditBranchData({});
        fetchData();
      });
  };

  const handleDeleteSubmit = async (id) => {
    try {
      const { data, status } = await axios.delete(
        `/api/v1/branch/delete/${id}`
      );
      if (status === 200) {
        fireToast(data.message, "success");
      }
    } catch (error) {
      console.error(error);
      fireToast(error.response.data.message, "error");
    } finally {
      document.getElementById("branch-list-delete-modal").close();
      fetchData();
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchData();
  }, []);

  useEffect(() => {
    if (PrintID !== null) {
      const print_data = data[PrintID];
      const print_format = {
        customer: print_data?.voucher_data?.customer?.name,
        wallet: print_data?.voucher_data?.wallet?.name,
        product_data: print_data?.voucher_data?.product_data,
        total_amount: print_data?.total_amount,
        datetime: print_data?.datetime,
        voucher_code: print_data?.voucher_code,
      };

      const encode_data = btoa(JSON.stringify(print_format));

      window.open(`/print/${encode_data}`, "_blank");
      setPrintID(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PrintID]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "location",
        header: "Location",
      },
      {
        accessorKey: "created_at",
        header: "Created",
        Cell: ({ row }) => (
          <div className="flex text-xs flex-nowrap text-nowrap">
            {Formatter(row.original.created_at, "datetime")}
          </div>
        ),
      },
      {
        accessorKey: "updated_at",
        header: "Last Updated",
        Cell: ({ row }) => (
          <div className="flex text-xs flex-nowrap text-nowrap">
            {Formatter(row.original.updated_at, "datetime")}
          </div>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => (
      <Flex gap="md">
        {storedData?.permission.user_mgmt.includes(3) && (
          <Tooltip label="Edit">
            <ActionIcon
              onClick={() => [
                setEditBranchData(row.original),
                document.getElementById("branch-list-edit-modal").showModal(),
              ]}
            >
              <IconEdit className="text-yellow-500" />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.user_mgmt.includes(4) && (
          <Tooltip label="Delete">
            <ActionIcon
              onClick={() => [
                setDeleteDataId(row.original),
                document.getElementById("branch-list-delete-modal").showModal(),
              ]}
            >
              <IconTrash className="text-red-400" />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),
    enableExpanding: false,
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <div>
      <MainTable table={table} />
      {/* add */}
      <CreateModal handleSubmit={handleSubmit} />
      {/* edit */}
      <EditModal
        handleEditSubmit={handleEditSubmit}
        EditBranchData={EditBranchData}
      />

      {/* delete modal */}
      <DeleteModal
        DeleteDataId={DeleteDataId}
        handleDeleteSubmit={handleDeleteSubmit}
      />
    </div>
  );
}
