import ViewUserGroupList from "../pages/userManagement/user-group-list";
import AddStock from "../pages/product/create-purchase";
import BrandList from "../pages/settings/brand-list";
import CategoryList from "../pages/settings/category-list";
import DailySaleList from "../pages/sale/daily-sale-list";
import Dashboard from "../pages/dashboard/dashboard";
import EditProduct from "../pages/product/edit-product";
import EditStock from "../pages/product/edit-purchase";
import ExpenseCategories from "../pages/settings/expense-category-list";
import ExpenseManagement from "../pages/report/expense-management";
import MenuPage from "../pages/menu-page";
import MoneyExchange from "../pages/otherFeatures/money-exchange/money-exchange-list";
import Payable from "../pages/otherFeatures/payable/payable";
import PayableLog from "../pages/otherFeatures/payable/payable-detail";
import Sale from "../pages/sale/cashier/cashier";
import SubCategoryList from "../pages/settings/sub-category-list";
import Supplier from "../pages/product/supplier-list";
import VariantDetails from "../pages/settings/varientDetails";
import VarientOptions from "../pages/settings/varient-option";
import ViewProduct from "../pages/product/product-list";
import ViewProductVariationList from "../pages/product/product-detail-list";
import ViewProfit from "../pages/report/view-profit";
import SaleList from "../pages/sale/voucher-list";
import ViewUserList from "../pages/userManagement/user-list";
import WalletManagement from "../pages/otherFeatures/wallet/wallet";
import UserAddButton from "../components/toolbox/userManagement/user-list/add-button";
import UserGroupAddButton from "../components/toolbox/userManagement/user-group-list/add-button";
import ViewCustomerList from "../pages/userManagement/customer-list";
import AddProductButtonGroup from "../components/toolbox/product/product-list/add-button-group";
import ViewPurchaseList from "../pages/product/purchase-list";
import BranchList from "../pages/userManagement/branch-list";
import AddBranchButton from "../components/toolbox/userManagement/branch-list/add-button";
import SelectBranch from "../pages/settings/select-branch";
import { Cookies } from "react-cookie";
import RegisterProduct from "../pages/product/create-product";
import RegisterProductButtonGroup from "../components/toolbox/product/product-list/create/add-button-group";
import AddPurchaseButton from "../components/toolbox/product/purchase-list/add-button";
import AddSupplierButton from "../components/toolbox/product/supplier-list/add-button";
import AddExpenseButton from "../components/toolbox/report/expense-management/add-button";
import Profile from "../pages/profile/profile";
import AddPayableCustomRecordButton from "../components/toolbox/otherFeatures/payable/add-button";
import AddPayableDetailButton from "../components/toolbox/otherFeatures/payable/detail/add-button";
import Receivable from "../pages/otherFeatures/receivable/receivable";
import AddReceivableCustomRecordButton from "../components/toolbox/otherFeatures/receivable/add-button";
import ReceivableLog from "../pages/otherFeatures/receivable/receivable-detail";
import AddReceivableDetailButton from "../components/toolbox/otherFeatures/receivable/detail/add-button";
import AddWalletButton from "../components/toolbox/otherFeatures/wallet/add-button";
import AddVariantOptionButton from "../components/toolbox/setting/variantOptions/add-button";
import AddCategoryButton from "../components/toolbox/setting/categoryList/add-button";
import AddBrandButton from "../components/toolbox/setting/brandList/add-button";
import AddExpenseCategoryButton from "../components/toolbox/setting/expenseCategoryList/add-button";
import AddVariantDetailButton from "../components/toolbox/setting/variantOptions/variantDetail/add-button";
import AddonProfit from "../components/toolbox/otherFeatures/money-exchange/addon-profit";
import WalletLog from "../pages/report/wallet-log";
import WalletDetailLog from "../pages/otherFeatures/wallet/wallet-detail-log";
import AddonWallet from "../components/toolbox/report/wallet-log/addon-wallet";
import {
  FaBoxOpen,
  FaBoxesStacked,
  FaCartFlatbed,
  FaCashRegister,
  FaClipboardList,
  FaCoins,
  FaGear,
  FaHouse,
  FaReceipt,
  FaShop,
  FaToolbox,
  FaUser,
  FaUserTie,
  FaUsers,
  FaWallet,
} from "react-icons/fa6";

const cookies = new Cookies();
const storedData = cookies.get("userSession");
const icons = require.context("../../image/icons", true);
const iconList = icons.keys().map((image) => icons(image));
const navigationLinks = [
  {
    name: "Dashboard",
    title: "Dashboard",
    href: "/",
    icon: <FaHouse className="text-primary " />,
    component: <Dashboard />,
    active: storedData?.permission?.dashboard_mgmt.includes(1) ? true : false,
  },
  {
    name: "Users",
    title: "User Management",
    href: "/user-management",
    icon: <FaUser className="text-primary " />,
    component: <MenuPage />,
    active:
      storedData?.permission?.user_mgmt.includes(1) ||
      storedData?.permission?.group_mgmt.includes(1) ||
      storedData?.permission?.customer_mgmt.includes(1)
        ? true
        : false,
    children: [
      {
        name: "User",
        title: "User List",
        href: "/user-list",
        icon: <FaUser />,
        component: <ViewUserList />,
        toolbox: { toolboxItem: <UserAddButton />, toolboxModal: null },
        active: storedData?.permission?.user_mgmt.includes(1) ? true : false,
      },
      {
        name: "Group",
        title: "User Groups",
        href: "/user-group-list",
        icon: <FaUsers />,
        component: <ViewUserGroupList />,
        toolbox: { toolboxItem: <UserGroupAddButton />, toolboxModal: null },
        active: storedData?.permission?.group_mgmt.includes(1) ? true : false,
      },
      {
        name: "Branch",
        title: "Branch List",
        href: "/branch-list",
        icon: <FaShop />,
        component: <BranchList />,
        toolbox: { toolboxItem: <AddBranchButton />, toolboxModal: null },
        active: storedData?.permission?.user_mgmt.includes(1) ? true : false,
      },
      {
        name: "Customer List",
        title: "Customer List",
        href: "/customer-list",
        icon: <FaUserTie />,
        component: <ViewCustomerList />,
        active: storedData?.permission?.customer_mgmt.includes(1)
          ? true
          : false,
      },
    ],
  },

  {
    name: "Product",
    title: "Product Management",
    href: "/product-management",
    icon: <FaBoxesStacked className="text-primary " />,
    component: <MenuPage />,
    active:
      storedData?.permission?.product_mgmt.includes(1) ||
      storedData?.permission?.purchase_mgmt.includes(1) ||
      storedData?.permission?.supplier_mgmt.includes(1)
        ? true
        : false,
    children: [
      {
        name: "Products",
        title: "Product List",
        href: "/product-list",
        icon: <FaBoxesStacked />,
        component: <ViewProduct />,
        toolbox: { toolboxItem: <AddProductButtonGroup />, toolboxModal: null },
        active: storedData?.permission?.product_mgmt.includes(1) ? true : false,
        children: [
          {
            name: "Create",
            title: "Create Product",
            href: "/add",
            parm: "",
            icon: "fa-duotone fa-plus",
            toolbox: {
              toolboxItem: <RegisterProductButtonGroup />,
              toolboxModal: null,
            },
            component: <RegisterProduct />,
            active: storedData?.permission?.product_mgmt.includes(2)
              ? true
              : false,
          },
          {
            name: "Detail",
            title: "Unknown",
            href: "/detail",
            parm: "/:pId",
            icon: "fa-duotone fa-boxes-stacked",
            component: <ViewProductVariationList />,
            active: storedData?.permission?.product_mgmt.includes(1)
              ? true
              : false,
          },
          {
            name: "Edit",
            title: "Edit",
            href: "/edit",
            parm: "/:pId",
            icon: "fa-duotone fa-boxes-stacked",
            component: <EditProduct />,
            active: storedData?.permission?.product_mgmt.includes(3)
              ? true
              : false,
          },
        ],
      },
      {
        name: "Purchase",
        title: "Purchase List",
        href: "/purchase-list",
        icon: <FaBoxOpen />,
        toolbox: { toolboxItem: <AddPurchaseButton />, toolboxModal: null },
        component: <ViewPurchaseList />,
        active: storedData?.permission?.purchase_mgmt.includes(1)
          ? true
          : false,
        children: [
          {
            name: "Add",
            title: "Add New Purchase",
            href: "/add",
            parm: "",
            icon: "fa-duotone fa-boxes-stacked",
            component: <AddStock />,
            active: storedData?.permission?.purchase_mgmt.includes(2)
              ? true
              : false,
          },
          {
            name: "Edit",
            title: "Edit",
            href: "/edit",
            parm: "/:purchaseId",
            icon: "fa-duotone fa-boxes-stacked",
            component: <EditStock />,
            active: storedData?.permission?.purchase_mgmt.includes(3)
              ? true
              : false,
          },
        ],
      },
      {
        name: "Suppliers",
        title: "Supplier List",
        href: "/supplier-list",
        icon: <FaCartFlatbed />,
        component: <Supplier />,
        toolbox: { toolboxItem: <AddSupplierButton />, toolboxModal: null },
        active: storedData?.permission?.supplier_mgmt.includes(1)
          ? true
          : false,
      },
    ],
  },
  {
    name: "Sale",
    title: "Sale Management",
    href: "/sale-management",
    icon: <FaCashRegister className="text-primary " />,
    component: <MenuPage />,
    active: storedData?.permission?.sale_mgmt.includes(1) ? true : false,
    children: [
      {
        name: "Casher",
        title: "Casher",
        href: "/casher",
        icon: <FaCashRegister />,
        component: <Sale />,
        active: storedData?.permission?.sale_mgmt.includes(2) ? true : false,
      },
      {
        name: "Vouchers",
        title: "Voucher List",
        href: "/voucher-list",
        icon: <FaReceipt />,
        component: <SaleList />,
        active: storedData?.permission?.sale_mgmt.includes(1) ? true : false,
      },
      {
        name: "Daily Sales",
        title: "Daily Sale List",
        href: "/daily-sale-list",
        icon: <FaClipboardList />,
        component: <DailySaleList />,
        active: storedData?.permission?.sale_mgmt.includes(1) ? true : false,
      },
    ],
  },
  {
    name: "Report",
    title: "Reports",
    href: "/report-management",
    icon: <FaClipboardList className="text-primary " />,
    component: <MenuPage />,
    active:
      storedData?.permission?.expense_mgmt.includes(1) ||
      storedData?.permission?.profit_mgmt.includes(1)
        ? true
        : false,
    children: [
      {
        name: "Expenses",
        title: "Total Expense List",
        href: "/expense-list",
        icon: <FaCoins />,
        component: <ExpenseManagement />,
        toolbox: { toolboxItem: <AddExpenseButton />, toolboxModal: null },
        active: storedData?.permission?.expense_mgmt.includes(1) ? true : false,
      },
      {
        name: "Profits",
        title: "Profits",
        href: "/profit",
        icon: <FaCoins />,
        component: <ViewProfit />,
        active: storedData?.permission?.profit_mgmt.includes(1) ? true : false,
      },
      {
        name: "Wallet Logs",
        title: "Wallet Logs",
        href: "/wallet-log",
        icon: <FaWallet />,
        toolbox: { toolboxItem: <AddonWallet />, toolboxModal: null },
        component: <WalletLog />,
        active: storedData?.permission?.walletlog_mgmt.includes(1)
          ? true
          : false,
      },
    ],
  },
  {
    name: "Other Features",
    title: "Other Features",
    href: "/other-features",
    icon: <FaToolbox className="text-primary " />,
    component: <MenuPage />,
    active: true,
    children: [
      {
        name: "Money Exchange",
        title: "Money Exchange",
        href: "/money-exchange",
        icon: "fa-money-bill-transfer",
        toolbox: { toolboxItem: <AddonProfit />, toolboxModal: null },
        component: <MoneyExchange />,
        active: storedData?.permission?.exchange_mgmt.includes(1)
          ? true
          : false,
      },
      {
        name: "Payable",
        title: "Payable",
        href: "/payable",
        icon: "fa-money-bill-wheat",
        component: <Payable />,
        toolbox: {
          toolboxItem: <AddPayableCustomRecordButton />,
          toolboxModal: null,
        },
        active: storedData?.permission?.payable_mgmt.includes(1),
        children: [
          {
            name: "Record Detail",
            title: "Payable Record Detail",
            href: "/record",
            parm: "/:noteId",
            icon: "fa-duotone fa-boxes-stacked",
            toolbox: {
              toolboxItem: <AddPayableDetailButton />,
              toolboxModal: null,
            },
            component: <PayableLog />,
            active: storedData?.permission?.payable_mgmt.includes(1)
              ? true
              : false,
          },
        ],
      },
      {
        name: "Receivable",
        title: "Receivable",
        href: "/receivable",
        icon: "fa-money-bill-trend-up",
        component: <Receivable />,
        toolbox: {
          toolboxItem: <AddReceivableCustomRecordButton />,
          toolboxModal: null,
        },
        active: storedData?.permission?.receivable_mgmt.includes(1),
        children: [
          {
            name: "Record Detail",
            title: "Receivable Record Detail",
            href: "/record",
            parm: "/:noteId",
            icon: "fa-duotone fa-boxes-stacked",
            toolbox: {
              toolboxItem: <AddReceivableDetailButton />,
              toolboxModal: null,
            },
            component: <ReceivableLog />,
            active: storedData?.permission?.receivable_mgmt.includes(1)
              ? true
              : false,
          },
        ],
      },
      {
        name: "Wallets",
        title: "Wallet Management",
        href: "/wallet",
        icon: "fa-wallet",
        toolbox: { toolboxItem: <AddWalletButton />, toolboxModal: null },
        component: <WalletManagement />,
        active: true,
        children: [
          {
            name: "Wallet Detail",
            title: "Wallet Detail",
            href: "/log",
            parm: "/:walletId",
            icon: "fa-duotone fa-boxes-stacked",
            component: <WalletDetailLog />,
            toolbox: { toolboxItem: <AddonWallet />, toolboxModal: null },

            active: storedData?.permission?.wallet_mgmt.includes(1)
              ? true
              : false,
          },
        ],
      },
    ],
  },
  {
    name: "Setting",
    title: "Settings",
    href: "/settings",
    icon: <FaGear className="text-primary " />,
    component: <MenuPage />,
    active: storedData?.permission?.setting_mgmt.includes(1) ? true : false,
    children: [
      {
        name: "Select Branch",
        title: "Select Branch",
        href: "/select-branch",
        icon: "fa-building",
        component: <SelectBranch />,
        active: true,
      },
      {
        name: "Brand List",
        title: "Brand List",
        href: "/brand-list",
        icon: "fa-tag",
        toolbox: { toolboxItem: <AddBrandButton />, toolboxModal: null },
        component: <BrandList />,
        active: storedData?.permission?.setting_mgmt.includes(1) ? true : false,
      },
      {
        name: "Category List",
        title: "Category List",
        href: "/category-list",
        icon: "fa-sitemap",
        toolbox: {
          toolboxItem: <AddCategoryButton />,
          toolboxModal: null,
        },
        component: <CategoryList />,
        active: storedData?.permission?.setting_mgmt.includes(1) ? true : false,
        children: [
          {
            name: "Sub Category List",
            title: "Sub Category List",
            href: "/sub-category-list",
            parm: "/:catId",
            icon: "fa-duotone fa-list",
            component: <SubCategoryList />,
            active: storedData?.permission?.setting_mgmt.includes(1)
              ? true
              : false,
          },
        ],
      },
      {
        name: "Variant Option",
        title: "Variant Options",
        href: "/variant-option",
        icon: "fa-tags",
        toolbox: {
          toolboxItem: <AddVariantOptionButton />,
          toolboxModal: null,
        },
        component: <VarientOptions />,
        active: storedData?.permission?.setting_mgmt.includes(1) ? true : false,
        children: [
          {
            name: "Detail List",
            title: "Variant Detail List",
            href: "/list",
            parm: "/:variantId",
            icon: "fa-duotone fa-list",
            toolbox: {
              toolboxItem: <AddVariantDetailButton />,
              toolboxModal: null,
            },
            component: <VariantDetails />,
            active: storedData?.permission?.setting_mgmt.includes(1)
              ? true
              : false,
          },
        ],
      },
      {
        name: "Expense Category List",
        title: "Expense Category List",
        href: "/expense-category",
        icon: "fa-tags",
        toolbox: {
          toolboxItem: <AddExpenseCategoryButton />,
          toolboxModal: null,
        },
        component: <ExpenseCategories />,
        active: true,
      },
    ],
  },
  {
    name: "Profile Setting",
    title: "User Profile",
    href: "/profile",
    icon: "fa-duotone fa-person",
    component: <Profile />,
    active: true,
    show_in_menu: false,
  },
];

export { navigationLinks, iconList };
