import { Label, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import Select from "react-select";
import clearForm from "../../../utils/clearForm";
import moment from "moment";
import fireToast from "../../../components/fireToast";
import axios from "axios";

const Create = ({ WalletType, fetchData, ClearForm }) => {
  const [MoneyInWallet, setMoneyInWallet] = useState(null);
  const [MoneyOutWallet, setMoneyOutWallet] = useState(null);
  const [ProfitWallet, setProfitWallet] = useState(null);

  function clearAll() {
    setMoneyInWallet(null);
    setMoneyOutWallet(null);
    setProfitWallet(null);
    clearForm();
  }

  useEffect(() => {
    if (ClearForm) {
      clearAll();
    }
  }, [ClearForm]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    axios
      .post("/api/v1/money-exchange/create", formData)
      .then(({ status, data }) => {
        if (status === 201 || status === 200) {
          fireToast(data.message, "success");
          clearAll();
        }
      })
      .catch((error) => fireToast(error.response.data.message, "error"))
      .finally(() => {
        fetchData();
      });
  };

  return (
    <form
      onSubmit={handleFormSubmit}
      className="z-50 w-full p-3 border rounded-lg shadow-sm phoenix-submit-form"
    >
      <div className="grid grid-cols-1 gap-3 mb-3 mg:grid-cols-2 lg:grid-cols-4">
        <div>
          <div className="block mb-2 ">
            <Label
              htmlFor="record_datetime"
              className="font-semibold text-primary"
              value="Date"
            />
          </div>
          <div className="grid w-full grid-cols-1 gap-2">
            <TextInput
              type="date"
              id="record_datetime"
              name="record_datetime"
              defaultValue={moment().format("YYYY-MM-DD")}
              max={moment().format("YYYY-MM-DD")}
              required
            />
          </div>
        </div>
        <div>
          <div className="block mb-2 ">
            <Label
              htmlFor="in_amount"
              value="Money IN"
              className="font-semibold text-primary"
            />
          </div>
          <div className="grid w-full grid-cols-1 gap-2">
            <TextInput
              id="in_amount"
              name="in_amount"
              placeholder="Enter amount"
              required
            />
            <Select
              id="in_wallet_id"
              name="in_wallet_id"
              className="z-50 croni-select-container"
              classNamePrefix="croni-select"
              placeholder="Choose Wallet"
              options={WalletType}
              required={true}
              value={MoneyInWallet}
              onChange={(e) => setMoneyInWallet(e)}
            />
          </div>
        </div>
        <div>
          <div className="block mb-2 ">
            <Label
              htmlFor="out_amount"
              value="Money OUT"
              className="font-semibold text-primary"
            />
          </div>
          <div className="grid w-full grid-cols-1 gap-2">
            <TextInput
              id="out_amount"
              name="out_amount"
              placeholder="Enter amount"
              required
            />
            <Select
              id="out_wallet_id"
              name="out_wallet_id"
              className="z-50 croni-select-container"
              classNamePrefix="croni-select"
              placeholder="Choose Wallet"
              options={WalletType}
              required={true}
              value={MoneyOutWallet}
              onChange={(e) => setMoneyOutWallet(e)}
            />
          </div>
        </div>
        <div>
          <div className="block mb-2 ">
            <Label
              htmlFor="profit_amount"
              value="Profit"
              className="font-semibold text-primary"
            />
          </div>
          <div className="grid w-full grid-cols-1 gap-2">
            <TextInput
              id="profit_amount"
              name="profit_amount"
              placeholder="Enter amount"
              required
            />
            <Select
              id="profit_wallet_id"
              name="profit_wallet_id"
              className="z-50 croni-select-container"
              classNamePrefix="croni-select"
              placeholder="Choose Wallet"
              options={WalletType}
              required={true}
              value={ProfitWallet}
              onChange={(e) => setProfitWallet(e)}
            />
          </div>
        </div>
      </div>
      <div className="sticky grid grid-cols-1 md:grid-cols-2 lg:grid-cols-8">
        <button
          type="submit"
          className="w-full md:col-start-2 lg:col-start-8 btn btn-primary"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default Create;
