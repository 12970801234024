import { useEffect, useMemo, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import axios from "axios";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import fireToast from "../../../components/fireToast";
import Formatter from "../../../utils/formater";
import CreateModal from "../../../components/modals/otherFeatures/receivable/create";
import DeleteModal from "../../../components/modals/otherFeatures/receivable/delete";
import clearForm from "../../../utils/clearForm";
import EditModal from "../../../components/modals/otherFeatures/receivable/edit";

const Receivable = () => {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];
  const router = useNavigate();

  const [data, setData] = useState([]);
  // State to store the selected image file
  const [EditData, setEditData] = useState({});
  const [DeleteDataId, setDeleteDataId] = useState(null);
  const [WalletType, setWalletType] = useState([]);

  function GetWalletType() {
    axios.get(`/api/v1/sale/props`).then((response) => {
      if (response.status === 200) {
        setWalletType(response.data.wallet);
      }
    });
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("type", 1);
    axios
      .post("/api/v1/receivable/add-record", formData)
      .then((response) => fireToast(response.data.message, "success"))
      .catch((error) => fireToast(error.response.data.error, "error"))
      .finally(() => {
        fetchData();
        document.getElementById("receivable-create-modal").close();
        clearForm();
      });
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios
      .put(`/api/v1/receivable/${EditData.id}`, formData)
      .then(({ data }) => fireToast(data.message, "success"))
      .catch((error) => fireToast(error.response.data.message, "error"))
      .finally(() => {
        setEditData({});
        fetchData();
        document.getElementById("receivable-edit-modal").close();
        clearForm();
      });
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/receivable");
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Record ID",
      },
      {
        accessorKey: "datetime",
        header: "Record Datetime",
        Cell: ({ row }) => (
          <div className="flex text-xs flex-nowrap text-nowrap">
            {Formatter(row.original.datetime, "date")}
          </div>
        ),
      },
      {
        accessorKey: "customer_name",
        header: "Customer Name",
      },
      {
        accessorKey: "total_amount",
        header: "Total Amount",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.total_amount), "price")}
          </span>
        ),
      },
      {
        accessorKey: "remain_amount",
        header: "Remain Amount",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.remain_amount), "price")}
          </span>
        ),
      },
      {
        accessorKey: "description",
        header: "Notes",
      },
    ],
    []
  );

  useEffect(() => {
    GetWalletType();
    fetchData();
  }, []);

  //DELETE action

  const handleDeleteFunction = async (dataId) => {
    try {
      const response = await axios.delete(`/api/v1/receivable/${dataId}`);
      if (response.status === 200) {
        setDeleteDataId(null);
        fireToast(response.data.message, "success");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    } finally {
      fetchData();
      document.getElementById("receivable-delete-modal").close();
    }
  };

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        {storedData?.permission.exchange_mgmt.includes(3) && (
          <Tooltip label="Edit">
            <ActionIcon
              onClick={() => [
                setEditData(row.original),
                document.getElementById("receivable-edit-modal").showModal(),
              ]}
            >
              <IconEdit className="text-yellow-500" />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.exchange_mgmt.includes(4) && (
          <Tooltip label="Delete">
            <ActionIcon
              onClick={() => [
                setDeleteDataId(row.original),
                document.getElementById("receivable-delete-modal").showModal(),
              ]}
            >
              <IconTrash className="text-red-400 " />
            </ActionIcon>
          </Tooltip>
        )}
        <ActionIcon onClick={() => router(`record/${row.original.id}`)}>
          <span className="flex items-center gap-2 text-cyan-800 ">
            <i className="fa-regular fa-sitemap"></i>
          </span>
        </ActionIcon>
      </Flex>
    ),
    enableEditing: true,
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableExpanding: false,
    columns,
    data,
  });

  return (
    <div>
      <MantineReactTable table={table} />
      {/* add model */}
      <CreateModal handleFormSubmit={handleFormSubmit} />
      {/* edit model */}
      <EditModal
        handleEditFormSubmit={handleEditFormSubmit}
        EditData={EditData}
      />
      {/* delete modal */}
      <DeleteModal
        DeleteDataId={DeleteDataId}
        handleDeleteFunction={handleDeleteFunction}
      />
    </div>
  );
};

export default Receivable;
