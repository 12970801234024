import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Formatter from "../../utils/formater";
import fireToast from "../../components/fireToast";
import MoneyExchangeProfitView from "./profit/view-exchange-profit";

export default function ViewProfit() {
  const [data, setData] = useState([]);
  const [PrintID, setPrintID] = useState(null);
  const [TotalProfit, setTotalProfit] = useState(0);
  const [TotalSaleAmount, setTotalSaleAmount] = useState(0);
  const [TotalExchangeProfit, setTotalExchangeProfit] = useState(0);
  const [Switch, setSwitch] = useState(0);
  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/v1/profit/list`);

      if (response.status === 200) {
        setData(response.data?.data);
        setTotalProfit(response.data?.total_profit);
        setTotalSaleAmount(response.data?.total_sale_amount);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    try {
      const response = await axios.get("/api/v1/money-exchange/total-profit");
      if (response.status === 200) {
        setTotalExchangeProfit(response.data?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (PrintID !== null) {
      window.print();
      const myFunction = () => {
        setPrintID(null);
      };

      const timeoutId = setTimeout(myFunction, 4000);

      return () => {
        clearTimeout(timeoutId);
      };

      //   location.reload();
    }
  }, [PrintID]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "sku", //access nested data with dot notation
        header: "Product Code",
      },
      {
        accessorKey: "product_name", //access nested data with dot notation
        header: "Product Name",
      },
      {
        accessorKey: "total_stock", //access nested data with dot notation
        header: "Total Stock",
      },
      {
        accessorKey: "remaining_stock", //access nested data with dot notation
        header: "Net Stock",
      },
      {
        accessorKey: "sold_stock",
        header: "Sale Stock",
      },
      {
        accessorKey: "sale_40",
        header: "Sale 40%",
        Cell: ({ row }) => (
          <span>Ks {Formatter(parseFloat(row.original.sale_40), "price")}</span>
        ),
      },
      {
        accessorKey: "net_whole_sale",
        header: "Net Whole Sale",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.net_whole_sale), "price")}
          </span>
        ),
      },
      {
        accessorKey: "whole_sale",
        header: "Whole Sale",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.whole_sale), "price")}
          </span>
        ),
      },
      {
        accessorKey: "ygn_sale",
        header: "Yangon Sale",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.ygn_sale), "price")}
          </span>
        ),
      },
      {
        accessorKey: "total_sale_amount",
        header: "Total Sale Amount",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.total_sale_amount), "price")}
          </span>
        ),
      },
      {
        accessorKey: "total_profit",
        header: "Total Profit",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.total_profit), "price")}
          </span>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    enableRowActions: false,
    enableExpanding: false,
    columns,
    initialState: {
      columnVisibility: {
        sale_40: false,
        ygn_sale: false,
        net_whole_sale: false,
        net_stock: false,
      },
    },
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <>
      <div className="grid w-full grid-cols-1 gap-3 mb-3 md:grid-cols-3">
        <div className="flex items-center gap-4 p-4 border rounded-lg bg-slate-50">
          <span className="bg-orange-100 rounded-full w-[3rem] h-[3rem] flex justify-center items-center">
            <i className="text-xl fa-solid fa-circle-dollar text-primary-content"></i>
          </span>
          <div className="flex flex-col">
            <span className="text-sm text-slate-400">Total Profit Amount</span>
            <span className="text-lg font-semibold">
              Ks{" "}
              {(
                parseFloat(TotalProfit) + parseFloat(TotalExchangeProfit)
              ).toLocaleString()}
            </span>
          </div>
        </div>
        <div
          className={`flex items-center gap-4 p-4 border rounded-lg cursor-pointer ${
            Switch == 0
              ? "bg-orange-100  border-primary shadow-inner"
              : "bg-slate-50"
          }`}
          onClick={() => setSwitch(0)}
        >
          <span className="bg-blue-200 rounded-full w-[3rem] h-[3rem] flex justify-center items-center">
            <i className="text-xl fa-solid fa-cash-register text-primary-content"></i>
          </span>
          <div className="flex flex-col">
            <span className="text-sm text-slate-400">Total Sale Profit</span>
            <span className="text-lg font-semibold">
              Ks {Number(TotalProfit).toLocaleString()}
            </span>
          </div>
        </div>
        <div
          className={`flex items-center gap-4 p-4 border rounded-lg cursor-pointer ${
            Switch == 1
              ? "bg-orange-100  border-primary shadow-inner"
              : "bg-slate-50"
          }`}
          onClick={() => setSwitch(1)}
        >
          <span className="bg-purple-200 rounded-full w-[3rem] h-[3rem] flex justify-center items-center">
            <i className="text-xl fa-solid fa-money-bill-transfer text-primary-content"></i>
          </span>
          <div className="flex flex-col">
            <span className="text-sm text-slate-400">
              Total Exchange Profit
            </span>
            <span className="text-lg font-semibold">
              Ks {Number(TotalExchangeProfit).toLocaleString()}
            </span>
          </div>
        </div>
      </div>
      <hr className="mb-2" />
      {Switch == 0 ? (
        <>
          <h2 className="mb-2 text-xl font-semibold">Sale Profit</h2>
          <MantineReactTable table={table} />
        </>
      ) : (
        <>
          <h2 className="mb-2 text-xl font-semibold">Money Exchange Profit</h2>
          <MoneyExchangeProfitView />
        </>
      )}
    </>
  );
}
