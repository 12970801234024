import { useParams } from "react-router-dom";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex } from "@mantine/core";
import { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import $ from "jquery";
import { useCookies } from "react-cookie";
import Formatter from "../../utils/formater";
import DeleteModal from "../../components/modals/product/product-list/detail/delete";
import fireToast from "../../components/fireToast";
import EditModal from "../../components/modals/product/product-list/detail/edit";

function ViewProductVariationList() {
  const { pId } = useParams();
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  const [ProductInfo, setProductInfo] = useState({});
  const [data, setData] = useState([]);
  // State to store the selected image file
  const [DeleteDataId, setDeleteDataId] = useState(null);
  const editFormRef = useRef();
  const [EditData, setEditData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/api/v1/product-management/variation-specification?pId=${pId}`
      );
      if (response.status === 200) {
        $("#page-title").html(response.data.productInfo?.name);
        setProductInfo(response.data.productInfo);
        setData(response.data.productVariations);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "sku", //access nested data with dot notation
        header: "Product Code",
        mantineEditTextInputProps: {
          required: true,
        },
      },
      {
        enableEditing: false,
        header: "Variation",
        Cell: ({ row }) => (
          <span className="flex gap-1 ml-2">
            {row.original.v_options?.split(",").map((v, index2) => (
              <div
                className="px-2 font-semibold text-white bg-blue-500 rounded-sm"
                key={index2}
              >
                {v}
              </div>
            ))}
          </span>
        ),
      },
      {
        accessorKey: "sale_price", //normal accessorKey
        header: "Real Sale Price",
        mantineEditTextInputProps: {
          required: true,
          type: "number",
        },
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.sale_price), "price")}
          </span>
        ),
      },
      {
        accessorKey: "ygn_sale",
        header: "YGN Sale",
        mantineEditTextInputProps: {
          required: true,
          type: "number",
        },
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.ygn_sale), "price")}
          </span>
        ),
      },
      {
        accessorKey: "whole_sale",
        header: "Whole Sale",
        mantineEditTextInputProps: {
          required: true,
          type: "number",
        },
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.whole_sale), "price")}
          </span>
        ),
      },
      {
        enableEditing: false,
        accessorKey: "stock",
        header: "Stock Qty",
      },
    ],
    []
  );

  const handleDelete = async (pdID) => {
    const form = new FormData();
    form.append("pdID", pdID);
    try {
      const response = await axios.post(
        "/api/v1/product-variation/delete",
        form
      );
      if (response.status === 200 || response.status === 204) {
        setDeleteDataId(null);
        fireToast(response.data.message, "success");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      document.getElementById("product-detail-list-delete-modal").close();
      fetchData();
    }
    fetchData();
  };

  const handleSaveRow = async (event) => {
    event.preventDefault();

    const formData = new FormData(editFormRef.current);
    formData.append("pID", ProductInfo.id);

    axios
      .post("/api/v1/product-variation/edit", formData)
      .then((response) => {
        if (response.status === 200) {
          fireToast(response.data.message, "success");
        }
      })
      .catch((error) => {
        console.error("Error saving product variation:", error);
        fireToast(error.response.data.message, "error");
      })
      .finally(() => {
        document.getElementById("product-detail-list-edit-modal").close();
        setEditData({}); // Close the modal after successful submission
        fetchData();
      });
  };

  const icons = {
    IconEdit: (props) => (
      <i className="text-yellow-400 fa-regular fa-pen-to-square" {...props}></i>
    ),
  };

  const table = useMantineReactTable({
    enableEditing: true,
    // editDisplayMode: "row",
    onEditingRowSave: handleSaveRow,
    enableRowActions: false,
    icons: icons,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        {storedData?.permission.product_mgmt.includes(3) && (
          <ActionIcon
            onClick={() => [
              document
                .getElementById("product-detail-list-edit-modal")
                .showModal(),
              setEditData(row?.original),
            ]}
          >
            <IconEdit className="text-yellow-500" />
          </ActionIcon>
        )}
        {storedData?.permission.product_mgmt.includes(4) && (
          <ActionIcon
            onClick={() => [
              document
                .getElementById("product-detail-list-delete-modal")
                .showModal(),
              setDeleteDataId(row?.original),
            ]}
          >
            <IconTrash className="text-red-400 " />
          </ActionIcon>
        )}
      </Flex>
    ),
    // renderRowActionMenuItems: ({ row }) => (
    //   <div className="min-w-[10rem]">
    //     <Menu.Item
    //       onClick={() => {
    //         props.setOpenDeleteModal("dismissible");
    //         console.log(row.original);
    //         setDeleteDataId(row.original);
    //       }}
    //     >
    //       <span className="flex items-center gap-2 ">
    //         <i className="text-red-600 fa-regular fa-trash-xmark"></i>
    //         Delete
    //       </span>
    //     </Menu.Item>
    //   </div>
    // ),
    enableExpanding: false,
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <div>
      <MantineReactTable table={table} />
      {/* Edit Modal */}
      <EditModal
        EditData={EditData}
        editFormRef={editFormRef}
        handleSaveRow={handleSaveRow}
      />
      {/* delete modal */}
      <DeleteModal
        DeleteDataId={DeleteDataId}
        handleDeleteFunction={handleDelete}
      />
    </div>
  );
}

export default ViewProductVariationList;
