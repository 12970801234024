import { useCookies } from "react-cookie";
import ToolboxSection from "../../../../toolbox-button-group";

const AddReceivableDetailButton = () => {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  return (
    storedData?.permission.exchange_mgmt.includes(1) && (
      <ToolboxSection className="grid items-center grid-cols-1 gap-3 md:flex">
        <button
          id="make-receivable-payment"
          onClick={() =>
            document
              .getElementById("receivable-detail-create-modal")
              .showModal()
          }
          className="btn btn-primary max-sm:w-full"
        >
          <span className="flex items-center gap-2">
            <i className="fa-solid fa-plus"></i>
            <p>Make Payment</p>
          </span>
        </button>
      </ToolboxSection>
    )
  );
};

export default AddReceivableDetailButton;
