import { useEffect, useMemo, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import axios from "axios";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useCookies } from "react-cookie";
import Create from "./create-money-exchage";
import EditModal from "../../../components/modals/otherFeatures/money-exchange/edit";
import fireToast from "../../../components/fireToast";
import DeleteModal from "../../../components/modals/otherFeatures/money-exchange/delete";
import Formatter from "../../../utils/formater";
import clearForm from "../../../utils/clearForm";

const MoneyExchange = () => {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];
  const [data, setData] = useState([]);
  // State to store the selected image file
  const [EditData, setEditData] = useState({});
  const [DeleteDataId, setDeleteDataId] = useState(null);
  const [WalletType, setWalletType] = useState([]);
  const [ClearForm, setClearForm] = useState(false);

  function GetWalletType() {
    axios.get(`/api/v1/sale/props`).then((response) => {
      if (response.status === 200) {
        setWalletType(response.data.wallet);
      }
    });
  }

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    axios
      .put(`/api/v1/money-exchange/update/${EditData.id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          fireToast(response.data.message, "success");
          setClearForm(!ClearForm);
          clearForm();
        }
      })
      .catch((error) => {
        fireToast(error.response.data.message, "error");
      })
      .finally(() => {
        setEditData({});
        document.getElementById("money-exchange-edit-modal").close();
        fetchData();
      });
  };
  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/money-exchange/list");
      if (response.status === 200) {
        setData(response.data?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
  };

  const handleDeleteFunction = async (id) => {
    try {
      const { data } = await axios.delete(
        `/api/v1/money-exchange/delete/${id}`
      );
      if (data.status === "success") {
        setDeleteDataId(null);
        fireToast(data.message, "success");
      }
    } catch (error) {
      console.error(error);
      fireToast(error.response.data.message, "error");
    } finally {
      fetchData();
      document.getElementById("money-exchange-delete-modal").close();
    }
  };

  useEffect(() => {
    GetWalletType();
    fetchData();
  }, []);
  const columns = useMemo(
    () => [
      {
        accessorKey: "record_datetime",
        header: "Recorded Datetime",
        Cell: ({ row }) => (
          <div className="flex text-sm flex-nowrap text-nowrap">
            {Formatter(row.original.record_datetime, "date")}
          </div>
        ),
      },
      {
        accessorKey: "id",
        header: "Exchange ID",
      },
      {
        accessorKey: "in_amount",
        header: "In Amount",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.in_amount), "price")}
          </span>
        ),
      },
      {
        accessorKey: "in_wallet_name",
        header: "In Wallet Name",
      },
      {
        accessorKey: "out_amount",
        header: "Out Amount",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.out_amount), "price")}
          </span>
        ),
      },
      {
        accessorKey: "out_wallet_name",
        header: "Out Wallet Name",
      },
      {
        accessorKey: "profit_amount",
        header: "Profit Amount",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.profit_amount), "price")}
          </span>
        ),
      },
      {
        accessorKey: "profit_wallet_name",
        header: "Profit Wallet Name",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        {storedData?.permission.exchange_mgmt.includes(3) && (
          <Tooltip label="Edit">
            <ActionIcon
              onClick={() => [
                setEditData(row.original),
                document
                  .getElementById("money-exchange-edit-modal")
                  .showModal(),
              ]}
            >
              <IconEdit className="text-yellow-500" />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.exchange_mgmt.includes(4) && (
          <Tooltip label="Delete">
            <ActionIcon
              onClick={() => [
                setDeleteDataId(row.original),
                document
                  .getElementById("money-exchange-delete-modal")
                  .showModal(),
              ]}
            >
              <IconTrash className="text-red-400 " />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),
    enableEditing: true,
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableExpanding: false,
    columns,
    data,
  });

  return (
    <div className="space-y-3">
      <Create
        WalletType={WalletType}
        fetchData={fetchData}
        ClearForm={ClearForm}
      />
      <MantineReactTable table={table} />
      {/* edit model */}
      <EditModal
        handleEditFormSubmit={handleEditFormSubmit}
        EditData={EditData}
        WalletType={WalletType}
        ClearForm={ClearForm}
      />
      {/* delete modal */}
      <DeleteModal
        handleDeleteFunction={handleDeleteFunction}
        DeleteDataId={DeleteDataId}
      />
    </div>
  );
};

export default MoneyExchange;
