import { useEffect, useMemo, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import axios from "axios";
import fireToast from "../../../components/fireToast";
import Formatter from "../../../utils/formater";

const MoneyExchangeProfitView = () => {
  const [data, setData] = useState([]);
  // State to store the selected image file

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/money-exchange/list");
      if (response.status === 200) {
        setData(response.data?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const columns = useMemo(
    () => [
      {
        accessorKey: "record_datetime",
        header: "Recorded Datetime",
        Cell: ({ row }) => (
          <div className="flex text-sm flex-nowrap text-nowrap">
            {Formatter(row.original.record_datetime, "date")}
          </div>
        ),
      },
      {
        accessorKey: "id",
        header: "Exchange ID",
      },
      {
        accessorKey: "in_amount",
        header: "In Amount",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.in_amount), "price")}
          </span>
        ),
      },
      {
        accessorKey: "in_wallet_name",
        header: "In Wallet Name",
      },
      {
        accessorKey: "out_amount",
        header: "Out Amount",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.out_amount), "price")}
          </span>
        ),
      },
      {
        accessorKey: "out_wallet_name",
        header: "Out Wallet Name",
      },
      {
        accessorKey: "profit_amount",
        header: "Profit Amount",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.profit_amount), "price")}
          </span>
        ),
      },
      {
        accessorKey: "profit_wallet_name",
        header: "Profit Wallet Name",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: false,
    enableEditing: false,
    enableExpanding: false,
    columns,
    data,
  });

  return (
    <div className="space-y-3">
      <MantineReactTable table={table} />
    </div>
  );
};

export default MoneyExchangeProfitView;
