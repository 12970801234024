import axios from "axios";
import { Button, Card, Label, TextInput, Textarea } from "flowbite-react";
import { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import fireToast from "../../components/fireToast";

function AddStock() {
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  var cToken = null;

  const [BrandList, setBrandList] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [ProductNameList, setProductNameList] = useState([]);
  const [SupplierList, setSupplierList] = useState([]);
  const [ProductVariantOptionList, setProductVariantOptionList] = useState([]);

  const [SubCategorySelect, setSubCategorySelect] = useState(null);
  const [BrandSelect, setBrandSelect] = useState(null);
  const [CategorySelect, setCategorySelect] = useState(null);
  const [ProductSelect, setProductSelect] = useState(null);
  const [WalletType, setWalletType] = useState([]);
  const [Status, setStatus] = useState([
    { value: "paid", label: "Paid" },
    { value: "unpaid", label: "Unpaid" },
  ]);
  const [SelectedWalletType, setSelectedWalletType] = useState(null);
  const router = useNavigate();

  const handleInputChange1 = (e) => {
    const inputValue = e.target.value;

    // Use a regular expression to allow only numeric characters
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    setValue1(numericValue);
  };

  const handleInputChange2 = (e) => {
    const inputValue = e.target.value;

    // Use a regular expression to allow only numeric characters
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    setValue2(numericValue);
  };

  function GetWalletType() {
    axios.get(`/api/v1/sale/props`).then((response) => {
      if (response.status === 200) {
        setWalletType(response.data.wallet);
      }
    });
  }

  function getProductBrandList() {
    // if (cToken) {
    //   cToken.cancel("New Request Made");
    // }
    // cToken = axios.CancelToken.source();
    axios
      .get(
        `/api/purchase-brand`
        // data,
        // {
        //   cancelToken: cToken.token,
        // }
      )
      .then((response) => {
        if (response.status === 200) {
          const newData = response.data.data.map((e) => ({
            value: e,
            label: e,
          }));
          setBrandList(newData);
        }
        // cToken = null;
      });
  }

  function getSupplierList() {
    // if (cToken) {
    //   cToken.cancel("New Request Made");
    // }
    // cToken = axios.CancelToken.source();
    axios
      .get(
        `/api/product-supplier`
        // data,
        // {
        //   cancelToken: cToken.token,
        // }
      )
      .then((response) => {
        if (response.status === 200) {
          const newData = response.data.data.map((e) => ({
            value: e,
            label: e,
          }));
          setSupplierList(newData);
        }
        // cToken = null;
      });
  }

  function getProductCategoryList(brandName) {
    if (cToken) {
      cToken.cancel("New Request Made");
    }
    cToken = axios.CancelToken.source();
    axios
      .get(
        `/api/purchase-category`,
        // data,
        {
          params: {
            brandName: brandName,
          },
          cancelToken: cToken.token,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const newData = response.data.data.map((e) => ({
            value: e,
            label: e,
          }));
          setCategoryList(newData);
        }
        cToken = null;
      });
  }

  function getProductSubCategoryList(value) {
    if (cToken) {
      cToken.cancel("New Request Made");
    }
    cToken = axios.CancelToken.source();

    axios
      .get(`/api/purchase-sub-category`, {
        params: {
          brandName: BrandSelect.value,
          catName: value,
        },
        cancelToken: cToken.token,
      })
      .then((response) => {
        if (response.status === 200) {
          const newData = response.data.data.map((e) => ({
            value: e,
            label: e,
          }));

          if (response.data.data.length > 0) {
            setSubCategoryList(newData);
          } else {
            axios
              .get(`/api/purchase-product`, {
                params: {
                  brandName: BrandSelect.value,
                  catName: value,
                },
              })
              .then((response) => {
                if (response.status === 200) {
                  const newData = response.data.data.map((e) => ({
                    value: e,
                    label: e,
                  }));
                  setProductNameList(newData);
                }
              });
          }
        }
        cToken = null;
      });
  }

  function getProductNameList(value) {
    if (cToken) {
      cToken.cancel("New Request Made");
    }
    cToken = axios.CancelToken.source();

    console.log(CategorySelect.value);
    axios
      .get(`/api/purchase-product`, {
        params: {
          brandName: BrandSelect.value,
          catName: CategorySelect.value,
          subName: value,
        },
        cancelToken: cToken.token,
      })
      .then((response) => {
        if (response.status === 200) {
          const newData = response.data.data.map((e) => ({
            value: e,
            label: e,
          }));
          setProductNameList(newData);
        }
        cToken = null;
      });
  }

  function getProductVariationList(value) {
    // if (cToken) {
    //   cToken.cancel("New Request Made");
    // }
    // cToken = axios.CancelToken.source();
    axios
      .get(
        `/api/product-variation`
        // data,
        // {
        //   cancelToken: cToken.token,
        // }
      )
      .then((response) => {
        if (response.status === 200) {
          var a = response.data.data;
          axios
            .get(`/api/purchase-product-variation`, {
              params: {
                productName: value,
              },
            })
            .then((response2) => {
              if (response2.status === 200) {
                setProductVariantOptionList(
                  a.filter((item) => response2.data.data.includes(item.id))
                );
              }
              // cToken = null;
            });
        }
        // cToken = null;
      });
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    form.append("total", parseFloat(value1) * parseFloat(value2) || 0);

    // Send the formData to the backend API using Axios
    axios
      .post("/api/v1/purchase-management/create", form)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          fireToast(response.data.message, "success");
          router(-1);
        }
      })
      .catch((error) => {
        // Handle errors
        alert("Error adding brand");
        console.log("Error adding brand:", error);
      });
  };

  useEffect(() => {
    getProductBrandList();
    getSupplierList();
    GetWalletType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <form className="w-full" onSubmit={handleFormSubmit}>
        <Card className="w-full ">
          <div className="flex flex-col w-full gap-4">
            <h5>Product Details</h5>
            <div className="grid-cols-3 gap-6 max-sm:flex max-sm:flex-col lg:grid">
              <div>
                <div className="block mb-2">
                  <Label htmlFor="brand" value="Brand *" />
                </div>
                <Select
                  id="brand"
                  name="brand"
                  className="croni-select-container"
                  classNamePrefix="croni-select"
                  options={BrandList}
                  isClearable={true}
                  value={BrandSelect}
                  onChange={(e) => {
                    e ? getProductCategoryList(e.value) : setCategoryList([]);
                    setCategorySelect("");
                    setBrandSelect(e);
                    setProductVariantOptionList([]);
                    setSubCategoryList([]);
                    setSubCategorySelect("");
                  }}
                />
              </div>
              {CategoryList.length > 0 && (
                <div>
                  <div className="block mb-2">
                    <Label htmlFor="category" value="Category *" />
                  </div>
                  <Select
                    name="category"
                    id="category"
                    className="croni-select-container"
                    classNamePrefix="croni-select"
                    options={CategoryList}
                    isClearable={true}
                    value={CategorySelect}
                    required
                    onChange={(e) => {
                      e
                        ? getProductSubCategoryList(e.value)
                        : setSubCategoryList([]);
                      setSubCategoryList([]);
                      setProductNameList([]);
                      setProductSelect("");
                      setSubCategorySelect("");
                      setProductVariantOptionList([]);
                      setCategorySelect(e);
                    }}
                  />
                </div>
              )}
              {SubCategoryList.length > 0 && (
                <div>
                  <div className="block mb-2">
                    <Label htmlFor="sub_category" value="Sub Category *" />
                  </div>
                  <Select
                    name="sub_category"
                    id="sub_category"
                    className="croni-select-container"
                    classNamePrefix="croni-select"
                    options={SubCategoryList}
                    isClearable={true}
                    value={SubCategorySelect}
                    onChange={(e) => {
                      e ? getProductNameList(e.value) : setProductNameList([]);
                      setProductSelect("");
                      setProductVariantOptionList([]);
                      setSubCategorySelect(e);
                    }}
                  />
                </div>
              )}
              {ProductNameList.length > 0 && (
                <div>
                  <div className="block mb-2">
                    <Label htmlFor="product_name" value="Product Name *" />
                  </div>
                  <Select
                    name="product_name"
                    id="product_name"
                    className="croni-select-container"
                    classNamePrefix="croni-select"
                    options={ProductNameList}
                    isClearable={true}
                    value={ProductSelect}
                    onChange={(e) => {
                      e
                        ? getProductVariationList(e.value)
                        : setProductVariantOptionList([]);
                      setProductSelect(e);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <hr className="mt-6 mb-3 " />
          <div className="flex flex-col w-full gap-4">
            <h5>Variant Options</h5>
            <div className="w-full grid-cols-3 gap-6 max-sm:flex max-sm:flex-col lg:grid">
              {ProductVariantOptionList.map((e, i) => (
                <div key={i} className="flex flex-col gap-2">
                  <div className="block">
                    <Label
                      htmlFor="brand"
                      className="capitalize "
                      value={`${e.name} *`}
                    />
                  </div>
                  <Select
                    name={`var-${e.id}`}
                    className="croni-select-container"
                    classNamePrefix="croni-select"
                    options={e.options.map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    required
                  />
                </div>
              ))}
            </div>
          </div>
          <hr className="mt-6 mb-3 " />
          <div className="flex flex-col w-full gap-4">
            <h5>Stock Details</h5>
            <div className="max-sm:flex max-sm:flex-col lg:grid w-full grid-cols-3 mb-[5rem] gap-6">
              <div>
                <div className="block mb-2">
                  <Label htmlFor="datetime" value="Purchase Date *" />
                </div>
                <TextInput
                  required
                  name="datetime"
                  id="datetime"
                  sizing="md"
                  type="date"
                />
              </div>
              <div className="lg:col-span-2"></div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="supplier" value="Supplier *" />
                </div>
                <Select
                  name="supplier"
                  id="supplier"
                  className="croni-select-container"
                  classNamePrefix="croni-select"
                  options={SupplierList}
                  isClearable={true}
                  required
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="purchase_price" value="Purchase Price *" />
                </div>
                <TextInput
                  required
                  name="purchase_price"
                  id="purchase_price"
                  sizing="md"
                  type="text"
                  placeholder="Enter purchase price"
                  addon="MMK"
                  value={value1}
                  onChange={handleInputChange1}
                />
              </div>
              <div className="max-w-[5rem]">
                <div className="block mb-2">
                  <Label htmlFor="qty" value="Quantity *" />
                </div>
                <TextInput
                  required
                  name="qty"
                  id="qty"
                  sizing="md"
                  type="text"
                  placeholder="0"
                  value={value2}
                  onChange={handleInputChange2}
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="wallet_id" value="Wallet Type *" />
                </div>
                <Select
                  id="wallet_id"
                  name="wallet_id"
                  className="croni-select-container"
                  classNamePrefix="croni-select"
                  options={WalletType}
                  isClearable={true}
                  required={true}
                  onChange={(e) => setSelectedWalletType(e)}
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="location" value="Location *" />
                </div>
                <TextInput
                  required
                  name="location"
                  id="location"
                  sizing="md"
                  type="text"
                  placeholder="Enter supplier location"
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="purchaser" value="Purchaser Name*" />
                </div>
                <TextInput
                  required
                  name="purchaser"
                  id="purchaser"
                  sizing="md"
                  type="text"
                  placeholder="Enter purchaser name"
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="status" value="Paid/Unpaid *" />
                </div>
                <Select
                  id="status"
                  name="status"
                  className="croni-select-container"
                  classNamePrefix="croni-select"
                  options={Status}
                  isClearable={true}
                  required={true}
                  onChange={(e) => setSelectedWalletType(e)}
                />
              </div>
              <div className="lg:col-span-2">
                <div className="block mb-2">
                  <Label htmlFor="note" value="Note*" />
                </div>
                <Textarea
                  name="note"
                  id="note"
                  placeholder="Leave a note..."
                  rows={4}
                />
              </div>
            </div>
          </div>

          <h3 className="font-semibold max-sm:text-sm">
            Total Amount:{" "}
            {(parseFloat(value1) * parseFloat(value2) || 0).toLocaleString()} Ks
          </h3>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2 lg:flex">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <button
              type="button"
              className=" btn btn-secondary"
              onClick={() => router(-1)}
            >
              Cancel
            </button>
          </div>
        </Card>
      </form>
    </div>
  );
}

export default memo(AddStock);
