import React from "react";
import { Breadcrumb } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCurrentPath } from "../hooks/useCurrentPath";

function BreadCrumb() {
  const route = useNavigate();
  const { currentPath, currentSubPath, currentMicroPath } = useCurrentPath();

  return (
    <Breadcrumb aria-label="breadcrumb">
      {currentSubPath && (
        <Breadcrumb.Item
          onClick={() => route(currentPath.href)}
          href={
            currentPath && !currentSubPath && !currentMicroPath
              ? undefined
              : "#?"
          }
        >
          {currentPath.name}
        </Breadcrumb.Item>
      )}
      {currentSubPath && (
        <Breadcrumb.Item
          onClick={() => route(currentPath.href + currentSubPath.href)}
          href={currentSubPath && !currentMicroPath ? undefined : "#?"}
        >
          {currentSubPath.name}
        </Breadcrumb.Item>
      )}
      {currentMicroPath && (
        <Breadcrumb.Item
          onClick={() =>
            route(
              currentPath.href + currentSubPath.href + currentMicroPath.href
            )
          }
          href={currentMicroPath ? undefined : "#?"}
        >
          {currentMicroPath.name}
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
}

export default BreadCrumb;
