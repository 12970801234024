import { Alert, Button, Card, Label, TextInput } from "flowbite-react";
import axios from "axios";
import { useState } from "react";
import { HiInformationCircle } from "react-icons/hi2";
import PhoenixLogo from "../../../image/phoenix_logo.png";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import fireToast from "../../components/fireToast";

function Login() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const source = axios.CancelToken.source();
  const [cookies, setCookie, removeCookie] = useCookies(["userSession"]);
  const route = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const formData = new FormData(e.target);
      const { data, status } = await axios.post("/api/v1/login", formData, {
        cancelToken: source.token,
      });

      setCookie("userSession", JSON.stringify(data?.data), {
        path: "/",
        expires: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000),
      });

      // document.cookie = `userSession=${JSON.stringify(
      //   data?.data
      // )}; expires=${new Date(
      //   Date.now() + 1 * 24 * 60 * 60 * 1000
      // ).toUTCString()}; path=/;`;
      if (status == 200) {
        fireToast(data.message, "success");
        window.location.href = "/";
      }
    } catch (error) {
      fireToast(error.response.data.message, "error");
      console.log(error);
      if (axios.isCancel(error)) {
        setError("Request canceled by user");
      } else {
        setError(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="absolute flex flex-col items-center justify-center w-full h-full px-4">
      <img
        src={PhoenixLogo}
        alt="Phoenix"
        className="w-[17rem] h-auto object-cover mb-6"
      />
      <div className="w-full md:w-[30rem] border rounded-xl p-4">
        <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
          {error && (
            <Alert color="failure" icon={HiInformationCircle}>
              <span>
                <p>{error}</p>
              </span>
            </Alert>
          )}
          <div>
            <div className="block mb-2">
              <Label htmlFor="email" value="Email" className="font-semibold" />
            </div>
            <TextInput
              id="email"
              name="email"
              required
              type="email"
              disabled={isLoading}
            />
          </div>
          <div className="mb-4">
            <div className="block mb-2">
              <Label
                htmlFor="password"
                value="Password"
                className="font-semibold"
              />
            </div>
            <TextInput
              id="password"
              name="password"
              required
              type="password"
              disabled={isLoading}
            />
          </div>
          {!isLoading ? (
            <button className="btn btn-primary" type="submit">
              Login
            </button>
          ) : (
            <button className="btn btn-primary" disabled>
              <i className="text-lg fa-regular fa-loader fa-spin"></i>
              Logging in
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default Login;
