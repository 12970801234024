import { useCookies } from "react-cookie";
import ToolboxSection from "../../../toolbox-button-group";

const AddVariantOptionButton = () => {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  return (
    storedData?.permission.setting_mgmt.includes(1) && (
      <ToolboxSection className="grid items-center grid-cols-1 gap-3 md:flex">
        <button
          onClick={() =>
            document.getElementById("variant-option-create-modal").showModal()
          }
          className="btn btn-primary max-sm:w-full"
        >
          <span className="flex items-center gap-2">
            <i className="fa-solid fa-plus"></i>
            <p>New Variant Option</p>
          </span>
        </button>
      </ToolboxSection>
    )
  );
};

export default AddVariantOptionButton;
