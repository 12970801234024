import axios from "axios";
import { Card, Label, TextInput, Textarea } from "flowbite-react";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import fireToast from "../../components/fireToast";

function EditStock() {
  const { purchaseId } = useParams();
  const [Data, setData] = useState();
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [DateTimeInputSW, setDateTimeInputSW] = useState("text");

  const [SupplierList, setSupplierList] = useState([]);

  const [WalletType, setWalletType] = useState([]);
  const [Status, setStatus] = useState([
    { value: "paid", label: "Paid" },
    { value: "unpaid", label: "Unpaid" },
  ]);
  const [SelectedWalletType, setSelectedWalletType] = useState(null);
  const router = useNavigate();

  const capitalizeFirstLetter = (string) =>
    `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

  const handleInputChange1 = (e) => {
    const inputValue = e.target.value;

    // Use a regular expression to allow only numeric characters
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    setValue1(numericValue);
  };

  const handleInputChange2 = (e) => {
    const inputValue = e.target.value;

    // Use a regular expression to allow only numeric characters
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    setValue2(numericValue);
  };

  function GetWalletType() {
    axios.get(`/api/v1/sale/props`).then((response) => {
      if (response.status === 200) {
        setWalletType(response.data.wallet);
      }
    });
  }

  function getPurchaseData() {
    axios
      .get(`/api/v1/purchase-management/edit/${purchaseId}`)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.data);
          setValue1(response.data.data.purchase_price);
          setValue2(response.data.data.qty);
        }
      })
      .catch((error) => {
        fireToast(error.response.data.message, "error");
      });
  }

  function getSupplierList() {
    // if (cToken) {
    //   cToken.cancel("New Request Made");
    // }
    // cToken = axios.CancelToken.source();
    axios
      .get(
        `/api/product-supplier`
        // data,
        // {
        //   cancelToken: cToken.token,
        // }
      )
      .then((response) => {
        if (response.status === 200) {
          const newData = response.data.data.map((e) => ({
            value: e,
            label: e,
          }));
          setSupplierList(newData);
        }
        // cToken = null;
      });
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    form.append("total", parseFloat(value1) * parseFloat(value2) || 0);
    form.append("variation_data_id", Data.product_variation_id || "");

    // Send the formData to the backend API using Axios
    axios
      .post(`/api/v1/purchase-management/edit/${purchaseId}`, form)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          fireToast(response.data.message, "success");
          router("/product-management/purchase-list");
        }
      })
      .catch((error) => {
        // Handle errors
        fireToast(error.response.data.message, "error");
      });
    // .finally((a) => {
    //   setOpenModal(false); // Close the modal after successful submission
    //   fetchData();
    // });
  };

  useEffect(() => {
    getPurchaseData();
    getSupplierList();
    GetWalletType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    Data && (
      <form className="w-full" onSubmit={handleFormSubmit}>
        <Card className="w-full ">
          <div className="flex flex-col w-full gap-4">
            <h5>Product Code: #{Data.sku}</h5>
          </div>

          <hr className="mt-6 mb-3 " />

          <div className="flex flex-col w-full gap-4">
            <h5>Details</h5>
            <div className="max-sm:flex max-sm:flex-col lg:grid w-full grid-cols-3 mb-[5rem] gap-6">
              <div>
                <div className="block mb-2">
                  <Label htmlFor="datetime" value="Purchase Date *" />
                </div>
                <TextInput
                  required
                  name="datetime"
                  id="datetime"
                  sizing="md"
                  type={DateTimeInputSW}
                  onClick={() => setDateTimeInputSW("date")}
                  onChange={() => setDateTimeInputSW("text")}
                  defaultValue={
                    Data.datetime && moment(Data.datetime).format("YYYY-MM-DD")
                  }
                />
              </div>
              <div className="lg:col-span-2"></div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="supplier" value="Supplier *" />
                </div>
                <Select
                  name="supplier"
                  id="supplier"
                  className="croni-select-container"
                  classNamePrefix="croni-select"
                  options={SupplierList}
                  isClearable={true}
                  defaultValue={{
                    label: Data.supplier,
                    value: Data.supplier_id,
                  }}
                  required
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="purchase_price" value="Purchase Price *" />
                </div>
                <TextInput
                  required
                  name="purchase_price"
                  id="purchase_price"
                  sizing="md"
                  type="text"
                  placeholder="Enter purchase price"
                  addon="MMK"
                  value={value1}
                  onChange={handleInputChange1}
                />
              </div>
              <div className="max-w-[5rem]">
                <div className="block mb-2">
                  <Label htmlFor="qty" value="Quantity *" />
                </div>
                <TextInput
                  required
                  name="qty"
                  id="qty"
                  sizing="md"
                  type="text"
                  placeholder="0"
                  value={value2}
                  onChange={handleInputChange2}
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="wallet_id" value="Wallet Type *" />
                </div>
                <Select
                  id="wallet_id"
                  name="wallet_id"
                  className="croni-select-container"
                  classNamePrefix="croni-select"
                  options={WalletType}
                  isClearable={true}
                  required={true}
                  onChange={(e) => setSelectedWalletType(e)}
                  defaultValue={{
                    label: Data.wallet_name,
                    value: Data.wallet_id,
                  }}
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="location" value="Location *" />
                </div>
                <TextInput
                  required
                  name="location"
                  id="location"
                  sizing="md"
                  type="text"
                  placeholder="Enter supplier location"
                  defaultValue={Data.location}
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="purchaser" value="Purchaser Name*" />
                </div>
                <TextInput
                  required
                  name="purchaser"
                  id="purchaser"
                  sizing="md"
                  type="text"
                  placeholder="Enter purchaser name"
                  defaultValue={Data.purchaser}
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="status" value="Paid/Unpaid *" />
                </div>
                <Select
                  id="status"
                  name="status"
                  className="croni-select-container"
                  classNamePrefix="croni-select"
                  options={Status}
                  isClearable={true}
                  required={true}
                  defaultValue={{
                    label: capitalizeFirstLetter(Data.status),
                    value: Data.status,
                  }}
                />
              </div>
              <div className="lg:col-span-2">
                <div className="block mb-2">
                  <Label htmlFor="note" value="Note*" />
                </div>
                <Textarea
                  name="note"
                  id="note"
                  placeholder="Leave a note..."
                  rows={4}
                  defaultValue={Data.note}
                />
              </div>
            </div>
          </div>

          <h3 className="font-semibold max-sm:text-sm">
            Total Amount:{" "}
            {(parseFloat(value1) * parseFloat(value2) || 0).toLocaleString()} Ks
          </h3>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2 lg:flex">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <button
              type="button"
              className=" btn btn-secondary"
              onClick={() => router(-1)}
            >
              Cancel
            </button>
          </div>
        </Card>
      </form>
    )
  );
}

export default memo(EditStock);
