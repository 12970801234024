import { Checkbox, Label, TextInput, Textarea } from "flowbite-react";

const EditModal = ({ handleEditSubmit, PermissionData, EditGroupData }) => {
  return (
    <dialog
      id="user-group-list-edit-modal"
      className=" modal modal-bottom md:modal-middle"
    >
      <div className="!max-w-[70rem] p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Edit Group</h3>
          <form method="dialog">
            <button className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2">
              ✕
            </button>
          </form>
        </div>
        <form
          onSubmit={handleEditSubmit}
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible"
        >
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 ">
            <div className="space-y-4">
              <div>
                <div className="block mb-2">
                  <Label htmlFor="name" value="Name" />
                </div>
                <TextInput
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Enter group name"
                  required
                  defaultValue={EditGroupData?.name}
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="description" value="Enter description" />
                </div>
                <Textarea
                  id="description"
                  name="description"
                  placeholder="Write something...."
                  rows={4}
                  required
                  defaultValue={EditGroupData?.description}
                />
              </div>
            </div>
            <div className="col-span-2">
              <div className="block mb-2">
                <Label htmlFor="permissions" value="Permissions" />
              </div>
              <div className="p-4 border rounded-md bg-slate-50">
                <div className="grid grid-cols-2 gap-2 lg:grid-cols-4 gap-y-4">
                  {PermissionData.map((data, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <Checkbox
                        id={`permission-${data.category}-${data.assign_number}`}
                        value={data.id}
                        name="permissions[]"
                        // onChange={() => handleCheckboxChange(data.id)}
                        // checked={SelectedPermissionList.includes(data.id)}
                        defaultChecked={EditGroupData?.permission_list?.includes(
                          data.id
                        )}
                      />
                      <Label
                        htmlFor={`permission-${data.category}-${data.assign_number}`}
                      >
                        {data.name}
                      </Label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="sticky grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div className="grid grid-cols-2 gap-2 mt-2 md:col-span-2 lg:col-start-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
              <form method="dialog">
                <button type="submit" className="w-full btn btn-secondary">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default EditModal;
