import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ToolboxSection = ({ children = null, ...props }) => {
  return (
    <div className={`flex gap-3 ${props?.className}`} id="toolbox-btn-group">
      {children}
    </div>
  );
};

export default ToolboxSection;
