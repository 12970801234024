import { Label, TextInput, Textarea } from "flowbite-react";
import moment from "moment";
import { useEffect, useState } from "react";
import Select from "react-select";
import clearForm from "../../../../utils/clearForm";

const EditModal = ({
  handleEditFormSubmit,
  EditData,
  WalletType,
  ClearForm,
}) => {
  const [MoneyInWallet, setMoneyInWallet] = useState(null);
  const [MoneyOutWallet, setMoneyOutWallet] = useState(null);
  const [ProfitWallet, setProfitWallet] = useState(null);

  function clearAll() {
    setMoneyInWallet(null);
    setMoneyOutWallet(null);
    setProfitWallet(null);
    clearForm();
  }

  useEffect(() => {
    if (EditData) {
      setMoneyInWallet({
        value: EditData.in_wallet_id,
        label: EditData.in_wallet_name,
      });
      setMoneyOutWallet({
        value: EditData.out_wallet_id,
        label: EditData.out_wallet_name,
      });
      setProfitWallet({
        value: EditData.profit_wallet_id,
        label: EditData.profit_wallet_name,
      });
    }
  }, [EditData]);

  useEffect(() => {
    if (ClearForm) {
      clearAll();
    }
  }, [ClearForm]);
  return (
    <dialog
      id="money-exchange-edit-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Edit Record</h3>
          <form method="dialog">
            <button
              className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
              onClick={clearAll}
            >
              ✕
            </button>
          </form>
        </div>
        <form
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form"
          onSubmit={handleEditFormSubmit}
        >
          <div>
            <div className="block mb-2 ">
              <Label htmlFor="record_datetime" value="Date" />
            </div>
            <div className="grid w-full grid-cols-2 gap-2">
              <TextInput
                type="date"
                id="record_datetime"
                name="record_datetime"
                required
                defaultValue={
                  EditData.record_datetime &&
                  moment(EditData.record_datetime).format("YYYY-MM-DD")
                }
              />
            </div>
          </div>
          <div>
            <div className="block mb-2 ">
              <Label htmlFor="in_amount" value="Money IN" />
            </div>
            <div className="grid w-full grid-cols-2 gap-2">
              <TextInput
                id="in_amount"
                name="in_amount"
                placeholder="Enter amount"
                required
                defaultValue={EditData.in_amount}
              />
              <Select
                id="in_wallet_id"
                name="in_wallet_id"
                className="croni-select-container"
                classNamePrefix="croni-select"
                placeholder="Choose Wallet"
                options={WalletType}
                required={true}
                value={MoneyInWallet}
                onChange={(data) => setMoneyInWallet(data)}
              />
            </div>
          </div>
          <div>
            <div className="block mb-2 ">
              <Label htmlFor="out_amount" value="Money OUT" />
            </div>
            <div className="grid w-full grid-cols-2 gap-2">
              <TextInput
                id="out_amount"
                name="out_amount"
                placeholder="Enter amount"
                required
                defaultValue={EditData.out_amount}
              />
              <Select
                id="out_wallet_id"
                name="out_wallet_id"
                className="croni-select-container"
                classNamePrefix="croni-select"
                placeholder="Choose Wallet"
                options={WalletType}
                required={true}
                value={MoneyOutWallet}
                onChange={(data) => setMoneyOutWallet(data)}
              />
            </div>
          </div>
          <div>
            <div className="block mb-2 ">
              <Label htmlFor="profit_amount" value="Profit" />
            </div>
            <div className="grid w-full grid-cols-2 gap-2">
              <TextInput
                id="profit_amount"
                name="profit_amount"
                placeholder="Enter amount"
                required
                defaultValue={EditData.profit_amount}
              />
              <Select
                id="profit_wallet_id"
                name="profit_wallet_id"
                className="croni-select-container"
                classNamePrefix="croni-select"
                placeholder="Choose Wallet"
                options={WalletType}
                required={true}
                value={ProfitWallet}
                onChange={(data) => setProfitWallet(data)}
              />
            </div>
          </div>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <form method="dialog">
              <button
                type="submit"
                className="w-full btn btn-secondary"
                onClick={clearAll}
              >
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default EditModal;
