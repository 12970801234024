const EditProfileImageModal = ({
  selectedImage,
  handleCancel,
  handleProfileImageEdit,
}) => {
  function clearAll() {
    handleCancel();
  }

  return (
    <dialog
      id="profile-image-edit-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Selected Profile Picture</h3>
          <form method="dialog">
            <button
              className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
              onClick={() => clearAll()}
            >
              ✕
            </button>
          </form>
        </div>
        <form className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form">
          <div className="flex items-center justify-center w-full">
            <div className="overflow-hidden border w-[15rem]">
              <img src={selectedImage} alt="Selected" className="w-full " />
            </div>
          </div>

          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button
              type="button"
              onClick={() => handleProfileImageEdit()}
              className="btn btn-primary"
            >
              Submit
            </button>
            <form method="dialog">
              <button
                type="submit"
                onClick={() => clearAll()}
                className="w-full btn btn-secondary"
              >
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default EditProfileImageModal;
