import axios from "axios";
import { Label, TextInput, Textarea, ToggleSwitch } from "flowbite-react";
import { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import fireToast from "../../components/fireToast";
import ExcelUploadModal from "../../components/modals/product/product-list/create/excel-upload";
import defaultImage from "../../../image/default_product-768x768.jpg";

function RegisterProduct() {
  const navigate = useNavigate();
  var cToken = null;
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image file
  const imageRef = useRef(null);

  const [BrandList, setBrandList] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [SubCategorySelect, setSubCategorySelect] = useState(null);
  const [ProductVariantOptionList, setProductVariantOptionList] = useState([]);

  const [ExcelUploadPreloader, setExcelUploadPreloader] = useState(false);

  const handleCameraButtonClick = () => {
    imageRef.current.click();
  };

  const handleImageChange = (event) => {
    // Update the selectedImage state with the selected file
    setSelectedImage(event.target.files[0]);
  };

  const handleImageRemove = () => {
    // Clear the selected image when the remove button is clicked
    setSelectedImage(null);
  };

  function getProductBrandList() {
    // if (cToken) {
    //   cToken.cancel("New Request Made");
    // }
    // cToken = axios.CancelToken.source();
    axios
      .get(
        `/api/product-brand`
        // data,
        // {
        //   cancelToken: cToken.token,
        // }
      )
      .then((response) => {
        if (response.status === 200) {
          const newData = response.data.data.map((e) => ({
            value: e,
            label: e,
          }));
          setBrandList(newData);
        }
        // cToken = null;
      });
  }

  function getProductCategoryList() {
    if (cToken) {
      cToken.cancel("New Request Made");
    }
    cToken = axios.CancelToken.source();
    axios
      .get(
        `/api/product-category`,
        // data,
        {
          cancelToken: cToken.token,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const newData = response.data.data.map((e) => ({
            value: e,
            label: e,
          }));
          setCategoryList(newData);
        }
        cToken = null;
      });
  }

  function getProductSubCategoryList(value) {
    if (cToken) {
      cToken.cancel("New Request Made");
    }
    cToken = axios.CancelToken.source();

    axios
      .get(`/api/product-sub-category`, {
        params: {
          catName: value,
        },
        cancelToken: cToken.token,
      })
      .then((response) => {
        if (response.status === 200) {
          const newData = response.data.data.map((e) => ({
            value: e,
            label: e,
          }));
          setSubCategoryList(newData);
        }
        cToken = null;
      });
  }

  function getProductVariationList() {
    // if (cToken) {
    //   cToken.cancel("New Request Made");
    // }
    // cToken = axios.CancelToken.source();
    axios
      .get(
        `/api/product-variation`
        // data,
        // {
        //   cancelToken: cToken.token,
        // }
      )
      .then((response) => {
        if (response.status === 200) {
          setProductVariantOptionList(response.data.data);
        }
        // cToken = null;
      });
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    formData.append("product-image", selectedImage);

    axios
      .post("/api/v1/product-management/create", formData)
      .then(({ data, status }) => {
        if (status === 200 || status === 201) {
          fireToast(data.message, "success");
          navigate("/product-management/product-list");
        }
      })
      .catch((error) => fireToast(error.response.data.message, "error"));
  };

  const handleExcelFormSubmit = async (event) => {
    event.preventDefault();
    setExcelUploadPreloader(true);
    document.getElementById("product-list-create-excel-upload-modal").close();

    const formData = new FormData(event.target);

    axios
      .post("/api/v1/product-management/excel-upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ status, data }) => {
        if (status === 200) {
          fireToast(data.message, "success");
        }

        navigate("/product-management/product-list");
      })
      .catch(({ response: { data } }) => fireToast(data.error, "error"))
      .finally(() => {
        setExcelUploadPreloader(false);
      });
  };

  useEffect(() => {
    getProductBrandList();
    getProductVariationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [VariantOptionList, setVariantOptionList] = useState([]);
  return (
    <div>
      <form className="w-full" onSubmit={handleFormSubmit}>
        <div className="w-full p-4 border rounded-lg shadow-sm">
          <div className="grid-cols-2 gap-4 max-sm:flex max-sm:flex-col lg:grid">
            <div className="flex flex-col items-center w-full gap-4">
              <div className="w-[10rem] h-[10rem] rounded-md overflow-hidden relative">
                <img
                  className="object-cover w-full "
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : defaultImage
                  }
                  alt=""
                />
                <div className="absolute bottom-0 flex justify-center w-full bg-opacity-50 bg-slate-400">
                  {!selectedImage ? (
                    <div className="flex gap-8">
                      <button
                        className="p-2 rounded-full text-slate-800 "
                        onClick={handleCameraButtonClick}
                      >
                        <i className="text-lg fa-light fa-camera"></i>
                      </button>
                    </div>
                  ) : (
                    <div className="flex gap-8">
                      <button
                        className="p-2 text-white rounded-full "
                        onClick={handleCameraButtonClick}
                      >
                        <i className="text-lg text-yellow-400 fa-light fa-pen-to-square"></i>
                      </button>
                      <button
                        className="p-2 text-white rounded-full "
                        onClick={handleImageRemove}
                      >
                        <i className="text-lg text-red-600 fa-regular fa-trash-xmark"></i>
                      </button>
                    </div>
                  )}

                  <input
                    ref={imageRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="absolute opacity-0 -z-30"
                  />
                </div>
              </div>
              <div className="flex flex-col w-full gap-4">
                <div>
                  <div className="block mb-2">
                    <Label htmlFor="productName" value="Product Name *" />
                  </div>
                  <TextInput
                    id="productName"
                    name="productName"
                    sizing="md"
                    type="text"
                    placeholder="Enter product name"
                  />
                </div>
                <div id="textarea">
                  <div className="block mb-2">
                    <Label htmlFor="description" value="Description" />
                  </div>
                  <Textarea
                    id="description"
                    name="description"
                    placeholder="Enter your description"
                    rows={8}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full gap-4">
              <h5>Product Details</h5>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="brand" value="Brand *" />
                </div>
                <Select
                  id="brand"
                  name="brand"
                  className="croni-select-container"
                  classNamePrefix="croni-select"
                  options={BrandList}
                  isClearable={true}
                  onChange={() => getProductCategoryList()}
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="category" value="Category *" />
                </div>
                <Select
                  name="category"
                  id="category"
                  className="croni-select-container"
                  classNamePrefix="croni-select"
                  options={CategoryList}
                  isClearable={true}
                  required
                  onChange={(e) => {
                    e
                      ? getProductSubCategoryList(e.value)
                      : setSubCategoryList([]);
                    setSubCategorySelect("");
                  }}
                />
              </div>
              {SubCategoryList.length > 0 && (
                <div>
                  <div className="block mb-2">
                    <Label htmlFor="sub_category" value="Sub Category *" />
                  </div>
                  <Select
                    name="sub_category"
                    id="sub_category"
                    className="croni-select-container"
                    classNamePrefix="croni-select"
                    options={SubCategoryList}
                    isClearable={true}
                    value={SubCategorySelect}
                    onChange={(e) => setSubCategorySelect(e)}
                  />
                </div>
              )}
              {/* <div>
                <div className="block mb-2">
                  <Label htmlFor="sale_price" value="Sale Price *" />
                </div>
                <TextInput
                  required
                  name="sale_price"
                  id="sale_price"
                  sizing="md"
                  type="text"
                  placeholder="Enter sale price"
                  addon="MMK"
                  value={value1}
                  onChange={handleInputChange1}
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label htmlFor="promo_price" value="Promo Price *" />
                </div>
                <TextInput
                  required
                  name="promo_price"
                  id="promo_price"
                  sizing="md"
                  type="text"
                  placeholder="Enter promotion price"
                  addon="MMK"
                  value={value2}
                  onChange={handleInputChange2}
                />
              </div> */}
            </div>
          </div>
          <hr className="mt-6 mb-3 " />
          <div className="flex flex-col w-full gap-4">
            <h5>Variant Options</h5>
            <div className="lg:grid flex flex-wrap w-full grid-cols-3 mb-[5rem] gap-6">
              {ProductVariantOptionList.map((e, i) => (
                <div key={i} className="flex flex-col gap-4">
                  <ToggleSwitch
                    checked={VariantOptionList.includes(e.id)}
                    label={e.name}
                    onClick={() => {
                      // Check if the option is already in VariantOptionList
                      const optionIndex = VariantOptionList.indexOf(e.id);

                      if (optionIndex !== -1) {
                        // If it's already selected, remove it
                        setVariantOptionList((oldValue) => [
                          ...oldValue.slice(0, optionIndex),
                          ...oldValue.slice(optionIndex + 1),
                        ]);
                      } else {
                        // If it's not selected, add it
                        setVariantOptionList((oldValue) => [...oldValue, e.id]);
                      }
                    }}
                  />
                  {VariantOptionList.includes(e.id) && (
                    <Select
                      name={`var-${e.id}`}
                      className="croni-select-container"
                      classNamePrefix="croni-select"
                      options={e.options.map((option) => ({
                        value: option,
                        label: option,
                      }))}
                      isMulti
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="grid justify-end grid-cols-2 gap-2 mt-2 lg:flex">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <button
              type="button"
              className=" btn btn-secondary"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
      {/* add model */}
      <ExcelUploadModal handleExcelFormSubmit={handleExcelFormSubmit} />
      <div
        className={`${
          ExcelUploadPreloader ? "" : "hidden"
        } fixed w-full h-[100vh] bg-black bg-opacity-50 flex justify-center items-center z-[99999] top-0 left-0`}
      >
        <div className="p-[3rem] rounded-md bg-white">
          <i class="fa-duotone fa-spinner-third fa-spin text-primary text-[5rem]"></i>
        </div>
      </div>
    </div>
  );
}

export default memo(RegisterProduct);
