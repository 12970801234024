import { Label, TextInput, Textarea } from "flowbite-react";
import Select from "react-select";

const EditModal = ({
  handleEditFormSubmit,
  updateRecordRef,
  SelectData,
  TheSelector,
  setTheSelector,
  EditData,
}) => {
  return (
    <dialog
      id="expense-management-edit-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Edit Expense</h3>
          <form method="dialog">
            <button className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2">
              ✕
            </button>
          </form>
        </div>

        <form
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form"
          onSubmit={handleEditFormSubmit}
          ref={updateRecordRef}
        >
          <div className="grid grid-cols-1 gap-4">
            <div>
              <div className="block mb-2">
                <Label
                  htmlFor="expense_category_id"
                  value="Expense Category *"
                />
              </div>
              <Select
                id="expense_category_id"
                name="expense_category_id"
                className="croni-select-container"
                classNamePrefix="croni-select"
                options={SelectData?.expense_category}
                isClearable={false}
                required={true}
                value={
                  TheSelector.expense_category
                    ? SelectData?.expense_category?.find(
                        (expense_category) =>
                          expense_category.label ===
                          TheSelector.expense_category
                      )
                    : SelectData?.expense_category?.find(
                        (expense_category) =>
                          expense_category.label === EditData.expense_category
                      )
                }
                onChange={(e) => {
                  setTheSelector((prevData) => ({
                    ...prevData,
                    expense_category: e?.label || "",
                  }));
                }}
              />
            </div>
            <div>
              <div className="block mb-2">
                <Label htmlFor="wallet_id" value="Wallet *" />
              </div>
              <Select
                id="wallet_id"
                name="wallet_id"
                className="croni-select-container"
                classNamePrefix="croni-select"
                options={SelectData?.wallet}
                isClearable={false}
                required={true}
                value={
                  TheSelector.wallet
                    ? SelectData?.wallet?.find(
                        (wallet) => wallet.label === TheSelector.wallet
                      )
                    : SelectData?.wallet?.find(
                        (wallet) => wallet.label === EditData.wallet
                      )
                }
                onChange={(e) => {
                  setTheSelector((prevData) => ({
                    ...prevData,
                    wallet: e?.label || "",
                  }));
                }}
              />
            </div>
            <div>
              <div className="block mb-2">
                <Label htmlFor="amount" value="Amount *" />
              </div>
              <TextInput
                required
                name="amount"
                id="amount"
                sizing="md"
                type="text"
                placeholder="Enter amount"
                addon="MMK"
                defaultValue={EditData?.amount}
              />
            </div>
            <div>
              <div className="block mb-2">
                <Label htmlFor="person" value="By who *" />
              </div>
              <TextInput
                required
                name="person"
                id="person"
                sizing="md"
                type="text"
                placeholder="Enter person name"
                defaultValue={EditData?.person}
              />
            </div>
            <div className="">
              <div className="block mb-2">
                <Label htmlFor="description" value="Description *" />
              </div>
              <Textarea
                id="description"
                name="description"
                placeholder="Enter description"
                required
                rows={4}
                defaultValue={EditData?.description}
              />
            </div>
          </div>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <form method="dialog">
              <button type="submit" className="w-full btn btn-secondary">
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default EditModal;
