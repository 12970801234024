import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import SaleAndProfitChart from "../../components/charts/dashboard/sale-and-profit";
import { iconList } from "../../utils/settings";
import Formatter from "../../utils/formater";
import { useCookies } from "react-cookie";

const Dashboard = () => {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];
  const [SelectData, setSelectData] = useState({});
  const [DefaultSelectData, setDefaultSelectData] = useState({});
  const [WalletData, setWalletData] = useState({});

  // States to track input values and formats
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`/api/v1/expenses/props`);
        if (response.status === 200) {
          setSelectData(response.data);
          setDefaultSelectData(response.data?.wallet[0]);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const fetchWalletAmountData = () => {
    axios
      .get(`/api/v1/dashboard/wallet-total-amount`)
      .then(({ data }) => setWalletData(data))
      .catch(console.error);
  };

  const [voucherData, setVoucherData] = useState([]);
  const [PrintID, setPrintID] = useState(null);
  const fetchVoucherData = async () => {
    try {
      const response = await axios.get(`/api/v1/sale/voucher-list`);
      if (response.status === 200) {
        setVoucherData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const [dailyProfitData, setDailyProfitData] = useState(null);

  // Function to make the Axios request
  // const fetchDailySaleProfitData = async () => {
  //   const fromDate = new Date($("input[name='from_date']").val());
  //   const toDate = new Date($("input[name='to_date']").val());
  //   const params = {
  //     from_date: fromDate.toISOString().slice(0, 10),
  //     to_date: toDate.toISOString().slice(0, 10),
  //   };
  //   try {
  //     const response = await axios.get(
  //       "/api/v1/dashboard/calculate-total-amount-profit",
  //       { params }
  //     );
  //     setDailyProfitData(response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const [PaymentAmountData, setPaymentAmountData] = useState([]); // Only run this effect once when the component mounts

  const fetchPaymentAmountData = () =>
    axios
      .get(`/api/v1/dashboard/total_amounts`)
      .then(({ data }) => setPaymentAmountData(data))
      .catch(console.error);

  useEffect(() => {
    fetchWalletAmountData();
    fetchVoucherData();
    fetchPaymentAmountData();

    // Format and set the state for from and to dates
    setFromDate(moment().startOf("week").format("YYYY-MM-DD"));
    setToDate(moment().format("YYYY-MM-DD"));
  }, []);

  // useEffect(() => {
  //   if (fromDate && toDate) {
  //     fetchDailySaleProfitData();
  //   }
  // }, [fromDate, toDate]);

  useEffect(() => {
    if (PrintID !== null) {
      const print_data = voucherData[PrintID];
      const print_format = {
        customer: print_data?.voucher_data?.customer?.name,
        wallet: print_data?.voucher_data?.wallet?.name,
        product_data: print_data?.voucher_data?.product_data,
        total_amount:
          parseFloat(print_data?.total_amount) +
          parseFloat(print_data?.promotion),
        promotion: print_data?.promotion > 0 ? print_data?.promotion : 0.0,
        net_amount: print_data?.total_amount ?? 0.0,
        status: print_data?.voucher_status == "paid" ? "Paid" : "Unpaid",
        datetime: print_data?.datetime,
        voucher_code: print_data?.voucher_code,
      };

      const encode_data = btoa(JSON.stringify(print_format));

      window.open(`/print/${encode_data}`, "_blank");
      setPrintID(null);
      // const myFunction = () => {
      //   setPrintID(null);
      // };

      // const timeoutId = setTimeout(myFunction, 4000);

      // return () => {
      //   clearTimeout(timeoutId);
      // };

      //   location.reload();
    }
  }, [PrintID]);

  return (
    <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
      <div className="flex flex-col w-full gap-4 lg:col-span-2">
        <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
          <div className="flex items-center gap-4 p-4 border rounded-lg bg-slate-50 border-primary">
            <span className="bg-gradient-to-tr from-orange-500 to-orange-300 text-white rounded-full w-[3rem] h-[3rem] flex justify-center items-center">
              <i className="text-xl fa-light fa-sack-dollar drop-shadow-lg"></i>
              {/* <div className="size-[25px] aspect-square">
                <img
                  src={iconList[29]}
                  className="object-cover w-full aspect-square"
                  alt={"Total Balance"}
                />
              </div> */}
            </span>
            <div className="flex flex-col">
              <span className="text-sm text-slate-500">Total Balance</span>
              <span className="text-lg font-semibold">
                Ks{" "}
                {(WalletData?.total_amount_all_wallets || 0).toLocaleString()}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-4 p-4 rounded-lg bg-slate-50">
            <span className="bg-gradient-to-tr from-green-500 to-green-300 text-white rounded-full w-[3rem] h-[3rem] flex justify-center items-center">
              <i className="text-xl fa-light fa-money-bill-transfer drop-shadow-lg "></i>
              {/* <div className="size-[25px] aspect-square">
                <img
                  src={iconList[10]}
                  className="object-cover w-full aspect-square"
                  alt={"Receivable Amount"}
                />
              </div> */}
            </span>
            <div className="flex flex-col">
              <span className="text-sm text-slate-500">Payable Amount</span>
              <span className="text-lg font-semibold">
                Ks{" "}
                {(
                  PaymentAmountData?.total_payable_amount || 0
                ).toLocaleString()}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-4 p-4 rounded-lg bg-slate-50">
            <span className="bg-gradient-to-tr from-blue-500 to-blue-300 text-white rounded-full w-[3rem] h-[3rem] flex justify-center items-center">
              <i className="text-xl fa-light fa-piggy-bank drop-shadow-lg"></i>
              {/* <div className="size-[25px] aspect-square">
                <img
                  src={iconList[15]}
                  className="object-cover w-full aspect-square"
                  alt={"Receivable Amount"}
                />
              </div> */}
            </span>
            <div className="flex flex-col">
              <span className="text-sm text-slate-500">Receivable Amount</span>
              <span className="text-lg font-semibold">
                Ks{" "}
                {(
                  PaymentAmountData?.total_getable_amount || 0
                ).toLocaleString()}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full border rounded-lg shadow-sm bg-card text-card-foreground">
          <div className="flex flex-col justify-between gap-3 p-3 border-b lg:items-center md:flex-row">
            <h3 className="text-lg font-semibold leading-none tracking-tight whitespace-nowrap">
              Sale & Profit
            </h3>
            <div className="flex flex-row items-center justify-center gap-2">
              <span className="hidden md:block">Date:</span>
              <input
                className="w-full max-w-xs input input-bordered input-sm"
                type="date"
                id="from_date"
                name="from_date"
                max={moment(toDate).subtract(1, "day").format("YYYY-MM-DD")}
                defaultValue={moment().startOf("week").format("YYYY-MM-DD")}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <span>to</span>
              <input
                className="w-full max-w-xs input input-bordered input-sm"
                type="date"
                id="to_date"
                name="to_date"
                min={moment(fromDate).add(1, "day").format("YYYY-MM-DD")}
                max={moment().format("YYYY-MM-DD")}
                defaultValue={moment().format("YYYY-MM-DD")}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-3">
            <SaleAndProfitChart from_date={fromDate} to_date={toDate} />
          </div>
          {/* <div className="p-6">
            <div className="mb-4">
              <div className="text-lg font-semibold">Total Sale</div>
              <div className="text-xl font-bold">
                {(dailyProfitData?.total_profit || 0).toLocaleString()} MMK
              </div>
            </div>
            <div className="text-lg font-semibold">Total Profit</div>
            <div className="text-xl font-bold">
              {(dailyProfitData?.total_sale_amount || 0).toLocaleString()} MMK
            </div>
          </div> */}
        </div>
      </div>
      <div className="flex flex-col justify-between gap-4 md:flex-row lg:flex-col">
        <div
          class="w-full h-auto aspect-[24/24] md:aspect-[24/14] m-auto bg-red-100 rounded-xl relative text-white shadow-lg"
          style={{
            backgroundImage: `url('https://i.imgur.com/kGkSg1v.png')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div class="w-full top-0 flex flex-col h-full justify-between">
            <div className="flex items-center justify-between p-3">
              <h3 className="pl-4 text-2xl italic font-semibold leading-none tracking-tight text-white drop-shadow-md whitespace-nowrap">
                Wallets
              </h3>
              {SelectData?.wallet && (
                <Select
                  id="wallet_id"
                  name="wallet_id"
                  className="croni-select-container w-[10rem] text-black "
                  classNamePrefix="croni-select"
                  options={SelectData?.wallet}
                  isClearable={false}
                  required={true}
                  onChange={(data) => setDefaultSelectData(data)}
                  defaultValue={SelectData?.wallet[0]}
                />
              )}
            </div>
            <div className="flex ">
              <span className="px-4 text-xl font-light tracking-widest opacity-90">
                {storedData?.current_branch}
              </span>
            </div>
            <div className="flex flex-wrap items-center justify-between w-full p-4 ">
              <span>Balance:</span>
              <div className="text-xl font-semibold drop-shadow-md">
                Ks{" "}
                {parseFloat(
                  WalletData?.total_amount_each_wallet?.find(
                    (wallet) => wallet.id === DefaultSelectData.value
                  )?.total_amount
                ).toLocaleString() || 0}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-full overflow-hidden border rounded-lg shadow-sm bg-card text-card-foreground bg-slate-50">
          <div className="flex flex-col ">
            <div className="flex items-center justify-between p-3 bg-white border-b">
              <h3 className="text-lg font-semibold leading-none tracking-tight whitespace-nowrap">
                Voucher
              </h3>
              <NavLink
                to="/sale-management/voucher-list"
                className="btn btn-ghost btn-sm"
              >
                More <i className="fa-regular fa-angle-right"></i>
              </NavLink>
            </div>
            {voucherData.length > 0 ? (
              <div className="grid w-full grid-cols-1 gap-2 p-2 md:grid-cols-2 ">
                {voucherData.slice(0, 4).map((d, index) => (
                  <div
                    key={index}
                    className="p-2 bg-white border rounded-lg card"
                  >
                    <div className="flex justify-between">
                      <div className="flex flex-col">
                        <span className="font-bold text-[.9rem]">
                          #{d.voucher_code}
                        </span>
                        <small className="flex items-center gap-2 text-primary">
                          <i className="fa-regular fa-calendar-days"></i>
                          {Formatter(d.datetime, "date")}
                        </small>
                        <div className="flex justify-between">
                          <small>
                            Qty:{" "}
                            <span className="font-semibold">{d.total_qty}</span>
                          </small>
                          <small>
                            Ks{" "}
                            <span className="font-semibold">
                              {parseFloat(d.total_amount).toLocaleString()}
                            </span>
                          </small>
                        </div>
                      </div>
                      <button
                        onClick={() => setPrintID(index)}
                        className="btn btn-square btn-secondary btn-sm "
                      >
                        <i className="fa-solid fa-print"></i>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex items-center justify-center w-full p-4 text-slate-400">
                No data
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
