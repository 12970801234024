import { Label, TextInput } from "flowbite-react";
import clearForm from "../../../../utils/clearForm";

const CreateModal = ({ handleFormSubmit }) => {
  function clearAll() {
    clearForm();
  }

  return (
    <dialog
      id="expense-category-create-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Create Expense Category</h3>
          <form method="dialog">
            <button className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2">
              ✕
            </button>
          </form>
        </div>

        <form
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form"
          onSubmit={handleFormSubmit}
        >
          <div>
            <div className="block mb-2">
              <Label htmlFor="name" value="Expense Category Title" />
            </div>
            <TextInput id="name" name="name" required />
          </div>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <form method="dialog">
              <button
                type="submit"
                className="w-full btn btn-secondary"
                onClick={clearAll}
              >
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default CreateModal;
