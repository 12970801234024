import { Link } from "react-router-dom";
import ToolboxSection from "../../../toolbox-button-group";

//!required to edit
const AddPurchaseButton = () => {
  return (
    // storedData?.permission.user_mgmt.includes(1) && (
    <ToolboxSection>
      <Link to={"purchase-list/add"} className="btn btn-primary max-sm:w-full">
        <span className="flex items-center gap-2">
          <i className="fa-solid fa-plus"></i>
          <p>Create Purchase</p>
        </span>
      </Link>
    </ToolboxSection>
  );
  // );
};

export default AddPurchaseButton;
