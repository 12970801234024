import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useCookies } from "react-cookie";
import CreateModal from "../../components/modals/product/supplier-list/create";
import fireToast from "../../components/fireToast";
import clearForm from "../../utils/clearForm";
import EditModal from "../../components/modals/product/supplier-list/edit";
import DeleteModal from "../../components/modals/product/supplier-list/delete";

function Supplier() {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  const [data, setData] = useState([]);
  const [EditData, setEditData] = useState({});
  const [DeleteDataId, setDeleteDataId] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/v1/supplier-management`);

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Create a FormData object to send the form data
    const formData = new FormData(event.target);
    // Send the formData to the backend API using Axios
    axios
      .post("/api/v1/supplier-management/add", formData)
      .then((response) => {
        if (response.status === 201) {
          // Handle success
          fireToast(response.data.message, "success");
          clearForm();
        }
      })
      .catch((error) => {
        // Handle errors
        console.log("Error adding brand:", error);
        fireToast(error.response.data.message, "error");
      })
      .finally((a) => {
        document.getElementById("supplier-list-create-modal").close();
        fetchData();
      });
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    // Create a FormData object to send the form data
    const formData = new FormData(event.target);
    // Send the formData to the backend API using Axios
    axios
      .post(`/api/v1/supplier-management/edit/${EditData.id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          // Handle success
          document.getElementById("supplier-list-edit-modal").close();
          fireToast(response.data.message, "success");
        }
      })
      .catch((error) => {
        // Handle errors
        console.log("Error adding brand:", error);
        fireToast(error.response.data.message, "error");
      })
      .finally((a) => {
        document.getElementById("supplier-list-edit-modal").close();
        setEditData({}); // Close the modal after successful submission
        fetchData();
      });
  };

  const handleDeleteFunction = async (dataId) => {
    const formData = new FormData();
    formData.append("sID", dataId);
    try {
      const response = await axios.post(
        "/api/v1/supplier-management/delete",
        formData
      );
      if (response.status === 200) {
        setDeleteDataId(null);
        document.getElementById("supplier-list-delete-modal").close();
        fireToast(response.data.message, "success");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
    fetchData();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        header: "Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "phone_number", //normal accessorKey
        header: "Phone Num",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        {storedData?.permission.supplier_mgmt.includes(3) && (
          <Tooltip label="Edit">
            <ActionIcon
              onClick={() => [
                document.getElementById("supplier-list-edit-modal").showModal(),
                setEditData(row.original),
              ]}
            >
              <IconEdit className="text-yellow-500" />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.supplier_mgmt.includes(4) && (
          <Tooltip label="Delete">
            <ActionIcon
              onClick={() => [
                document
                  .getElementById("supplier-list-delete-modal")
                  .showModal(),
                setDeleteDataId(row.original),
              ]}
            >
              <IconTrash className="text-red-400 " />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),
    enableExpanding: false,
    // renderDetailPanel: ({ row }) => (
    //   <div className="grid grid-cols-2 gap-4">
    //     <Text>
    //       Address: <span className="font-semibold">{row.original.address}</span>
    //     </Text>

    //     <Text>
    //       Created At:{" "}
    //       <span className="font-semibold">{row.original.created_at}</span>
    //     </Text>
    //     <Text>
    //       Description:{" "}
    //       <span className="font-semibold">{row.original.note}</span>
    //     </Text>
    //   </div>
    // ),
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <div>
      <MantineReactTable table={table} />
      {/* add model */}
      <CreateModal handleFormSubmit={handleFormSubmit} />
      {/* edit model */}
      <EditModal
        handleEditFormSubmit={handleEditFormSubmit}
        EditData={EditData}
      />
      {/* delete modal */}
      <DeleteModal
        handleDeleteFunction={handleDeleteFunction}
        DeleteDataId={DeleteDataId}
      />
    </div>
  );
}

export default Supplier;
