import { Label, TextInput, Textarea } from "flowbite-react";
import clearForm from "../../../../../utils/clearForm";
import moment from "moment";
import { useEffect, useState } from "react";
import Select from "react-select";

const CreateModal = ({ handleFormSubmit, WalletType, ClearForm }) => {
  const [Wallet, setWallet] = useState(null);
  function clearAll() {
    setWallet(null);
    clearForm();
  }

  useEffect(() => {
    if (ClearForm) {
      clearAll();
    }
  }, [ClearForm]);

  return (
    <dialog
      id="payable-detail-create-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Make Payment</h3>
          <form method="dialog">
            <button className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2">
              ✕
            </button>
          </form>
        </div>

        <form
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form"
          onSubmit={handleFormSubmit}
        >
          <div>
            <div className="block mb-2 ">
              <Label htmlFor="datetime" value="Date" />
            </div>
            <TextInput
              type="date"
              id="datetime"
              name="datetime"
              required
              defaultValue={moment().format("YYYY-MM-DD")}
              max={moment().format("YYYY-MM-DD")}
            />
          </div>
          <div>
            <div className="block mb-2 ">
              <Label htmlFor="wallet_id" value="Wallet" />
            </div>
            <div className="grid w-full grid-cols-1 gap-2">
              <Select
                id="wallet_id"
                name="wallet_id"
                className="croni-select-container"
                classNamePrefix="croni-select"
                placeholder="Choose Wallet"
                options={WalletType}
                required={true}
                value={Wallet}
                onChange={(data) => setWallet(data)}
              />
            </div>
          </div>
          <div>
            <div className="block mb-2 ">
              <Label htmlFor="amount" value="Amount" />
            </div>
            <div>
              <TextInput id="amount" name="amount" required />
            </div>
          </div>

          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <form method="dialog">
              <button
                type="submit"
                className="w-full btn btn-secondary"
                onClick={clearAll}
              >
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default CreateModal;
