import { Label, TextInput } from "flowbite-react";

const EditModal = ({ EditUserData, handleEditSubmit, UserGroup }) => {
  return (
    <dialog
      id="customer-list-edit-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Edit Customer</h3>
          <form method="dialog">
            <button className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2">
              ✕
            </button>
          </form>
        </div>

        <form
          onSubmit={handleEditSubmit}
          autoComplete="off"
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible"
        >
          <div>
            <div className="block mb-2">
              <Label htmlFor="Username" value="Username" />
            </div>
            <TextInput
              id="Username"
              type="text"
              name="name"
              placeholder="Oliviar Naing"
              defaultValue={EditUserData?.name ?? ""}
              required
            />
          </div>
          <div>
            <div className="block mb-2">
              <Label htmlFor="email" value="Your email" />
            </div>
            <TextInput
              key={1}
              id="email"
              type="email"
              name="email"
              placeholder="name@flowbite.com"
              defaultValue={EditUserData?.email ?? ""}
              required
            />
          </div>
          <div>
            <div className="block mb-2">
              <Label htmlFor="phone" value="Phone" />
            </div>
            <TextInput
              id="phone"
              type="tel"
              name="phone"
              placeholder="09xxxxxxxx"
              defaultValue={EditUserData?.phone ?? ""}
              required
            />
          </div>
          <div>
            <div className="block mb-2">
              <Label htmlFor="address" value="Address" />
            </div>
            <TextInput
              id="address"
              type="text"
              name="address"
              placeholder="Address"
              defaultValue={EditUserData?.address ?? ""}
            />
          </div>

          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <form method="dialog">
              <button type="submit" className="w-full btn btn-secondary">
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default EditModal;
