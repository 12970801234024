import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { LuListRestart } from "react-icons/lu";
import EditModal from "../../components/modals/userManagement/user-list/edit";
import CreateModal from "../../components/modals/userManagement/user-list/create";
import ResetPasswordModal from "../../components/modals/userManagement/user-list/reset-password";
import DeleteModal from "../../components/modals/userManagement/user-list/delete";
import fireToast from "../../components/fireToast";
import Formatter from "../../utils/formater";
import { useCookies } from "react-cookie";

export default function ViewUserList() {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  const [data, setData] = useState([]);
  const [PrintID, setPrintID] = useState(null);
  const [EditUserData, setEditUserData] = useState(null);
  const [PasswordResetUserData, setPasswordResetUserData] = useState(null);
  const [UserGroup, setUserGroup] = useState([]);
  const [BranchList, setBranchList] = useState([]);
  const [DeleteDataId, setDeleteDataId] = useState(null);
  const [ClearForm, setClearForm] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/get-user-group`);

      if (response.status === 200) {
        var result = response.data?.data;
        setUserGroup(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    try {
      const response = await axios.get(`/api/v1/user-management/list`);

      if (response.status === 200) {
        setData(response.data?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    try {
      const response = await axios.get(`/api/v1/user-management/branch-list`);

      if (response.status === 200) {
        setBranchList(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    axios
      .post("/api/v1/user-management/add", formData)
      .then((response) => {
        if (response.status === 201) {
          fireToast(response.data.message, "success");
          setClearForm(true);
        }
      })
      .catch((error) => {
        // Handle errors
        console.log("Error :", error);
        fireToast(error.response.data.message, "error");
      })
      .finally(() => {
        document.getElementById("user-list-create-modal").close();
        fetchData();
      });
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    axios
      .put(`/api/v1/user-management/update/${EditUserData.id}`, data)
      .then((response) => {
        if (response.status === 200) {
          fireToast(response.data.message, "success");
        }
      })
      .catch((error) => {
        // Handle errors
        console.log("Error :", error);
        fireToast(error.response.data.message, "error");
      })
      .finally(() => {
        document.getElementById("user-list-edit-modal").close();
        fetchData();
      });
  };

  const handlePasswordResetSubmit = (e) => {
    e.preventDefault();
    // Create a FormData object to send the form data
    const formData = new FormData(e.target);


    // Send the formData to the backend API using Axios
    axios
      .put(
        `/api/v1/user-management/reset-password/${PasswordResetUserData.id}`,
        formData
      )
      .then((response) => {
        if (response.status === 200) {
          // Handle success
          fireToast(response.data.message, "success");
        }
      })
      .catch((error) => {
        // Handle errors
        console.log("Error :", error);
        fireToast(error.response.data.message, "error");
      })
      .finally((a) => {
        document.getElementById("user-list-reset-password-modal").close();
        setPasswordResetUserData({}); // Close the modal after successful submission
        fetchData();
      });
  };

  const handleDeleteFunction = async (dataId) => {
    try {
      const response = await axios.delete(
        `/api/v1/user-management/suspend-user/${dataId}`
      );

      if (response.status === 200) {
        setDeleteDataId(null);
        fireToast(response.data.message, "success");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
    document.getElementById("user-list-delete-modal").close();
    fetchData();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (PrintID !== null) {
      const print_data = data[PrintID];
      const print_format = {
        customer: print_data?.voucher_data?.customer?.name,
        wallet: print_data?.voucher_data?.wallet?.name,
        product_data: print_data?.voucher_data?.product_data,
        total_amount: print_data?.total_amount,
        datetime: print_data?.datetime,
        voucher_code: print_data?.voucher_code,
      };

      const encode_data = btoa(JSON.stringify(print_format));

      window.open(`/print/${encode_data}`, "_blank");
      setPrintID(null);
      // const myFunction = () => {
      //   setPrintID(null);
      // };

      // const timeoutId = setTimeout(myFunction, 4000);

      // return () => {
      //   clearTimeout(timeoutId);
      // };

      //   location.reload();
    }
  }, [PrintID]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "phone",
        header: "Phone",
      },
      {
        accessorKey: "group_name",
        header: "Group Name",
        Cell: ({ row }) => (
          <span className="text-xs font-semibold text-green-600 bg-green-100 border-green-600 badge">
            {row.original.group_name}
          </span>
        ),
      },
      {
        accessorKey: "last_login_date",
        header: "Last Login Date",
        Cell: ({ row }) => (
          <div className="flex text-xs flex-nowrap text-nowrap">
            {Formatter(row.original.last_login_date, "datetime")}
          </div>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        {storedData?.permission.user_mgmt.includes(3) && (
          <Tooltip label="Edit">
            <ActionIcon
              onClick={() => [
                document.getElementById("user-list-edit-modal").showModal(),
                setEditUserData(row["original"]),
              ]}
            >
              <IconEdit className="text-yellow-500" />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.user_mgmt.includes(4) && (
          <Tooltip label="Delete">
            <ActionIcon
              onClick={() => [
                document.getElementById("user-list-delete-modal").showModal(),
                setDeleteDataId(row.original),
              ]}
            >
              <IconTrash className="text-red-400 " />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.user_mgmt.includes(3) && (
          <Tooltip label="Reset Password">
            <ActionIcon
              onClick={() => [
                document
                  .getElementById("user-list-reset-password-modal")
                  .showModal(),
                setPasswordResetUserData(row["original"]),
              ]}
            >
              <LuListRestart className="text-2xl text-blue-400" />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),
    enableExpanding: false,
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <div>
      <MantineReactTable table={table} />
      {/* add */}
      <CreateModal
        handleSubmit={handleSubmit}
        UserGroup={UserGroup}
        BranchList={BranchList}
        ClearForm={ClearForm}
      />
      {/* edit */}
      <EditModal
        handleEditSubmit={handleEditSubmit}
        EditUserData={EditUserData}
        UserGroup={UserGroup}
        BranchList={BranchList}
      />

      {/* password reset */}
      <ResetPasswordModal
        handlePasswordResetSubmit={handlePasswordResetSubmit}
        userName={PasswordResetUserData?.name}
      />

      {/* delete modal */}
      <DeleteModal
        DeleteDataId={DeleteDataId}
        handleDeleteFunction={handleDeleteFunction}
      />
    </div>
  );
}
