import axios from "axios";
import { Label, TextInput, Textarea } from "flowbite-react";
import { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import clearForm from "../../utils/clearForm";
import defaultImage from "../../../image/default_product-768x768.jpg";
import fireToast from "../../components/fireToast";
import { toast } from "react-toastify";

function EditProduct({ pId, fetchData }) {
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image file
  const imageRef = useRef(null);
  // const { pId } = useParams();
  var cToken = "";

  const [BrandList, setBrandList] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [ProductVariantOptionList, setProductVariantOptionList] = useState([]);
  const [VariantOptionList, setVariantOptionList] = useState([]);
  const [ProductData, setProductData] = useState({});
  const [TheSelector, setTheSelector] = useState({
    brand: "",
    category: "",
    sub_category: "",
  });

  const handleCameraButtonClick = () => {
    imageRef.current.click();
  };

  const handleImageChange = (event) => {
    // Update the selectedImage state with the selected file
    setSelectedImage(event.target.files[0]);
  };

  const handleImageRemove = () => {
    // Clear the selected image when the remove button is clicked
    setSelectedImage(null);
  };

  const getProductBrandList = async () => {
    const { data } = await axios.get(`/api/product-brand`);
    const brandList = data.data.map((brand) => ({
      value: brand,
      label: brand,
    }));
    setBrandList(brandList);
  };

  function getPreviousData() {
    if (!pId) {
      return;
    }
    axios
      .get(`/api/v1/product-management/edit/${pId}`)
      .then(({ data, status }) => {
        if (status === 200) {
          setProductData(data.pdata);
          getProductSubCategoryList(data.pdata.category_name);
          data.variations.forEach(({ name }) => {
            if (data.pdata[name].length > 0) {
              setVariantOptionList((list) => [...list, name]);
            }
          });
        } else {
          fireToast("Failed to fetch product data", "error");
        }
      })
      .catch((error) => {
        if (error.response) {
          fireToast(error.response.data.message, "error");
        } else if (error.request) {
          fireToast("Request failed", "error");
        } else {
          fireToast("Something went wrong", "error");
        }
      });
  }

  function getProductCategoryList() {
    cToken = axios.CancelToken.source();
    axios
      .get("/api/product-category", { cancelToken: cToken.token })
      .then(({ data: { data: categoryList } }) => {
        setCategoryList(
          categoryList.map((name) => ({ value: name, label: name }))
        );
        cToken = null;
      })
      .catch((error) => {
        if (error.response) {
          fireToast(error.response.data.message, "error");
        } else if (error.request) {
          fireToast("Request failed", "error");
        } else {
          fireToast("Something went wrong", "error");
        }
      });
  }

  function getProductSubCategoryList(categoryName) {
    axios
      .get(`/api/product-sub-category`, {
        params: { catName: categoryName },
      })
      .then(({ data: { data: subCategoryList } }) => {
        setSubCategoryList(
          subCategoryList.map((name) => ({ value: name, label: name }))
        );
      })
      .catch((error) => {
        if (error.response) {
          fireToast(error.response.data.message, "error");
        } else if (error.request) {
          fireToast("Request failed", "error");
        } else {
          fireToast("Something went wrong", "error");
        }
      });
  }

  function getProductVariationList() {
    axios
      .get(`/api/product-variation`)
      .then(({ data: { data: variationList } }) => {
        setProductVariantOptionList(variationList);
      })
      .catch((error) => {
        if (error.response) {
          fireToast(error.response.data.message, "error");
        } else if (error.request) {
          fireToast("Request failed", "error");
        } else {
          fireToast("Something went wrong", "error");
        }
      });
  }

  const notify = () =>
    toast.info("Refreshing...", {
      autoClose: false,
      position: "top-center",
    });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("product-image", selectedImage);

    axios
      .post(`/api/v1/product-management/edit/${pId}`, formData)
      .then((response) => {
        if (response.status === 200) {
          fireToast(response.data.message, "success");
          setSelectedImage(null);
          notify();
        }
      })
      .catch((error) => {
        fireToast(error.response.data.message, "error");
      })
      .finally(() => {
        document.getElementById("product-list-edit-modal").close();
        fetchData();
      });
  };

  useEffect(() => {
    getPreviousData();
    getProductBrandList();
    getProductVariationList();
    getProductCategoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pId]);

  return (
    <form
      className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form"
      onSubmit={handleFormSubmit}
    >
      <div className="flex flex-col grid-cols-2 gap-4">
        <div className="flex flex-col items-center w-full gap-4">
          <div className="w-[10rem] h-[10rem] rounded-md overflow-hidden relative">
            {ProductData?.product_image == null ? (
              <img
                className="object-cover w-full "
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : defaultImage
                }
                alt={ProductData.name}
              />
            ) : (
              <img
                className="object-cover w-full "
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : "/" + ProductData?.product_image
                }
                alt={ProductData.name}
              />
            )}
            <div className="absolute bottom-0 flex justify-center w-full bg-opacity-50 bg-slate-400">
              {!selectedImage ? (
                <div className="flex gap-8">
                  <button
                    type="button"
                    className="p-2 rounded-full text-slate-800 "
                    onClick={handleCameraButtonClick}
                  >
                    <i className="text-lg fa-light fa-camera"></i>
                  </button>
                </div>
              ) : (
                <div className="flex gap-8">
                  <button
                    type="button"
                    className="p-2 text-white rounded-full "
                    onClick={handleCameraButtonClick}
                  >
                    <i className="text-lg text-yellow-400 fa-light fa-pen-to-square"></i>
                  </button>
                  <button
                    type="button"
                    className="p-2 text-white rounded-full "
                    onClick={handleImageRemove}
                  >
                    <i className="text-lg text-red-600 fa-regular fa-trash-xmark"></i>
                  </button>
                </div>
              )}

              <input
                ref={imageRef}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="absolute opacity-0 -z-30"
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-4">
            <div>
              <div className="block mb-2">
                <Label htmlFor="productName" value="Product Name *" />
              </div>
              <TextInput
                id="productName"
                name="productName"
                sizing="md"
                type="text"
                defaultValue={ProductData.name}
                placeholder="Enter product name"
              />
            </div>
            <div>
              <div className="block mb-2">
                <Label htmlFor="brand" value={`Brand * `} />
              </div>
              <Select
                id="brand"
                name="brand"
                className="croni-select-container"
                classNamePrefix="croni-select"
                options={BrandList}
                isClearable={true}
                value={
                  TheSelector.brand
                    ? BrandList.find(
                        (brand) => brand.value === TheSelector.brand
                      )
                    : BrandList.find(
                        (brand) => brand.value === ProductData["brand_name"]
                      )
                }
                onChange={(e) => {
                  getProductCategoryList();
                  setTheSelector((prevData) => ({
                    ...prevData,
                    brand: e?.value || "",
                  }));
                }}
              />
            </div>
            <div>
              <div className="block mb-2">
                <Label htmlFor="category" value="Category *" />
              </div>
              <Select
                name="category"
                id="category"
                className="croni-select-container"
                classNamePrefix="croni-select"
                options={CategoryList}
                isClearable={true}
                required
                value={
                  TheSelector.category
                    ? CategoryList.find(
                        (brand) => brand.value === TheSelector.category
                      )
                    : CategoryList.find(
                        (brand) => brand.value === ProductData.category_name
                      )
                }
                onChange={(e) => {
                  e
                    ? getProductSubCategoryList(e.value)
                    : setSubCategoryList([]);
                  setTheSelector((prevData) => ({
                    ...prevData,
                    category: e?.value || "",
                  }));
                }}
              />
            </div>
            {SubCategoryList.length > 0 && (
              <div>
                <div className="block mb-2">
                  <Label htmlFor="sub_category" value="Sub Category *" />
                </div>
                <Select
                  name="sub_category"
                  id="sub_category"
                  className="croni-select-container"
                  classNamePrefix="croni-select"
                  options={SubCategoryList}
                  isClearable={true}
                  value={
                    TheSelector.sub_category
                      ? SubCategoryList.find(
                          (brand) => brand.value === TheSelector.sub_category
                        )
                      : SubCategoryList.find(
                          (brand) =>
                            brand.value === ProductData.subcategory_name
                        )
                  }
                  onChange={(e) => {
                    setTheSelector((prevData) => ({
                      ...prevData,
                      sub_category: e?.value || "",
                    }));
                  }}
                />
              </div>
            )}
            <div id="textarea">
              <div className="block mb-2">
                <Label htmlFor="description" value="Description" />
              </div>
              <Textarea
                id="description"
                name="description"
                placeholder="Enter your description"
                defaultValue={ProductData.description}
                rows={8}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col w-full gap-4">
            <h5>Variant Options</h5>
            <div className="max-sm:flex max-sm:flex-wrap lg:grid w-full grid-cols-3 mb-[5rem] gap-6">
              {ProductVariantOptionList.map((e, i) => (
                <div key={i} className="flex flex-col gap-4">
                  <ToggleSwitch
                    checked={VariantOptionList.includes(e.id)}
                    label={e.name}
                    onClick={() => {
                      // Check if the option is already in VariantOptionList
                      const optionIndex = VariantOptionList.indexOf(e.id);
                      const isOptionSelected = optionIndex !== -1;

                      if (isOptionSelected) {
                        // If it's already selected, remove it
                        setVariantOptionList((oldValue) =>
                          oldValue.filter((id) => id !== e.id)
                        );
                      } else {
                        // If it's not selected, add it
                        setVariantOptionList((oldValue) => [...oldValue, e.id]);
                      }
                    }}
                  />
                  {VariantOptionList.includes(e.id) && (
                    <Select
                      name={`var-${e.id}`}
                      className="croni-select-container"
                      classNamePrefix="croni-select"
                      options={e.options.map((option) => ({
                        value: option,
                        label: option,
                      }))}
                      defaultValue={ProductData[e.name]}
                      isMulti
                    />
                  )}
                </div>
              ))}
            </div>
          </div> */}

      <div className="grid justify-end grid-cols-2 gap-2 mt-2">
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
        <form method="dialog">
          <button
            type="submit"
            className="w-full btn btn-secondary"
            onClick={() => clearForm()}
          >
            Cancel
          </button>
        </form>
      </div>
    </form>
  );
}

export default memo(EditProduct);
