import { useEffect, useMemo, useRef, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import axios from "axios";
import { IconEdit } from "@tabler/icons-react";
import { IconTrash } from "@tabler/icons-react";
import { useCookies } from "react-cookie";
import fireToast from "../../components/fireToast";
import CreateModal from "../../components/modals/report/expense-management/create";
import clearForm from "../../utils/clearForm";
import DeleteModal from "../../components/modals/report/expense-management/delete";
import EditModal from "../../components/modals/report/expense-management/edit";
import Formatter from "../../utils/formater";

function ExpenseManagement() {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  const [SelectData, setSelectData] = useState({});
  const [data, setData] = useState([]);
  const [EditData, setEditData] = useState({});
  const [DeleteData, setDeleteData] = useState({});

  const addRecordRef = useRef(null);
  const updateRecordRef = useRef(null);

  const [TheSelector, setTheSelector] = useState({
    expense_category: "",
    wallet: "",
  });

  const fetchSelectData = async () => {
    try {
      const response = await axios.get(`/api/v1/expenses/props`);
      if (response.status === 200) {
        setSelectData(response.data);
      }
    } catch (error) {
      console.error(error);
      fireToast(error.response.data.message, "error");
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(addRecordRef.current);
    axios
      .post("/api/v1/expenses/add", formData)
      .then((response) => {
        fireToast(response.data.message, "success");
        clearForm();
      })
      .catch((error) => fireToast(error.response.data.error, "error"))
      .finally(() => {
        fetchData();
        document.getElementById("expense-management-create-modal").close();
      });
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(updateRecordRef.current);
    axios
      .put(`/api/v1/expenses/edit/${EditData.id}`, formData)
      .then((response) => {
        fireToast(response.data.message, "success");
        clearForm();
      })
      .catch((error) => fireToast(error.response.data.error, "error"))
      .finally(() => {
        setEditData({});
        fetchData();
        document.getElementById("expense-management-edit-modal").close();
      });
  };

  const handleDeleteFormSubmit = () => {
    const walletData = SelectData?.wallet?.find(
      (wallet) => wallet.label === DeleteData.wallet
    );
    const formData = new FormData();
    formData.append("wallet_id", walletData.value);

    axios
      .post(`/api/v1/expenses/delete/${DeleteData.id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          fireToast(response.data.message, "success");
        }
      })
      .catch((error) => {
        console.error(error);
        fireToast(error.response.data.error, "error");
      })
      .finally((a) => {
        setDeleteData({});
        fetchData();
        document.getElementById("expense-management-delete-modal").close();
      });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "description",
        header: "Note",
      },
      {
        accessorKey: "person",
        header: "By person",
      },
      {
        accessorKey: "expense_category",
        header: "Expense Category",
      },
      {
        accessorKey: "wallet",
        header: "Wallet",
      },
      {
        accessorKey: "amount",
        header: "Amount",
        Cell: ({ row }) => (
          <span>Ks {Formatter(parseFloat(row.original.amount), "price")}</span>
        ),
      },
      {
        accessorKey: "datetime",
        header: "Datetime",
        Cell: ({ row }) => (
          <div className="flex text-xs flex-nowrap text-nowrap">
            {Formatter(row.original.datetime, "datetime")}
          </div>
        ),
      },
    ],
    []
  );

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/expenses");
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
  };

  useEffect(() => {
    fetchData();
    fetchSelectData();
  }, []);

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        {storedData?.permission.expense_mgmt.includes(3) && (
          <Tooltip label="Edit">
            <ActionIcon
              onClick={() => [
                setEditData(row.original),
                document
                  .getElementById("expense-management-edit-modal")
                  .showModal(),
              ]}
            >
              <IconEdit className="text-yellow-500" />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.expense_mgmt.includes(4) && (
          <Tooltip label="Delete">
            <ActionIcon
              onClick={() => [
                setDeleteData(row.original),
                document
                  .getElementById("expense-management-delete-modal")
                  .showModal(),
              ]}
            >
              <IconTrash className="text-red-400 " />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),
    enableEditing: true,
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableExpanding: false,
    columns,
    data,
  });

  return (
    <>
      <MantineReactTable table={table} />
      {/* add model */}
      <CreateModal
        handleFormSubmit={handleFormSubmit}
        addRecordRef={addRecordRef}
        SelectData={SelectData}
      />
      {/* edit model */}
      <EditModal
        handleEditFormSubmit={handleEditFormSubmit}
        updateRecordRef={updateRecordRef}
        SelectData={SelectData}
        TheSelector={TheSelector}
        setTheSelector={setTheSelector}
        EditData={EditData}
      />
      {/* delete modal */}
      <DeleteModal
        handleDeleteSubmit={handleDeleteFormSubmit}
        DeleteDataId={DeleteData}
      />
    </>
  );
}

export default ExpenseManagement;
