import { useCookies } from "react-cookie";
import ToolboxSection from "../../../toolbox-button-group";

const AddButton = () => {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  return (
    storedData?.permission.group_mgmt.includes(1) && (
      <ToolboxSection>
        <button
          onClick={() =>
            document.getElementById("user-group-list-create-modal").showModal()
          }
          className="btn btn-primary max-sm:w-full"
        >
          <span className="flex items-center gap-2">
            <i className="fa-solid fa-plus"></i>
            <p>New Group</p>
          </span>
        </button>
      </ToolboxSection>
    )
  );
};

export default AddButton;
