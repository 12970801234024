import { useNavigate } from "react-router-dom";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useCookies } from "react-cookie";
import Formatter from "../../utils/formater";
import DeleteModal from "../../components/modals/product/purchase-list/delete";
import fireToast from "../../components/fireToast";

function ViewPurchaseList() {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];

  const [data, setData] = useState([]);
  const [DeleteDataId, setDeleteDataId] = useState(null);

  const router = useNavigate();
  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/v1/purchase-list`);

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      fireToast(error.response.data.message, "error");
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (pID) => {
    try {
      const response = await axios.delete(
        `/api/v1/purchase-management/delete/${pID}`
      );

      if (response.status == 200) {
        fireToast(response.data.message, "success");
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting product variation:", error);
      fireToast(error.response.data.message, "error");
    } finally {
      setDeleteDataId(null);
      document.getElementById("purchase-list-delete-modal").close();
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "datetime", //access nested data with dot notation
        header: "Purchase Date",
        Cell: ({ row }) => moment(row.original.datetime).format("YYYY-MM-DD"),
      },
      {
        accessorKey: "id", //access nested data with dot notation
        header: "Purchase ID",
      },
      {
        accessorKey: "sku", //access nested data with dot notation
        header: "Product Code",
      },
      {
        accessorKey: "product_name", //access nested data with dot notation
        header: "Product Name",
      },
      {
        accessorKey: "supplier", //access nested data with dot notation
        header: "Supplier",
      },
      {
        accessorKey: "purchaser", //access nested data with dot notation
        header: "Purchaser",
      },

      {
        accessorKey: "location", //access nested data with dot notation
        header: "Location",
      },
      {
        accessorKey: "status", //access nested data with dot notation
        header: "Paid/Unpaid",
        Cell: ({ row }) =>
          row.original.status == "unpaid" ? (
            <span className="text-xs font-semibold text-red-600 bg-red-100 border-red-600 badge">
              {row.original.status}
            </span>
          ) : (
            <span className="text-xs font-semibold text-green-600 bg-green-100 border-green-600 badge">
              {row.original.status}
            </span>
          ),
      },
      {
        accessorKey: "purchase_price", //access nested data with dot notation
        header: "Purchase Price",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.purchase_price), "price")}
          </span>
        ),
      },
      {
        accessorKey: "qty", //access nested data with dot notation
        header: "Qty",
      },
      {
        accessorKey: "total_amount", //access nested data with dot notation
        header: "Net Purchase Price",
        Cell: ({ row }) => (
          <span>
            Ks {Formatter(parseFloat(row.original.total_amount), "price")}
          </span>
        ),
      },
      {
        accessorKey: "note", //access nested data with dot notation
        header: "Purchase Note",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        {storedData?.permission.purchase_mgmt.includes(3) && (
          <Tooltip label="Edit">
            {/* <ActionIcon onClick={() => router(`/product-edit/${row.original.id}`)}> */}
            <ActionIcon
              onClick={() =>
                router(
                  `/product-management/purchase-list/edit/${row.original.id}`
                )
              }
            >
              <IconEdit className="text-yellow-500" />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.purchase_mgmt.includes(4) && (
          <Tooltip label="Delete">
            <ActionIcon
              onClick={() => [
                setDeleteDataId(row.original),
                document
                  .getElementById("purchase-list-delete-modal")
                  .showModal(),
              ]}
            >
              <IconTrash className="text-red-400 " />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),

    enableExpanding: false,
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <div>
      <MantineReactTable table={table} />
      {/* delete modal */}

      <DeleteModal
        DeleteDataId={DeleteDataId}
        handleDeleteFunction={handleDelete}
      />
    </div>
  );
}

export default ViewPurchaseList;
