import { Label, TextInput, Textarea } from "flowbite-react";
import clearForm from "../../../../utils/clearForm";

const EditModal = ({ handleEditFormSubmit, EditData }) => {
  const clearAll = () => {
    clearForm();
  };
  return (
    <dialog
      id="supplier-list-edit-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Edit Supplier</h3>
          <form method="dialog">
            <button
              className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
              onClick={() => clearAll()}
            >
              ✕
            </button>
          </form>
        </div>

        <form
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form"
          onSubmit={handleEditFormSubmit}
        >
          <div className="flex flex-col gap-1">
            <div>
              <div className="block mb-2">
                <Label htmlFor="name" value="Supplier Name*" />
              </div>
              <TextInput
                type="text"
                id="name"
                name="name"
                placeholder="Enter supplier name"
                required
                defaultValue={EditData.name}
              />
            </div>
            <div>
              <div className="block mb-2">
                <Label htmlFor="email" value="Email" />
              </div>
              <TextInput
                type="email"
                id="email"
                name="email"
                placeholder="Enter email address"
                required
                defaultValue={EditData.email}
              />
            </div>
            <div>
              <div className="block mb-2">
                <Label htmlFor="phone" value="Phone" />
              </div>
              <TextInput
                type="phone"
                id="phone"
                name="phone"
                placeholder="09xxxxxxxx"
                required
                defaultValue={EditData.phone_number}
              />
            </div>
            <div>
              <div className="block mb-2">
                <Label htmlFor="address" value="Address" />
              </div>
              <Textarea
                id="address"
                name="address"
                placeholder="Enter address"
                required
                rows={4}
                defaultValue={EditData.address}
              />
            </div>
            <div>
              <div className="block mb-2">
                <Label htmlFor="note" value="Note" />
              </div>
              <Textarea
                id="note"
                name="note"
                placeholder="Write something..."
                rows={4}
                defaultValue={EditData.note}
              />
            </div>
          </div>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <form method="dialog">
              <button
                type="submit"
                className="w-full btn btn-secondary"
                onClick={() => clearAll()}
              >
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default EditModal;
