import { Label, TextInput } from "flowbite-react";
import clearForm from "../../../../utils/clearForm";

const EditModal = ({
  handleEditFormSubmit,
  EditData,
  selectedImage,
  imageRef,
  handleImageChange,
  handleCameraButtonClick,
  handleImageRemove,
}) => {
  function clearAll() {
    clearForm();
  }

  return (
    <dialog
      id="brand-edit-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Edit Brand</h3>
          <form method="dialog">
            <button
              className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
              onClick={clearAll}
            >
              ✕
            </button>
          </form>
        </div>
        <form
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form"
          onSubmit={handleEditFormSubmit}
        >
          <center>
            <div className="w-[10rem] h-[10rem] rounded-md overflow-hidden relative">
              <img
                className="object-cover w-full "
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : "/" + EditData.image
                }
                alt="Brand"
              />
              <div className="absolute bottom-0 flex justify-center w-full bg-opacity-50 bg-slate-400">
                {!selectedImage ? (
                  <div className="flex gap-8">
                    <button
                      type="button"
                      className="p-2 rounded-full text-slate-800 "
                      onClick={handleCameraButtonClick}
                    >
                      <i className="text-lg fa-light fa-camera"></i>
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-8">
                    <button
                      type="button"
                      className="p-2 text-white rounded-full "
                      onClick={handleCameraButtonClick}
                    >
                      <i className="text-lg text-yellow-400 fa-light fa-pen-to-square"></i>
                    </button>
                    <button
                      type="button"
                      className="p-2 text-white rounded-full "
                      onClick={handleImageRemove}
                    >
                      <i className="text-lg text-red-600 fa-regular fa-trash-xmark"></i>
                    </button>
                  </div>
                )}

                <input
                  ref={imageRef}
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="absolute opacity-0 -z-30"
                />
              </div>
            </div>
          </center>
          <div>
            <div className="block mb-2">
              <Label htmlFor="brandName" value="Brand Name" />
            </div>
            <TextInput
              id="brandName"
              name="brandName"
              defaultValue={EditData.name}
              required
            />
          </div>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <form method="dialog">
              <button
                type="submit"
                className="w-full btn btn-secondary"
                onClick={clearAll}
              >
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default EditModal;
