import { Label, TextInput, Textarea } from "flowbite-react";
import moment from "moment";
import { useEffect, useState } from "react";
import Select from "react-select";
import clearForm from "../../../../utils/clearForm";

const EditModal = ({ handleEditFormSubmit, EditData }) => {
  function clearAll() {
    clearForm();
  }

  return (
    <dialog
      id="wallet-edit-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Edit Wallet</h3>
          <form method="dialog">
            <button
              className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
              onClick={clearAll}
            >
              ✕
            </button>
          </form>
        </div>

        <form
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form"
          onSubmit={handleEditFormSubmit}
        >
          <div>
            <div className="block mb-2">
              <Label htmlFor="title" value="Wallet Name" />
            </div>
            <TextInput
              id="name"
              name="name"
              defaultValue={EditData.name}
              className="mb-4"
              required
            />
            <div className="block mb-2">
              <Label htmlFor="total_amount" value="Total Amount" />
            </div>
            <TextInput
              id="total_amount"
              name="total_amount"
              defaultValue={EditData.total_amount}
              required
            />
          </div>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <form method="dialog">
              <button
                type="submit"
                className="w-full btn btn-secondary"
                onClick={clearAll}
              >
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default EditModal;
