import { useEffect, useMemo, useRef, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import axios from "axios";
import { IconEdit } from "@tabler/icons-react";
import { IconTrash } from "@tabler/icons-react";
import fireToast from "../../components/fireToast";
import defaultImage from "../../../image/default_product-768x768.jpg";
import CreateModal from "../../components/modals/setting/brandList/create";
import EditModal from "../../components/modals/setting/brandList/edit";
import DeleteModal from "../../components/modals/setting/brandList/delete";
import clearForm from "../../utils/clearForm";

const BrandList = () => {
  const imageRef = useRef(null);
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image file
  const [EditData, setEditData] = useState({});
  const [DeleteDataId, setDeleteDataId] = useState(null);

  const handleCameraButtonClick = () => {
    imageRef.current.click();
  };

  const handleImageChange = (event) => {
    // Update the selectedImage state with the selected file
    setSelectedImage(event.target.files[0]);
  };

  const handleImageRemove = () => {
    // Clear the selected image when the remove button is clicked
    setSelectedImage(null);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    formData.append("brandLogo", selectedImage);

    axios
      .post("/api/v1/brand/add-brand", formData)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          console.log("Brand added successfully");
          fireToast("Brand added successfully", "success");
        }
      })
      .catch((error) => {
        console.log("Error adding brand:", error);
        fireToast(error.response.data.message, "error");
      })
      .finally((a) => {
        fetchData();
        clearForm();
        setSelectedImage(null);
        document.getElementById("brand-create-modal").close();
      });
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    formData.append("brandLogo", selectedImage);
    axios
      .post(`/api/v1/brand/edit-brand/${EditData.id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          console.log("Brand edited successfully");
          fireToast("Brand edited successfully", "success");
        }
      })
      .catch((error) => {
        console.log("Error editing brand:", error);
        fireToast(error.response.data.message, "error");
      })
      .finally((a) => {
        setEditData({}); // Close the modal after successful submission
        fetchData();
        clearForm();
        setSelectedImage(null);
        document.getElementById("brand-edit-modal").close();
      });
  };

  const columns = useMemo(
    () => [
      {
        enableEditing: false,
        header: "Image",
        Cell: ({ row }) => (
          <div className="w-20 overflow-hidden border rounded-md aspect-square bg-slate-50">
            {row.original.image ? (
              <img
                alt={row.original.name}
                className="object-cover w-full border rounded-md aspect-square bg-slate-50"
                src={"/" + row.original.image}
              />
            ) : (
              <img
                alt={row.original.name}
                className="object-cover w-full border rounded-md aspect-square bg-slate-50"
                src={defaultImage}
              />
            )}
          </div>
        ),
      },
      {
        accessorKey: "name", //access nested data with dot notation
        header: "Title",
      },
    ],
    []
  );

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/brand");
      if (response.status === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.error, "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //DELETE action

  const handleDeleteFunction = async (dataId) => {
    const formData = new FormData();
    formData.append("brandID", dataId);
    try {
      const response = await axios.post("/api/v1/brand/delete-brand", formData);
      if (response.status === 200) {
        setDeleteDataId(null);
        fireToast("Brand deleted successfully", "success");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.error, "error");
    }
    fetchData();
    document.getElementById("brand-delete-modal").close();
  };

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => [
              setEditData(row.original),
              document.getElementById("brand-edit-modal").showModal(),
            ]}
          >
            <IconEdit className="text-yellow-500" />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Delete">
          <ActionIcon
            onClick={() => [
              setDeleteDataId(row.original),
              document.getElementById("brand-delete-modal").showModal(),
            ]}
          >
            <IconTrash className="text-red-400 " />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    enableEditing: true,
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableExpanding: false,
    columns,
    data,
  });

  return (
    <>
      <MantineReactTable table={table} />
      {/* add model */}
      <CreateModal
        handleFormSubmit={handleFormSubmit}
        selectedImage={selectedImage}
        imageRef={imageRef}
        handleImageChange={handleImageChange}
        handleCameraButtonClick={handleCameraButtonClick}
        handleImageRemove={handleImageRemove}
      />
      {/* edit model */}
      <EditModal
        handleEditFormSubmit={handleEditFormSubmit}
        selectedImage={selectedImage}
        imageRef={imageRef}
        handleImageChange={handleImageChange}
        handleCameraButtonClick={handleCameraButtonClick}
        handleImageRemove={handleImageRemove}
        EditData={EditData}
      />
      {/* delete modal */}
      <DeleteModal
        handleDeleteFunction={handleDeleteFunction}
        DeleteDataId={DeleteDataId}
      />
    </>
  );
};

export default BrandList;
