import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import CreateModal from "../../components/modals/userManagement/user-group-list/create";
import DeleteModal from "../../components/modals/userManagement/user-group-list/delete";
import EditModal from "../../components/modals/userManagement/user-group-list/edit";
import { useCookies } from "react-cookie";
import fireToast from "../../components/fireToast";

export default function ViewUserGroupList() {
  const [cookies] = useCookies();
  const storedData = cookies["userSession"];
  const [data, setData] = useState([]);
  const [PermissionData, setPermissionData] = useState([]);
  const [PrintID, setPrintID] = useState(null);
  const [EditGroupData, setEditGroupData] = useState(null);
  const [DeleteDataId, setDeleteDataId] = useState(null);

  // const handleCheckboxChange = (value) => {
  //   // Check if the value is already in the list
  //   const isSelected = SelectedPermissionList.includes(value);

  //   // If checked, add the value to the list; otherwise, remove it
  //   if (isSelected) {
  //     setSelectedPermissionList((prevList) =>
  //       prevList.filter((item) => item !== value)
  //     );
  //   } else {
  //     setSelectedPermissionList((prevList) => [...prevList, value]);
  //   }
  // };

  const fetchData = async () => {
    try {
      const { data } = await axios.get("/api/v1/permission/list");
      setPermissionData(data?.data);
    } catch (error) {
      console.error(error);
      fireToast(error.response.data.message, "error");
    }

    try {
      const { data } = await axios.get("/api/v1/group-management/list");
      setData(data?.data);
    } catch (error) {
      console.error(error);
      fireToast(error.response.data.message, "error");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    axios
      .post("/api/v1/group-management/add", formData)
      .then((response) => {
        if (response.status === 200) {
          fireToast(response.data.message, "success");
        }
      })
      .catch((error) => fireToast(error.response.data.message, "error"))
      .finally(() => {
        document.getElementById("user-group-list-create-modal").close();
        fetchData();
      });
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    axios
      .put(`/api/v1/group-management/update/${EditGroupData.id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          fireToast(response.data.message, "success");
        }
      })
      .catch((error) => fireToast(error.response.data.message, "error"))
      .finally(() => {
        document.getElementById("user-group-list-edit-modal").close();
        setEditGroupData({});
        fetchData();
      });
  };

  const handleDeleteFunction = async (dataId) => {
    try {
      const response = await axios.delete(
        `/api/v1/group-management/delete/${dataId}`
      );

      if (response.status === 200) {
        setDeleteDataId(null);
        fireToast(response.data.message, "success");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fireToast(error.response.data.message, "error");
    }
    document.getElementById("user-group-list-delete-modal").close();
    fetchData();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (PrintID !== null) {
      const print_data = data[PrintID];
      const print_format = {
        customer: print_data?.voucher_data?.customer?.name,
        wallet: print_data?.voucher_data?.wallet?.name,
        product_data: print_data?.voucher_data?.product_data,
        total_amount: print_data?.total_amount,
        datetime: print_data?.datetime,
        voucher_code: print_data?.voucher_code,
      };

      const encode_data = btoa(JSON.stringify(print_format));

      window.open(`/print/${encode_data}`, "_blank");
      setPrintID(null);
      // const myFunction = () => {
      //   setPrintID(null);
      // };

      // const timeoutId = setTimeout(myFunction, 4000);

      // return () => {
      //   clearTimeout(timeoutId);
      // };

      //   location.reload();
    }
  }, [PrintID]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        {storedData?.permission.group_mgmt.includes(3) && (
          <Tooltip label="Edit">
            <ActionIcon
              onClick={() => [
                document
                  .getElementById("user-group-list-edit-modal")
                  .showModal(),
                setEditGroupData(row["original"]),
              ]}
            >
              <IconEdit className="text-yellow-500" />
            </ActionIcon>
          </Tooltip>
        )}
        {storedData?.permission.group_mgmt.includes(4) && (
          <Tooltip label="Delete">
            <ActionIcon
              onClick={() => [
                document
                  .getElementById("user-group-list-delete-modal")
                  .showModal(),
                setDeleteDataId(row.original),
              ]}
            >
              <IconTrash className="text-red-400 " />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),
    enableExpanding: false,
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <div>
      <MantineReactTable table={table} />
      {/* create */}
      <CreateModal
        handleSubmit={handleSubmit}
        PermissionData={PermissionData}
      />
      {/* edit */}
      <EditModal
        handleEditSubmit={handleEditSubmit}
        EditGroupData={EditGroupData}
        PermissionData={PermissionData}
      />

      {/* delete modal */}
      <DeleteModal
        DeleteDataId={DeleteDataId}
        handleDeleteFunction={handleDeleteFunction}
      />
    </div>
  );
}
