import CSideBar from "./components/SideBar";
import TopNavBar from "./components/TopNavBar";
import { Outlet } from "react-router-dom";
import Wrapper from "./components/wrapper";
import MobileNavBar from "./components/mobile-nav-bar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MainLayout() {
  return (
    <div className="flex h-full min-h-[400px]">
      <CSideBar />
      <div className="phoenix-content-container shrink">
        <TopNavBar />
        <Wrapper>
          <Outlet />
        </Wrapper>
        <MobileNavBar />
        <ToastContainer />
        <div className="h-20 lg:h-16"></div>
      </div>
    </div>
  );
}

export default MainLayout;
