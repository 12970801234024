import { useLocation } from "react-router-dom";
import { navigationLinks } from "../utils/settings";

function useCurrentPath(links = navigationLinks) {
  const location = useLocation();

  const currentPath = links.find(
    (link) =>
      link.href === location.pathname ||
      (link.children &&
        link.children.find((sub_link) =>
          location.pathname.includes(sub_link.href)
        )) ||
      (link.children &&
        link.children.find(
          (sub_link) =>
            sub_link.children &&
            sub_link.children.find((micro_link) =>
              location.pathname.includes(micro_link.href)
            )
        ))
  );

  const currentSubPath =
    currentPath &&
    currentPath.children &&
    currentPath.children.find((sub_link) =>
      location.pathname.includes(sub_link.href)
    );

  const currentMicroPath =
    currentSubPath &&
    currentSubPath.children &&
    currentSubPath.children.find((micro_link) =>
      location.pathname.includes(micro_link.href)
    );

  return {
    currentPath,
    currentSubPath,
    currentMicroPath,
  };
}

export { useCurrentPath };
