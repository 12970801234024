import { Label, TextInput, ToggleSwitch } from "flowbite-react";
import { useEffect, useState } from "react";
import Select from "react-select";
import clearForm from "../../../../utils/clearForm";

const BulkChangeModal = ({
  handleFormSubmit,
  formRef,
  switch1,
  switch2,
  setSwitch1,
  setSwitch2,
}) => {
  const clearAll = () => {
    clearForm();
  };

  return (
    <dialog
      id="product-list-bulk-change-amount-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Change all amount</h3>
          <form method="dialog">
            <button
              className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
              onClick={() => clearAll()}
            >
              ✕
            </button>
          </form>
        </div>
        <form
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form"
          onSubmit={handleFormSubmit}
          ref={formRef}
        >
          <div className="grid w-full grid-cols-1 gap-4">
            <ToggleSwitch
              checked={switch1}
              label={
                switch1
                  ? "Turn OFF to decrease amount"
                  : "Turn ON to increase amount"
              }
              onChange={setSwitch1}
              className="text-primary"
            />
            <ToggleSwitch
              checked={switch2}
              label={
                switch2
                  ? "Turn OFF to Use Amount (Fixed)"
                  : "Turn ON to use % (Percentage)"
              }
              onChange={setSwitch2}
            />
          </div>
          <div>
            <div className="block mb-2">
              <Label
                htmlFor="amount"
                value={`Note: This input amount will be ${
                  switch1 ? "(+) added to" : "(-) reduced from"
                } all current products' amount `}
              />
            </div>
            <TextInput
              id="amount"
              name="amount"
              placeholder="Amount"
              required
            />
          </div>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <form method="dialog">
              <button
                type="submit"
                className="w-full btn btn-secondary"
                onClick={() => clearAll()}
              >
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default BulkChangeModal;
