import { FileInput } from "flowbite-react";
import clearForm from "../../../../../utils/clearForm";

const ExcelUploadModal = ({ handleExcelFormSubmit }) => {
  const clearAll = () => {
    clearForm();
  };

  return (
    <dialog
      id="product-list-create-excel-upload-modal"
      className="modal modal-bottom md:modal-middle"
    >
      <div className="p-0 modal-box">
        <div className="sticky top-0 z-10 flex items-center justify-between w-full p-3 border-b bg-base-100">
          <h3 className="text-xl font-semibold">Upload with Excel file</h3>
          <form method="dialog">
            <button
              className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
              onClick={() => clearAll()}
            >
              ✕
            </button>
          </form>
        </div>
        <form
          className="flex flex-col w-full gap-3 p-3 overflow-x-hidden overflow-y-visible phoenix-submit-form"
          onSubmit={handleExcelFormSubmit}
        >
          <div>
            <FileInput
              name="excel_file"
              id="file-upload-helper-text"
              helperText="Only XLSX, XLS (Max file size: 100MB)"
              accept=".xlsx, .xls"
            />
          </div>
          <div className="grid justify-end grid-cols-2 gap-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <form method="dialog">
              <button
                type="submit"
                className="w-full btn btn-secondary"
                onClick={() => clearAll()}
              >
                Cancel
              </button>
            </form>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default ExcelUploadModal;
